@import "../../Style/_mixin.scss";

.progress-bar {
	background: rgba(255, 255, 255, 0.04);
	border-radius: 97px;
	height: 11px;

	.filled {
		display: block;
		height: inherit;
		background: linear-gradient(88.83deg,
				#eeeeee -290.8%,
				#fffee2 -272.69%,
				rgba(255, 255, 255, 0.950883) -259.54%,
				#df68fd -244.29%,
				rgba(255, 255, 255, 0.815523) -226.25%,
				#b7eee5 -202.79%,
				#20282e -142%,
				#29353c -122.65%,
				rgba(255, 255, 255, 0.596267) -113.78%,
				#c9fbfe -93.45%,
				#79a2f2 -77.51%,
				rgba(255, 255, 255, 0.741036) -63.62%,
				#79a2f2 -47.78%,
				#5d23fd -19.47%,
				#ffffff 10.99%,
				#51555e 99.71%,
				#699cff 127%,
				#ffffff 133.99%,
				#79a2f2 141.27%,
				#ffffff 147.64%);
		border-radius: 97px;
	}
}