@import "../../Style/_mixin.scss";

.nft-card {
	padding: 0 4px;

	@media screen and (max-width: 991px) {
		padding-bottom: 4rem;
	}

	.nft-card-colbox {
		background: url('../../images/box/rectangle/rectangle10.png') center/100% 100% no-repeat;		
		// background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.1) 98.47%);
		// border: 1px solid rgba(255, 255, 255, 0.1);
		// backdrop-filter: blur(40px);
		// border-radius: 24px;
		margin-bottom: 25px;

		.nft-card-imgbox {
			position: relative;
			width: 100%;
			aspect-ratio: 1/1;
			// background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.1) 98.47%);
			// border: 1px solid rgba(255, 255, 255, 0.1);
			// border-radius: 24px;
			// backdrop-filter: blur(40px);
			overflow: hidden;

			img {
				position: absolute; 	
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				max-width: 81%;
				max-height: 90%;

				&.origin {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}

		.nft-card-content-info {
			padding: 25px;
			text-align: center;

			p {
				font-size: 16px;
				line-height: 24px;
				font-weight: 300;
				letter-spacing: 0.4px;
				color: #FFFFFF;
			}

			ul {
				display: inline-block;
				padding: 0;
				margin: 0;

				li {
					position: relative;
					font-size: 18px;
					line-height: 33px;
					font-weight: 500;
					text-align: center;
					letter-spacing: 0.4px;
					color: #FFFFFF;
					padding-left: 15px;

					&:before {
						position: absolute;
						content: "";
						left: 0;
						top: 15px;
						width: 4px;
						height: 4px;
						background: #fff;
					}
				}
			}
		}

		.nft-card-name {
			display: block;
			@include cairo(600, 16px, 24px);
			letter-spacing: 0.1px;
			color: #FFFFFF;
			margin-bottom: 10px;

			i {
				margin-left: 8px;
			}
		}

		.nft-card-token {
			display: block;
			font-size: 16px;
			line-height: 1.2;
			letter-spacing: 0.05em;
			color: gray;
			margin-bottom: 10px;
		}

		.nft-card-description {
			margin-top: 16px;
			@include cairo(400, 14px, 24px);
			text-align: left;
			color: #9AA1AC;
		}

		.progress-bar {
			margin-top: 4px;
			width: 100%;
			height: 8px;
			background: #FFFFFF0A;
			border-radius: 4px;

			&>div {
				width: 80%;
				height: 100%;
				background: linear-gradient(88.83deg, #EEEEEE -290.8%, #FFFEE2 -272.69%, rgba(255, 255, 255, 0.950883) -259.54%, #DF68FD -244.29%, rgba(255, 255, 255, 0.815523) -226.25%, #B7EEE5 -202.79%, #20282E -142%, #29353C -122.65%, rgba(255, 255, 255, 0.596267) -113.78%, #C9FBFE -93.45%, #79A2F2 -77.51%, rgba(255, 255, 255, 0.741036) -63.62%, #79A2F2 -47.78%, #5D23FD -19.47%, #FFFFFF 10.99%, #51555E 99.71%, #699CFF 127%, #FFFFFF 133.99%, #79A2F2 141.27%, #FFFFFF 147.64%);
				border-radius: 4px;
			}
		}
	}

	.nft-card-discount-row {
		padding: 24px 16px;
		// background: linear-gradient(136.24deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
		// 	linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
		// border-radius: 24px;

		.input-container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			background: url('../../images/box/rectangle/rectangle6.png') center/100% 100% no-repeat;

			.count-minus {
				width: 58px;
				height: 60px;
				display: flex;
				justify-content: center;
				align-items: center;
				// border: 1px solid grey;
				// border-radius: 12px 0 0 12px;
				// background: linear-gradient(136.24deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);

				&:disabled {
					border: none;
					// background: rgba(255, 255, 255, 0.05);
				}
			}

			.count-plus {
				width: 58px;
				height: 60px;
				display: flex;
				justify-content: center;
				align-items: center;
				// border: 1px solid grey;
				// border-radius: 0 12px 12px 0;
				// background: linear-gradient(136.24deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);

				&:disabled {
					border: none;
					// background: rgba(255, 255, 255, 0.05);
				}
			}

			.count-text {
				width: 50%;
				// background: rgba(255, 255, 255, 0.1);
				height: 60px;
				color: gray;
				font-weight: 600;
				font-size: 20px;
				line-height: 20px;
				text-transform: uppercase;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		.btn-buy {
			position: relative;
			margin-top: 16px;
			width: 100%;
			height: 48px;
			background: url('../../images/box/rectangle/rectangle6.png') center/100% 100% no-repeat;
			// background: rgba(255, 255, 255, 0.02);
			// box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1), inset 0px 4px 11px rgba(0, 0, 0, 0.16), inset 0px 0px 2px rgba(255, 255, 255, 0.4);
			// backdrop-filter: blur(8px);
			// border-radius: 4px;
			@include workSans(600, 14px, 130%);
			color: gray;
			text-transform: uppercase;
			overflow: hidden;

			display: flex;
			justify-content: center;
			align-items: center;

			&::after {
				content: "";
				// position: absolute;
				// bottom: 0;
				// width: 100%;
				// height: 3px;
				// background: linear-gradient(269.99deg, #EEEEEE -69.29%, #FFFEE2 -62.4%, rgba(255, 255, 255, 0.950883) -57.4%, #DF68FD -51.6%, rgba(255, 255, 255, 0.815523) -44.74%, #B7EEE5 -35.82%, #20282E -12.7%, #29353C -5.34%, rgba(255, 255, 255, 0.596267) -1.97%, #C9FBFE 5.76%, #79A2F2 11.83%, rgba(255, 255, 255, 0.741036) 17.11%, #79A2F2 23.13%, #5D23FD 33.9%, #FFFFFF 45.48%, #51555E 79.22%, #699CFF 89.6%, #FFFFFF 92.26%, #79A2F2 95.03%, #FFFFFF 97.45%);
			}
		}
	}
}