@import "../../Style/_mixin.scss";

.staking-lots-wrapper {
  @media screen and (max-width: 991px) {
    padding-bottom: 200px;
  }
}
.staking-lots {
  @media screen and (max-width: 991px) {
    padding-top: 144px;
  }

  .section-header {
    display: flex;
    justify-content: center;
    align-items: center;

    .section-header-wrapper {
      position: relative;
      padding: 20px 24px;
      display: flex;
      background-image: url("../../images/box/rectangle/rectangle10.png");
      background-position: center;
      background-size: 100% 100%;
      width: 400px;
      align-items: center;
      justify-content: center;

      span {
        @include cairo(600, 20px, 32px);
        letter-spacing: 0.1em;
        color: var(--Primary-Orange, #ffa12b);

        /* Display/md */
        -webkit-text-stroke: 0.5 #ffa12b;
        font-size: 36px;
        line-height: 120%; /* 43.2px */
        letter-spacing: -0.72px;
      }
    }
  }

  .staking-listrow {
    padding-top: 5rem;
    gap: 30px;

    @media screen and (min-width: 992px) {
      margin-left: -10px;
      margin-right: -12px;

      display: flex;
      flex-wrap: wrap;
    }

    @media screen and (max-width: 991px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @media screen and (max-width: 576px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .staking-colitem {
      background-image: url("../../images/box/rectangle/rectangle10.png");
      background-position: center;
      background-size: 100% 100%;
      padding: 30px;
      width: 30%;
      @media screen and (max-width: 992px) {
        width: 45%;
      }

      @media screen and (max-width: 767px) {
        width: calc(100% / 2);
      }

      @media screen and (max-width: 576px) {
        width: 100%;
        max-width: 320px;
      }
    }
  }

  .allocation-container {
    margin-top: 28px !important;

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  .staking-applications-box.tier-comparison.desktop {
    margin-top: 100px;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
    // border-radius: 4px;
    // border: 2px solid var(--Black, #111);

    // background: url('../../images/box/rectangle/rectangle12.png') center/100% 100% no-repeat;
    background-color: white;
    box-shadow: 5px 5px 3px;

    // background: var(--White-White-100, #fff);
    // box-shadow: 4px 4px 0px 0px #111;

    @media screen and (min-width: 991px) {
      min-width: 764px;
      height: unset;
    }

    // @media screen and (max-width: 841px) {
    // 	display: none;
    // }

    .tier-applications-list-item {
      display: grid;
      grid-template-columns: 1fr 1fr 15rem 21rem;
      color: #ffffff;
    }

    .tier-com-applications-list-item {
      height: 64px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;

      &.tier-header-line {
        border-bottom: 2px solid #000;
        margin: 18px;
      }

      .tier-applications-col {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        color: var(--Primary-Orange, #ffa12b);
        -webkit-text-stroke: 0.20000000298023224 var(--Primary-Orange, #ffa12b);
        font-size: 16px;
        padding: 8px 14px 8px;
        letter-spacing: 0.45px;
        justify-content: space-around;

        &.amount-label {
          color: var(--Black-Black-40, rgba(0, 0, 0, 0.4));
        }

        & > span {
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: var(--Black-Black-40, rgba(0, 0, 0, 0.4));
          font-size: 18px;
          line-height: 120%; /* 21.6px */

          @media screen and (min-width: 992px) {
            @include cairo(400, 16px, 32px);
          }

          @media screen and (max-width: 991px) {
            @include cairo(400, 12px, 32px);
          }
        }
      }

      .tier-applications-col:nth-child(1) {
        flex: 2 2;
        justify-content: unset;
      }

      .tier-applications-col:nth-child(2) {
        flex: 1.5 1.5;
      }

      .tier-applications-col:nth-child(3) {
        flex: 2 2;
      }

      .tier-applications-col:nth-child(4) {
        flex: 3 3;
      }

      .applications-original-img1 {
        border-radius: 100%;
        width: 40px;
        height: 50px;
        margin-right: 20px;

        img {
          width: 80%;
          height: 100%;
          // object-fit: cover;
        }
      }
    }

    .tier-com-applications-list-item:first-child {
      height: 70px;
    }
  }

  .tier-comparison.tier-comparison-title {
    margin-top: 48px;

    @media screen and (max-width: 840px) {
      display: none;
    }
  }

  .staking-applications-box.tier-comparison-mobile {
    margin-top: 114px;
    display: none;

    @media screen and (max-width: 840px) {
      display: none;
    }

    .tier-com-applications-list-item {
      background: linear-gradient(
        223.88deg,
        rgba(255, 255, 255, 0) 1.79%,
        rgba(255, 255, 255, 0.1) 98.47%
      );
      backdrop-filter: blur(8px);
      border-radius: 4px;
      height: 269px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      color: white;
      padding: 26px 33px 28px 15px;

      &-header {
        display: flex;
        align-items: center;

        & > img {
          width: 68px;
          height: 63px;
        }

        & > span {
          margin-left: 24px;
          font-size: 16px;
          line-height: 32px;
          text-align: center;
          letter-spacing: 0.1em;
          text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
        }
      }

      &-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .allocation-pool-row {
    text-align: center;
    margin-top: 37px;

    h2 {
      margin: 0;
      @include cairo(600, 20px, 32px);
      letter-spacing: 0.1em;
      text-align: center;
      color: var(--Black-Black-100, #000) !important;
      text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
      font-size: 36px;
      line-height: 120%; /* 43.2px */
      letter-spacing: -0.72px;
    }
  }
}
