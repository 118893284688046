.admin-table-box {
  .main-container {
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.1em;
        text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
        margin-bottom: 0;
        color: #000 !important;
      }

      .actions-container {
        margin-left: auto;

        display: flex;
        gap: 24px;
      }

      .btn-action-link {
        display: flex;
        align-items: center;
        justify-content: space-around;

        span {
          margin-left: 12px;
        }
      }
    }

    .table-body {
      margin-top: 64px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .admin-table-box {
    .main-container {
      padding-top: 32px;

      .table-header {
        p {
          font-size: 14px;
          line-height: 150%;
        }

        .btn-action-link {
          height: 40px;

          span {
            margin-left: 12px;
          }
        }
      }

      .table-body {
        margin-top: 26px;
      }
    }
  }
}

@media screen and (max-width: 524px) {
  .admin-table-box {
    .main-container {
      .table-header {
        flex-direction: column;

        p {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
        }

        .btn-action-link {
          margin-top: 24px;
          height: 49px;
        }
      }

      .table-body {
        margin-top: 82px;
      }
    }
  }
}
