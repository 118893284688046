@import "../../Style/_mixin.scss";

.last-section {
  // background-image: url("../../images/footer.png");
  position: relative;
  background-repeat: contain;
  background-size: 100% 100%;
  // height: 135vh;
  z-index: 97;
  // margin-top: -17.5rem;
  @media screen and (max-width: 375px) {
    // background-image: url("../../images/footer-small.png");
  }
}

footer {
  background-size: cover;

  @media screen and (min-width: 1920px) {
    background-size: 100% 100%;
  }

  @media screen and (max-width: 900px) {
    padding-top: 0;
  }

  @media screen and (max-width: 1919px) {
  }

  .footercontainer {
    padding: 150px 0 0;
    text-align: center;
    text-align: -webkit-center;

    @media only screen and (max-width: 767px) {
      padding: 100px 0;
    }

    p {
      font-size: 22px;
      line-height: 1.2;
      color: #000;
      margin-bottom: 3rem;
    }
  }

  .footerlogo {
    margin: 40px 0 30px;
    width: 30%;
    max-width: 294px;

    @media only screen and (max-width: 767px) {
      width: 50%;
    }

    a {
      background-image: url(../../images/box/cirgle_elipsae/circle1.png);
      display: inline-block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 100%;
    }
    img {
      width: 90%;
    }
  }

  .social-link {
    display: flex;
    padding: 0;
    margin: 0;

    li + li {
      margin-left: 15px;
    }

    li {
      a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        background-image: url(../../images/box/cirgle_elipsae/circle2.png);
        background-repeat: no-repeat;
        background-size: 60px 60px;
        margin: 0 auto;

        svg {
          position: relative;
          z-index: 1;
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .footer-social-link {
    padding: 3rem 0 5rem;

    ul {
      li {
        text-align: center;
        width: 100px;
        margin: 0;
      }

      flex-wrap: wrap;
      justify-content: center;
      padding: 0;
      margin: 0;
    }
  }

  .social-link-info {
    display: block;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;
    font-weight: 600;
    color: #ffffff;
    margin-top: 10px;
  }
}
