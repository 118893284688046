@import "../../Style/_mixin.scss";

.select-file-form {
	position: relative;

	.upload-form-wrapper {
		cursor: pointer;

		input {
			display: none;
		}
	}

	.result-thumbnail {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		height: 100%;
		object-fit: contain;
	}

	.progress-bar-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.progress-bar-value {
			color: #FFFFFF;
		}

		.progress-bar {
			margin: 12px 0;
			// width: 80%;
			background-color: #424867;
			border-radius: 4px;

			&>div {
				height: 8px;
				border-radius: 4px;
				background: rgba(0, 0, 0, 0.7);
				animation: flowAnimation 2s linear infinite;
			}
		}
	}

	#drag-file-element {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	.btn-clear {
		position: absolute;
		top: 12px;
		right: 12px;
	}
}

.upload-form.drag-active {
	opacity: 0.5;
}

.upload-form.no-file {
	cursor: pointer;
}

@keyframes flowAnimation {
	from {
		background-color: #424867;
	}

	to {
		background-color: white;
		opacity: 0.5;
	}
}
