$border_color_1: #f0f0f060;
$black: #000000;

.all-projects-wrap {
  .selection-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 50%;
    margin-block: 30px;
    gap: 10px;
    @media screen and (max-width: 768px) {
      width: 75%;
      padding-inline:10px;
    }
    @media screen and (max-width: 425px) {
        width: 60%;
        flex-direction: column;
      }
    .selection-row-item {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;
      color: $border_color_1;
      @media screen and (max-width: 576px) {
        width: 100%;
      }
      &:hover {
        background: $border_color_1;
        color: $black;
      }
    }
    .active {
      background: $border_color_1;
      color: $black;
    }
  }
}
// .nav_btn_options{
//   width: 30% !important;
// }
.btn-action-link.nav_btn_options{
  width: 100% !important;
  min-width: 80px !important;
  // width: 30% !important;

}
.New-game-left-back-cover{
  @media (max-width: 650px) {
    margin-top: 30rem !important;
  }
  @media (max-width: 576px) {
    margin-top: 35rem !important;
  }
}
