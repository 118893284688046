@import "../../../Style/_mixin.scss";

.profile {
	.admin-table-box {
    background-image: url("../../../images/submit-form-wrapper.png");
    background-position: center;
    background-size: 100% 100%;
		padding: 30px;
		padding-top: 50px;
	}

	.profile-table {
		overflow: hidden;
		border-radius: 4px;
		border: 2px solid var(--Black, #111);
		background: var(--White-White-100, #FFF);
		box-shadow: 4px 4px 0px 0px #111;
		display: flex;

		.profile-table-left {
			width: 204px;

			&>div {
				height: 52px;

				display: flex;
				align-items: center;
			}

			.table-header-row {
				color: var(--Primary-Orange, #FFA12B);

				/* Text/md */
				-webkit-text-stroke: 0.20000000298023224 #FFA12B;
				font-size: 16px;
				line-height: 120%; /* 19.2px */
				border-bottom: 1px solid #4F5258;
				display: flex;
				justify-content: center;
				align-items: center;

				@media screen and (min-width: 992px) {
					@include cairo(600, 16px, 150%);
				}

				@media screen and (max-width: 991px) {
					@include cairo(600, 12px, 150%);
				}
			}

			.table-row {
				padding-left: 24px;

				img {
					width: 20px;
				}

				span {
					margin-left: 24px;
					color: var(--Black-Black-40, rgba(0, 0, 0, 0.40));
					font-size: 14px;
					line-height: 120%; /* 16.8px */
					text-transform: uppercase;

					@media screen and (min-width: 992px) {
						@include cairo(400, 14px, 150%);
					}

					@media screen and (max-width: 991px) {
						@include cairo(400, 12px, 150%);
					}
				}
			}
		}

		.profile-table-right {
			flex: auto;

			&>div {
				height: 52px;
				display: flex;

				&>div {
					flex: 1 1;
					color: var(--Black-Black-40, rgba(0, 0, 0, 0.40));
					font-size: 14px;
					line-height: 120%; /* 16.8px */

					display: flex;
					justify-content: center;
					align-items: center;

					@media screen and (min-width: 992px) {
						@include cairo(400, 14px, 150%);
					}

					@media screen and (max-width: 991px) {
						@include cairo(400, 12px, 150%);
					}
				}
			}

			.table-header > div {
				color: var(--Primary-Orange, #FFA12B);
				-webkit-text-stroke: 0.20000000298023224 #FFA12B;
				font-size: 16px;
				line-height: 120%; /* 19.2px */
				border-bottom: 1px solid #4F5258;
			}
		}
	}
}