.headermenu {
  ul {
    li {
      &:hover{
        background-color: #000000;
      }
      &:active{
        background-color: #000000;
      }
      .dropdown-list {
        position: absolute;
        background: black;
        height: auto;
        width: 196px;
        border-radius: 5px;
        top: 80px;
        padding: 25px 15px;
        z-index: 1000;
        .dropdown-list-item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: auto;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          padding-block: 10px;
          transform: skew(-13deg);
          cursor: pointer;

          &:last-child {
            border-bottom: 0px;
          }
          #dropdown-list-item-link {
            padding: 5px !important;
            @media (max-width: 1400px) {
              padding: 5px !important;
            }
          }
          a::before {
            width: 0px;
          }
        }
      }
    }
  }
}
.header-nav{
    .header-mode-section{
        width:60px;
        height:32px;
        border-radius: 10px;
        border:1px solid rgba(255, 255, 255, 0.2);
        margin-right:20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 7px;
        gap:2px;
        img{
            height:18px;
            width:16px;
        }
        .header-mode-switch{
            width: 20px;
            height: 20px;
            border-radius: 6px;
            border: 1px solid rgba(255, 255, 255, 0.16);
            background-color:  rgba(255, 255, 255, 0.17);
            cursor: pointer; 
        }
    }
}
.header-bottom-right1{
  // .arrowimg{
  //   height:8px;
  //   width:15px;
  //   margin-left: 2px;
  // }
  .disconnect-container{
    position: absolute;
    top: 45px;
    right: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    border-radius: 10px;
    padding-block: 30px;
    label{
      cursor: pointer;
    }
  }
}
