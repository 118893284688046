@import "../../Style/_mixin.scss";

.ntfs {
	@media screen and (min-width: 992px) {
		padding-top: 150px;
	}

	@media screen and (max-width: 991px) {
		padding-top: 250px;
	}

	.ntfs-wrapper {}

	.section-header {
		display: flex;
		justify-content: center;
		align-items: center;

		.section-header-wrapper {
			position: relative;
			// height: 53px;
			padding: 50px 100px;
			background: url("../../images/box/rectangle/rectangle7.png") center/100% 100% no-repeat;
			// background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.1) 98.47%);
			// border-radius: 4px;

			display: flex;

			@media screen and (max-width: 767px) {
				padding: 25px 50px;
			}

			&::before {
				position: absolute;
				content: "";
				left: 0;
				bottom: -8px;
				// background: url(../../images/bottom-line.png) center 0 no-repeat;
				width: 100%;
				height: 20px;
			}

			span {
				margin-top: 8px;
				@include cairo(600, 32px, 32px);
				letter-spacing: 0.1em;
				color: gray;

				@media screen and (max-width: 767px) {
					@include cairo(600, 20px, 32px);
				}
			}
		}
	}

	.staking-listrow {
		padding-top: 5rem;

		@media screen and (min-width: 992px) {
			margin-left: -10px;
			margin-right: -12px;

			display: flex;
			flex-wrap: wrap;
		}

		@media screen and (max-width: 991px) {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}

		@media screen and (max-width: 576px) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.nft-card {
			@media screen and (min-width: 992px) {
				width: 20%;
			}

			@media screen and (max-width: 991px) {
				width: calc(100% / 3);
			}

			@media screen and (max-width: 767px) {
				width: calc(100% / 2);
			}

			@media screen and (max-width: 576px) {
				width: 100%;
				max-width: 284px;
			}
		}
	}

	.allocation-pool-row {
		margin-top: 80px;

		@media only screen and (max-width: 500px) {
			margin-top: 0;
		}

		h2 {
			margin: 0;
			@include cairo(600, 20px, 32px);
			letter-spacing: 0.1em;
			text-align: center;
			text-shadow: 0px 0px 8px rgb(255 255 255 / 45%);
			color: #FFFFFF;
		}
	}

	.puchase-modal {
		.purchase-modal-container {
			width: 100%;

			h1 {
				width: 100%;
				border-bottom: 1px solid #4F5258;
				padding-top: 20px;

				@media screen and (min-width: 992px) {
					@include cairo(700, 16px, 30px);
					padding-bottom: 12px;
				}

				@media screen and (max-width: 991px) {
					@include cairo(700, 12px, 22px);
					padding-bottom: 8px;
				}
			}

			.purchase-body {
				@media screen and (min-width: 992px) {
					margin-top: 24px;
				}

				@media screen and (max-width: 991px) {
					margin-top: 16px;
				}

				&-title {
					width: 100%;
					letter-spacing: 0.05em;
					text-align: center;
					color: #FFFFFF;

					@media screen and (min-width: 992px) {
						@include cairo(700, 16px, 130%);
					}

					@media screen and (max-width: 991px) {
						@include cairo(700, 12px, 130%);
					}

					&.success-text {
						margin-top: 32px;
					}
				}

				&-detail {
					margin-top: 12px;

					display: flex;
					justify-content: center;
					align-items: center;

					@media screen and (max-width: 768px) {
						flex-direction: column;
					}

					.detail-img {
						display: flex;

						img {
							max-width: 138px;
						}
					}

					.detail-container {
						margin-left: 12px;
						min-width: 108px;

						.detail-row {
							margin-top: 16px;
							display: flex;
							align-items: center;

							.text-name {
								flex: 1 1;
								color: #FFFFFF99;

								@media screen and (min-width: 992px) {
									@include cairo(400, 16px, 32px);
								}

								@media screen and (max-width: 991px) {
									@include cairo(400, 12px, 32px);
								}
							}

							.text-des {
								flex: 1 1;
								color: #FFFFFF;
                white-space: nowrap;
                margin-left: 8px;

								@media screen and (min-width: 992px) {
									@include cairo(400, 16px, 32px);
								}

								@media screen and (max-width: 991px) {
									@include cairo(400, 12px, 32px);
								}
							}
						}
					}
				}

				&-footer {
					margin-top: 32px;
					width: 100%;

					display: flex;
					align-items: center;
					gap: 16px;

					@media screen and (max-width: 768px) {
						margin-top: 28px;
						flex-direction: column-reverse;
					}

					button {
						flex: 1 1;
						border-radius: 4px;
						text-transform: uppercase;
						color: #ffffff;
						overflow: hidden;

						display: flex;
						justify-content: center;
						align-items: center;

						@media screen and (min-width: 992px) {
							height: 64px;
							@include cairo(600, 16px, 130%);
						}

						@media screen and (max-width: 991px) {
							height: 48px;
							@include cairo(600, 12px, 130%);
						}

						@media screen and (max-width: 768px) {
							flex: none;
							width: 209px;
						}
					}

					.btn-primary {
						position: relative;
						// background: rgba(255, 255, 255, 0.02);
						border: none;
						// box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1), inset 0px 4px 11px rgba(0, 0, 0, 0.16),
						// 	inset 0px 0px 2px rgba(255, 255, 255, 0.4);
						// backdrop-filter: blur(16px);

						background: url('../../images/box/orange_buttons/orange_button_1.png') center/100% 100% no-repeat;

						&::after {
							content: "";
							position: absolute;
							left: 0;
							right: 0;
							bottom: 0;
							height: 3px;
							// background: linear-gradient(269.99deg, #EEEEEE -69.29%, #FFFEE2 -62.4%, rgba(255, 255, 255, 0.950883) -57.4%, #DF68FD -51.6%, rgba(255, 255, 255, 0.815523) -44.74%, #B7EEE5 -35.82%, #20282E -12.7%, #29353C -5.34%, rgba(255, 255, 255, 0.596267) -1.97%, #C9FBFE 5.76%, #79A2F2 11.83%, rgba(255, 255, 255, 0.741036) 17.11%, #79A2F2 23.13%, #5D23FD 33.9%, #FFFFFF 45.48%, #51555E 79.22%, #699CFF 89.6%, #FFFFFF 92.26%, #79A2F2 95.03%, #FFFFFF 97.45%);
						}
					}

					.btn-secondary {
						background: url('../../images/box/orange_buttons/orange_button_1.png') center/100% 100% no-repeat;
						// background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
						// backdrop-filter: blur(20px);
						// box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1), inset 0px 4px 11px rgba(0, 0, 0, 0.16), inset 0px 0px 2px rgba(255, 255, 255, 0.4);
					}
				}
			}

			.purchase-body.succss-body {
				margin-top: 72px;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.purchase-body-footer {
					justify-content: center;

					button {
						max-width: 209px;
					}
				}
			}
		}
	}
}