@import "../../Style/_mixin.scss";

.game-details {
  .details-row {

    @media (max-width: 991px) {
      margin-top: -40px !important;
    }
  }

  .game-details-banner-wrap {
    img {
      width: 52%;
      position: absolute;
      top: 0;
      right: 0;

      @media (max-width: 991px) {
        width: 100%;
        position: relative;
        display: none !important;
      }
    }
  }

  .game-detail-info {
    display: flex;
    background-image: url("../../images/game-detail-title-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    align-items: flex-start;
    padding: 30px;

    @media (max-width: 991px) {
      position: relative;
      top: 250px;
    }

    @media (max-width: 767px) {
      display: block;
      text-align: center;
    }

    @media (max-width: 400px) {
      flex-direction: column;
      align-items: center;
    }

    .game-apply-img {
      background: #0a0f12;
      border: 1px solid #fff;
      border-radius: 50%;
      width: 150px;
      height: 150px;
      margin-right: 40px;

      @media (max-width: 767px) {
        width: 100px;
        height: 100px;
        margin: 0 auto;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
      }
    }

    .game-apply-content {
      @media (max-width: 400px){
        text-align: center;
      }
      h4 {
        font-size: 1.5rem;
        line-height: 1.4;
        font-weight: 600;
        color: #000 !important;
      }

      h6 {
        font-size: 1rem;
        line-height: 1.4;
        font-weight: 600;
        color: #9AA1AC;
        margin-top: 8px;
      }

      h5 {
        font-size: 1rem;
        line-height: 1.4;
        font-weight: 400;
        color: #9aa1ac;
      }

      margin-top: 17px;
    }

    .game-dark-info {
      display: block;
      font-size: 22px;
      line-height: 1.4;
      font-weight: 500;
      color: #9aa1ac;
    }

    .game-action-link {
      display: inline-block;
      background: rgba(255, 255, 255, 0.02);
      border: none;
      box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1),
        inset 0px 4px 11px rgba(0, 0, 0, 0.16),
        inset 0px 0px 2px rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(16px);
      border-radius: 4px;
      text-align: center;
      position: relative;
      overflow: hidden;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1.2;
      color: #ffffff;
      padding: 16px 22px;
      letter-spacing: 0.4px;
      min-width: 160px;
      text-decoration: none;

      &:hover {
        color: #ffffff;
      }

      &::before {
        position: absolute;
        content: "";
        height: 3px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: linear-gradient(
          269.99deg,
          #eeeeee -69.29%,
          #fffee2 -62.4%,
          rgba(255, 255, 255, 0.950883) -57.4%,
          #df68fd -51.6%,
          rgba(255, 255, 255, 0.815523) -44.74%,
          #b7eee5 -35.82%,
          #20282e -12.7%,
          #29353c -5.34%,
          rgba(255, 255, 255, 0.596267) -1.97%,
          #c9fbfe 5.76%,
          #79a2f2 11.83%,
          rgba(255, 255, 255, 0.741036) 17.11%,
          #79a2f2 23.13%,
          #5d23fd 33.9%,
          #ffffff 45.48%,
          #51555e 79.22%,
          #699cff 89.6%,
          #ffffff 92.26%,
          #79a2f2 95.03%,
          #ffffff 97.45%
        );
      }
    }

    .game-submit-col {
      margin-top: 85px;
      margin-left: 20px;

      @media (max-width: 991px) {
        display: none;
      }
    }

    .game-submit-col-mobile {
      @media (max-width: 991px) {
        margin-top: 15px;
        margin-left: 0;
        display: block;
        text-align: start;
      }

      @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        margin: 15px auto;
      }

      .btn-action-link {
        padding: 15px 40px;
      }
    }
  }

  .game-details-container {
    margin-top: 5rem;

    @media (max-width: 991px) {
      margin-top: 0 !important;
      top: 370px;
      width: 100%;
    }
    @media (max-width: 400px) {
      top: 500px;
    }

    .game-details-grid {
      display: flex;
      gap: 30px;

      @media (max-width: 991px) {
        flex-direction: column-reverse;
        margin-top: 300px;
      }

      @media (max-width: 767px) {
        flex-direction: column-reverse;
      }

      .about-top-row1 {
        border-bottom: 1px solid rgba(255, 255, 255, 0.23);
        padding-bottom: 24px;

        h3 {
          @include cairo(700, 24px, 150%);
          color: #000 !important;
          font-size: 27px;

          @media (max-width: 767px) {
            font-size: 20px;
          }
        }

        .description {
          margin-top: 16px;
          @include cairo(400, 14px, 150%);
          color: #9AA1AC;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          word-break: break-all;

          @media (max-width: 767px) {
            font-size: 15px;
          }
        }

        .social-links {
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          a {
            position: relative;
            width: 60px;
            height: 60px;
            border-radius: 100%;
            background-image: url("../../images/round-wrapper.svg");
            background-repeat: no-repeat;
            background-size: 60px 60px;

            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              position: relative;
              width: 25px;
              height: 25px;
              z-index: 1;
            }
          }

          a + a {
            margin-left: 16px;
          }
        }
      }

      .project-video-wrap {
        // padding: 2.5rem 0;
        margin-bottom: 1rem;

        iframe {
          width: 100%;
        }

        video {
          width: 100%;
        }
      }

      .project-info {
        padding-top: 2.5rem;
        padding-bottom: 1.5rem;
        color: white;

        .ql-container {
          height: unset;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
          font-size: 2rem;
          line-height: 1.4;
          font-weight: 600;
          margin-bottom: 1.5rem;
        } 
        p {
          margin-top: 2rem !important;
          font-size: 1.2rem;
          line-height: 1.5;
          margin-bottom: 2rem;
        }
      }
      .pitchDecklink-wrapper {
        display: flex;
        align-items: center;
        justify-items: center;
        width: 100%;
        border: 1px solid rgba(255, 255, 255, 0.23);
        // background-color: rgba(255, 255, 255, 0.23);
        margin-bottom: 2rem;
        padding: 10px;

        a {
          display: flex !important;
          color: white;
          text-decoration: underline !important;
          font-size: 1rem;
          line-height: 1.5;
        }
		img{
			width:24px;
			height:24px;
			margin-right: 10px;
			object-fit: contain;
		}
      }
    }
  }
}
.main-width{
  width: 70%;
  flex: 5;
  @media (max-width:991px) {
		width: 100%;
	}
}
.details-sidebar {
  flex: 2;
}
.border_Bottom{
  border-bottom: 1px solid rgba(255, 255, 255, 0.23);
}
.whitelist-table_thead{
  background-color: transparent !important;
}
.pitch p{
  text-align: center;
  color: #9AA1AC;
  font-size: 16px;
  line-height: 20px;
  margin-top: 15px;
}
.pitch img{
  width: 90px;
  height: 90px;
}
