*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}
body > iframe[style*='2147483647']{
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.apexcharts-tooltip {
  background: #16244e !important;
  box-shadow: 0px 0px 20px rgba(0, 10, 38, 0.3);
  border-radius: 4px;
  padding: 8px 10px;
  border: 0 !important;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #2dd2ff;
}
