@import "../../Style/_mixin.scss";

.landing-zone {
  .latest-games {
    padding-top: 17rem;
    padding-bottom: 10rem;

    @media screen and (max-width: 1280px) {
      padding-top: 5rem;
    }

    @media screen and (max-width: 991px) {
      padding-bottom: 15rem;
    }

    @media screen and (max-width: 550px) {
      padding-bottom: 17rem;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 8rem;
    }

    h4 {
      text-align: center;
      font-size: 24px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      color: white !important;
      -webkit-text-stroke-width: 1;
      -webkit-text-stroke-color: #000;
    }

    .latest-games-wrapper {
      margin-top: 32px;

      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .latest-row {
        margin: 16px;
        width: 354px;

        display: flex;
        flex-wrap: wrap;
      }      
    }

    @media only screen and (max-width: 767px) {
      .latest-games-wrapper {
        .latest-row {
          width: 440px;
        }      
      }
    }

    @media only screen and (min-width: 767px) {
      .latest-games-wrapper {
        .latest-row {
          width: 360px;
        }      
      }
    }

    @media only screen and (min-width: 992px) {
      .latest-games-wrapper {
        .latest-row {
          width: 440px;
        }      
      }
    }

    @media only screen and (min-width: 1400px) {
      .latest-games-wrapper {
        .latest-row {
          width: 590px;
        }      
      }   
    }
  }

  .dashboard-roya-row {
    margin-top: 5rem;

    display: flex;
    gap: 3%;

    @media (max-width: 768px) {
      margin-top: 1rem;

      flex-direction: column;
      gap: 24px;
    }

    .roya-article-box {
      background: linear-gradient(
        223.88deg,
        rgba(255, 255, 255, 0) 1.79%,
        rgba(255, 255, 255, 0.1) 98.47%
      );
      border: 1px solid rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(40px);
      border-radius: 24px;
      overflow: hidden;
      flex: 1 1;
      cursor: pointer;

      .roya-article-img {
        width: 100%;
        aspect-ratio: 4/3;
        border-radius: 24px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .roya-article-info {
        margin-bottom: 25px;
        padding: 16px 20px;
        overflow: hidden;

        display: flex;
        flex-direction: column;

        .title {
          @include cairo(700, 16px, 130%);
          letter-spacing: 0.05em;
          color: #ffffff;
        }

        .description {
          margin-top: 16px;
          @include textShrink(4);
          opacity: 0.5;

          * {
            @include cairo(600, 14px, 16px);
            color: #ffffff;
          }

          figure,
          img {
            display: none !important;
          }
        }
      }
    }
  }

  .third-section {
    // background-image: url("../../images/middle-bg2.png");
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 98;
    @media screen and (max-width: 375px) {
      // background-image: url("../../images/mid-bg-small-3.png");
    }
  }
  .second-section {
    // background-image: url("../../images/middle-bg1.png");
    position: relative;
    background-repeat: contain;
    background-size: 100% 100%;
    z-index: 99;
    margin-top: 4.7rem;
    @media screen and (max-width: 375px) {
      // background-image: url("../../images/mid-bg-small-2.png");
    }
  }

  .tier-levels-info {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // width: 100%;
    .tier-start-img {
      width: 50%;
      align-self: center;
      margin-bottom: 50px;
      @media screen and (max-width: 425px) {
        width: 90%;
      }
    }
    .tierMargin {
      margin-left: 5rem;
      margin-right: 5rem;
      @media screen and (max-width: 900px) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    //  button{
    //   align-self: center;
    //  }
    // .tier-levels-info-list {
    //   width: 100%;
    //   height: 294px;
    //   border-radius: 24px;
    //   border: 1px;
    //   background: linear-gradient(
    //     223.88deg,
    //     rgba(255, 255, 255, 0) 1.79%,
    //     rgba(255, 255, 255, 0.08) 98.47%
    //   );
    //   margin-block: 15px;
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    //   justify-content: space-between;
    //   padding: 10px;
    //   @media screen and (max-width: 768px) {
    //     flex-direction: column;
    //     height: auto;
    //     justify-content: flex-start;
    //   }
    //   .item-img {
    //     width: 100px;
    //     @media screen and (max-width: 1024px) {
    //       width: 60px;
    //     }
    //     @media screen and (max-width: 768px) {
    //       transform: rotate(90deg);
    //     }
    //     @media screen and (max-width: 767px) {
    //       margin-right: 156px;
    //     }
    //   }
    //   .tier-levels-info-list-item {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     @media screen and (max-width: 767px) {
    //       flex-direction: row-reverse;
    //       justify-content: flex-end;
    //       width: 220px;
    //       position: relative;
    //       z-index: 9;
    //     }

    //     p {
    //       font-size: 14px;
    //       line-height: 1.2;

    //       letter-spacing: 0.05em;
    //       @media screen and (max-width: 1024px) {
    //         font-size: 12px;
    //       }
    //       @media screen and (max-width: 767px) {
    //         margin: 0 0 0 10px;
    //       }
    //     }

    //     .tier-levels-info-list-item-imgCont {
    //       width: 100px;
    //       height: 100px;
    //       top: 110px;
    //       left: 110px;
    //       opacity: 0.5px;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       background-size: 130px !important;
    //       background-position: center;
    //       border-radius: 100%;
    //       @media screen and (max-width: 1024px) {
    //         width: 80px;
    //         height: 80px;
    //       }
    //       @media screen and (max-width: 768px) {
    //         width: 60px;
    //         height: 60px;
    //       }
    //       img {
    //         width: 50px;
    //         height: 50px;
    //         @media screen and (max-width: 1024px) {
    //           width: 40px;
    //           height: 40px;
    //         }
    //       }
    //     }
    //   }
    // }
    .tier-levels-info-section {
      display: flex;
      flex-direction: row;
      // align-items: center;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      .tier-levels-info-list {
        width: 100%;
        // height: 294px;
        height: auto;
        border-radius: 24px;
        border: 1px;
        // background: linear-gradient(
        //   223.88deg,
        //   rgba(255, 255, 255, 0) 1.79%,
        //   rgba(255, 255, 255, 0.08) 98.47%
        // );
        margin-block: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        // justify-content: flex-start;
        // @media screen and (max-width: 768px) {
        //   flex-direction: column;
        //   height: auto;
        //   justify-content: flex-start;
        // }
        .item-img {
          width: 40%;
          // width: 60px;
          transform: rotate(90deg);
          margin-right: 216px;
          @media screen and (max-width: 768px) {
            width: 60px;
          }
          // @media screen and (max-width: 768px) {
          //   transform: rotate(90deg);
          // }
          // @media screen and (max-width: 767px) {
          //   margin-right: 156px;
          // }
        }
        .tier-levels-info-list-item {
          display: flex;
          // flex-direction: column;
          flex-direction: row-reverse;
          // justify-content: center;
          justify-content: flex-end;
          align-items: center;
          width: 320px;
          position: relative;
          z-index: 9;
          // @media screen and (max-width: 767px) {
          //   flex-direction: row-reverse;
          //   justify-content: flex-end;
          //   width: 220px;
          //   position: relative;
          //   z-index: 9;
          // }

          p {
            font-size: 14px;
            // font-size: 12px;

            line-height: 1.2;

            letter-spacing: 0.05em;
            margin: 0 0 0 10px;

            // @media screen and (max-width: 1024px) {
            //   font-size: 12px;
            // }
            // @media screen and (max-width: 767px) {
            //   margin: 0 0 0 10px;
            // }
          }

          .tier-levels-info-list-item-imgCont {
            width: 100px;
            height: 100px;
            // width: 60px;
            // height: 60px;
            top: 110px;
            left: 110px;
            opacity: 0.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: 130px !important;
            background-position: center;
            border-radius: 100%;
            // @media screen and (max-width: 1024px) {
            //   width: 80px;
            //   height: 80px;
            // }
            // @media screen and (max-width: 768px) {
            //   width: 60px;
            //   height: 60px;
            // }
            img {
              width: 50px;
              height: 50px;
              // width: 40px;
              // height: 40px;
              // @media screen and (max-width: 1024px) {
              //   width: 40px;
              //   height: 40px;
              // }
            }
          }
        }
      }
      .tier-levels-info-rightSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        max-width: 65%;
        gap: 16px;
        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
        img {
          max-width: 100%;
          // max-height:320px;
          background: linear-gradient(
            223.88deg,
            rgba(255, 255, 255, 0) 1.79%,
            rgba(255, 255, 255, 0.08) 98.47%
          );
        }
        .latest-games-wrapper {
          max-width: 100%;
          margin-top: 32px;
          background: linear-gradient(
            223.88deg,
            rgba(255, 255, 255, 0) 1.79%,
            rgba(255, 255, 255, 0.08) 98.47%
          );
          display: flex;
          // flex-wrap: wrap;
          justify-content: space-around;
          @media screen and (max-width: 768px) {
            max-width: 100%;
          }
          @media screen and (max-width: 425px) {
            flex-wrap: wrap;
          }
          .latest-row {
            margin: 16px;
            width: 320px;
            display: flex;
            flex-wrap: wrap;
          }
        }
        .tier-levels-info-claim-section {
          display: flex;
          width: 100%;
          height: 400px;

          // max-height:100%;
          // background: linear-gradient(
          //   223.88deg,
          //   rgba(255, 255, 255, 0) 1.79%,
          //   rgba(255, 255, 255, 0.08) 98.47%
          // );
          background: url("../../images/claimCard.png") no-repeat;
          background-size: contain;
          @media screen and (max-width: 425px) {
            width: 100%;
          }
          button {
            height: 40px;
            width: auto;
            margin-top: 174px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 425px) {
              margin-top: 100px;
              height: 40px;
              width: 20px;
            }
          }
        }
      }
    }
  }
  .subscriptionwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block: 100px;
    .subscriptionContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        @media screen and (max-width: 768px) {
          width: 80%;
        }
        @media screen and (max-width: 425px) {
          width: 90%;
        }
      }
      .subscriptionSubTitle {
        font-size: 32px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 12px;
        text-align: left;
        color: #ffffff;
        margin-top: 20px;
        @media screen and (max-width: 425px) {
          font-size: 24px;
        }
      }
      .subscriptionBox {
        width: 100%;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 100px;
        padding: 50px 40px 50px 40px !important;
        background: linear-gradient(
          223.88deg,
          rgba(255, 255, 255, 0) 1.79%,
          rgba(255, 255, 255, 0.1) 98.47%
        );
        @media screen and (max-width: 768px) {
          width: 80%;
        }
        @media screen and (max-width: 425px) {
          width: 90%;
        }
        label {
          color: #ffffff;
        }
        input {
          border-radius: 20px;
          border: 1px solid rgb(78, 70, 70);
          width: 70%;
          padding: 10px;
          background: rgba(0, 0, 0, 0.12);
          margin-top: 34px;
          color: #ffffff;
          @media screen and (max-width: 425px) {
            width: 90%;
          }
        }
        button {
          margin-top: 75px;
        }
      }
    }
  }
}

.container .home-banner-content2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 425px) {
    flex-direction: column-reverse;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media screen and (max-width: 1024px) {
      width: 500px;
    }
    @media screen and (max-width: 768px) {
      width: 350px;
      object-fit: contain;
    }
  }
}
.home-banner-content2 .home-banner-content-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 12px;
    text-align: left;
    @media screen and (max-width: 1024px) {
      font-size: 40px;
    }
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  p {
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    @media screen and (max-width: 1024px) {
      font-size: 15px;
    }
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
  button {
    width: 100px;
  }
}
.container .home-banner-sliding {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  .home-banner-sliding-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-inline: auto;
    img {
      cursor: pointer;
    }
    .slide-point {
      width: 15px;
      height: 15px;
      margin-right: 10px;
      border-radius: 100%;
      background: rgba(68, 69, 111, 1);
      transform: all 0.3s ease;
    }
    .active {
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255, 1);
    }
  }
}

.w-primary {
  width: 350px;
  height: 45px;
  @media screen and (max-width: 375px) {
    width: 200px;
    height: 45px;
  }
}

.w-secondary {
  width: 500px;
  height: 45px;
  @media screen and (max-width: 375px) {
    width: 300px;
    height: 45px;
  }
}

.w-full {
  width: 100%;
}
