

.Crop-Controls {
  margin-bottom: 10px;
}
.Crop-Controls > * {
  margin-bottom: 3px;
}

img {
  max-width: 100%;
}
.ReactCrop .ord-nw:after{
  display: none;
}
.ReactCrop .ord-ne:after{
  display: none;
}
.ReactCrop .ord-sw:after{
  display: none;
}
.ReactCrop .ord-se:after{
  display: none;
}

.relative{
  position: relative;
}
.flex{
  display: flex;
}
.flex-col{
  flex-direction: column;
}
.flex-1{
  flex: 1 1 0%;
}
.items-center{
  align-items: center;
}
.justify-center{
  justify-content: center;
}
.box-img{
  min-height: 115px !important;
  border-radius: 4px;
  border: 2px dashed var(--Black, #111);
  background: var(--White-White-100, #FFF);
  box-shadow: 4px 4px 0px 0px #111;
  font-size: 0.8rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}
.inputBtn{
  position: relative;
    display: inline-block;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    color: #3a3a3a;
    text-align: center;
    width: 100%;
    padding-bottom: 22px;
}
.inputFile {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.img-list{
  margin-top: 20px;
  display: flex;
  height: 122px;
  gap:14px
}
.img-item{
    width: 128px;
    height: 122px;
    border-radius: 12px;
}
.closebutton{
  width: 48px;
  height: 32px;
  position: absolute;
  bottom: -20px;
  left: 30%;
}
.clipping{
  padding: 10px 20px;
  background: #d7d7d7;
  border-radius: 12px;
}