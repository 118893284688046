.page-not-found-container {
	height: 100vh;

	&>img {

		@media screen and (max-width: 700px) {
			height: 150px;
		}
	}

	.label-404 {
		color: var(--White-White-100, #FFF);
		-webkit-text-stroke-width: 1;
		-webkit-text-stroke-color: #000;
		font-size: 240px;
		font-weight: 400;
		letter-spacing: -4.8px;
		line-height: 100px;
		margin-top: 0;
	}

	&>p {
		margin-top: 54px;
		margin-bottom: 60px;
		font-weight: 700;
		font-size: 64px;
		line-height: 56px;
		text-align: center;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		color: #FFFFFF;

		@media screen and (max-width: 700px) {
			font-size: 24px;
		}
	}
}