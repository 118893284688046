.submit-inner-box {
	.submit-label {
		color: #000 !important;
		font-weight: 500;
		font-size: 15px;
		line-height: 28px;
	}

	.upload-wrap {
		margin: 63px 17px 0;

		.upload-container {
			border-radius: 24px;
			display: flex;
			overflow: hidden;
			width: 100%;
			min-height: 200px;
			flex-direction: column;
			align-items: center;
			position: relative;
			background: transparent url("../images/border-dashed.svg") no-repeat;
			background-position: inherit;
			background-size: contain;

			@media screen and (max-width: 767px) {
				max-height: none;
				// background : unset;
				// border: 1px dashed rgba(255, 255, 255, 0.4);
				// border-radius: 24px;
				background-image: repeating-linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4) 10px, transparent 10px, transparent 19px, rgba(255, 255, 255, 0.4) 19px), repeating-linear-gradient(90deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4) 10px, transparent 10px, transparent 19px, rgba(255, 255, 255, 0.4) 19px), repeating-linear-gradient(180deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4) 10px, transparent 10px, transparent 19px, rgba(255, 255, 255, 0.4) 19px), repeating-linear-gradient(270deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4) 10px, transparent 10px, transparent 19px, rgba(255, 255, 255, 0.4) 19px);
				background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
				background-position: 0 0, 0 0, 100% 0, 0 100%;
				background-repeat: no-repeat;
				border-radius: 12px;
			}

			&-media {
				position: relative;
				width: 100%;
				min-height: 200px;

				.uploaded-img {
					border-radius: 12px;
					width: 100%;
					object-fit: contain;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;

					@media screen and (max-width: 767px) {
						width: 100%;
					}
				}

				.grey-play-button {
					position: absolute;
					top: 35%;
					left: 42%;
					cursor: pointer;
					width: 105px;
					height: 105px;

					@media screen and (max-width: 767px) {
						top: 40%;
						left: 36%;
						width: 70px;
						height: 70px;
					}
				}

				&>img:last-child {
					position: absolute;
					top: 5%;
					right: 12px;
					cursor: pointer;
				}
			}

			&-wrap {
				position: relative;
				margin-bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				width: 100%;
				padding-left: 2%;
				padding-right: 2%;

				&.active {
					background-color: white;
					opacity: 0.5;
				}

				.upload-icon {
					width: 76px;
					height: 76px;
				}

				&-label {
					margin-top: 20px;
					color: var(--Primary-Orange, #FFA12B);
					text-align: center;
					-webkit-text-stroke: 0.5px #000;
					font-size: 20px;
					line-height: 120%; /* 24px */
					margin-bottom: 10px;
				}

				&-option {
					display: flex;
					flex-direction: column;
					justify-content: center;

					&-element {
						display: flex;
						align-items: center;

						&:last-child {
							margin-top: 16px;
						}

						p {
							margin: 0;
							margin-left: 8px;
							font-size: 16px;
							line-height: 150%;
							text-align: center;
							color: #000;
						}
					}

					&-rectangle {
						width: 7px;
						height: 7px;
						background-color: #000;
					}
				}

				&-text {
					margin-top: 10px;
					margin-bottom: 0;
					font-size: 14px;
					line-height: 26px;
					text-align: center;
					color: #000;
				}

				input {
					display: none;
				}

				&-progress {
					display: flex;
					width: 100%;
					flex-direction: column;
					align-items: center;

					p {
						text-align: center;
						color: #FFFFFF;
						opacity: 0.5;
						margin-bottom: 0;
					}

					.progress {
						margin: 12px 0;
						width: 90%;

						&-bar {
							width: 100%;
							height: 100%;
							border-radius: 12px;
							background-color: white;
							opacity: 0.5;
							background-color: #424867;
							animation: flowAnimation 2s linear infinite;
						}

						@keyframes flowAnimation {
							from {
								background-color: #424867;
							}

							to {
								background-color: white;
								opacity: 0.5;
							}
						}
					}
				}
			}

			&-drag {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				height: 100%;
				width: 100%;
				border-radius: 24px;
			}
		}

		.upload-logo-container {
			display: flex;
			justify-content: center;

			@media (max-width: 509px) {
				display: block !important;
				text-align: center;
				text-align: -webkit-center;
			}

			.logo-container {
				width: 160px;
				height: 160px;
				background: transparent url("../images/logo-border.svg") no-repeat;
				display: flex;
				align-items: center;
				justify-content: center;


				&-media {
					position: relative;
					width: 100%;
					height: 100%;

					.uploaded-img {
						border-radius: 50%;
						height: 100%;
						width: 100%;
						object-fit: cover;
						background-repeat: no-repeat;
						background-position: center;

						@media screen and (max-width: 767px) {
							width: 100%;
						}
					}

					&>img:last-child {
						position: absolute;
						top: 5%;
						right: 0;
						cursor: pointer;
						width: 44px;
						height: 44px;
					}
				}

				input {
					display: none;
				}

				&-wrap {
					cursor: pointer;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					&-progress {
						display: flex;
						width: 100%;
						flex-direction: column;
						align-items: center;

						p {
							text-align: center;
							color: #FFFFFF;
							opacity: 0.5;
							margin-bottom: 0;
						}

						.progress {
							margin: 12px 0;
							width: 90%;

							&-bar {
								width: 100%;
								height: 100%;
								border-radius: 12px;
								background-color: white;
								opacity: 0.5;
								background-color: #424867;
								animation: flowAnimation 2s linear infinite;
							}

							@keyframes flowAnimation {
								from {
									background-color: #424867;
								}

								to {
									background-color: white;
									opacity: 0.5;
								}
							}
						}
					}
				}
			}

			.right-container {
				position: relative;
				margin-bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				margin-left: 10%;
				padding-left: 2%;
				padding-right: 2%;

				@media (max-width: 509px) {
					margin-left: 0%;
				}

				&.active {
					background-color: white;
					opacity: 0.5;
				}

				.upload-icon {
					width: 76px;
					height: 76px;
				}

				&-label {
					font-weight: 600;
					font-size: 16px;
					line-height: 30px;
					color: #FFFFFF;
					opacity: 0.5;
					margin-bottom: 10px;
				}

				&-option {
					display: flex;
					flex-direction: column;
					justify-content: center;

					&-element {
						display: flex;
						align-items: center;

						&:last-child {
							margin-top: 16px;
						}

						p {
							margin: 0;
							margin-left: 8px;
							font-size: 16px;
							line-height: 150%;
							text-align: center;
							color: #FFFFFF;
							opacity: 0.5;
						}
					}

					&-rectangle {
						width: 7px;
						height: 7px;
						background-color: #FFFFFF;
						opacity: 0.5;
					}
				}

				&-text {
					margin-top: 10px;
					margin-bottom: 0;
					font-size: 14px;
					line-height: 26px;
					text-align: center;
					color: #FFFFFF;
					opacity: 0.5;
				}

			}
		}
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

.token-logo {
	width: 55px;
	height: 55px;
	border-radius: 50%;
}