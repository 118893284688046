@import "../../Style/_mixin.scss";

.game-detail-card {
	background-image: url("../../images/game-card-wrapper.svg");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	border-radius: 24px;
	padding: 100px;
	overflow: hidden;
	width: 50%;

	@media (max-width:1280px) {
		padding:80px;
	}
	@media (max-width:991px) {
		width: 48%;
		padding: 30px;
	}
	@media (max-width:767px) {
		width: 100%;
		padding: 40px;
	}

	.game-detail-card-container {
		width: 100%;
	}

	.game-detail-card-body {
		width: 100%;
		display: block;
	}

	&.game-list {
		padding: 30px;
	}

	.image-container {
		position: relative;
		margin-top: 20px;
		padding: 0 20px;

		&>p {
			position: absolute;
			top: 16px;
			left: 16px;
			height: 29px;
			padding-left: 12px;
			padding-right: 12px;
			background: linear-gradient(223.88deg, rgba(79, 189, 223, 0) 1.79%, #4D3AC7 98.47%);
			backdrop-filter: blur(20px);
			border-radius: 8px;
			margin-bottom: 0;
			text-transform: uppercase;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&>.thumbnail {
			border-radius: 24px;
			width: 100%;
			aspect-ratio: 4/3;
			object-fit: cover;
		}

		&>.logo {
			position: absolute;
			left: 26px;
			bottom: 6px;
			width: 84px;
			height: 84px;
			border-radius: 42px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		@media screen and (max-width: 440px) {
			&>.logo {
				width: 40px;
				height: 40px;
			}
			&>p {
				font-size: 5px;
			}
		}

		@media screen and (min-width: 440px) {
			&>.logo {
				width: 90px;
				height: 90px;
			}
			&>p {
				font-size: 14px;
			}
		}
	}

	.game-card-header {
		margin-top: -24px;
		padding-left: 23px;
		padding-right: 24px;
		display: flex;
		align-items: center;

		&>img {
			z-index: 99;
			border-radius: 50%;
			height: 70px;
			width: 70px;
		}

		&>p {
			margin-left: 9px;
			margin-top: 20px;
			font-weight: 700;
			font-size: 23px;
			line-height: 150%;
			letter-spacing: 0.1em;
			color: #FFFFFF;
			text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
			margin-bottom: 0;
		}
	}

	.game-card-body {
		padding: 24px;

		.card-body-title {
			@include cairo(700, 24px, 150%);
			color: #000;
			text-align: left;
			text-shadow: 0px 0px 8px 0px #FFFFFF73;
		}

		.card-body-row {
			margin-top: 32px;

			display: flex;
			justify-content: space-between;
			align-items: center;

			&>span:nth-child(1) {
				@include cairo(400, 16px, 24px);
				color: #9AA1AC;
			}

			&>span:nth-child(2) {
				@include cairo(700, 20px, 150%);
				color: #000;
			}
		}

		.card-body-row+.card-body-row {
			margin-top: 12px;
		}

		.progress-bar-container {
			margin-top: 16px;

			&>.progress-bar-description {
				@include cairo(400, 12px);
				color: #9AA1AC;
        text-align: left;
			}

			.progress-bar {
				margin-top: 8px;
			}
		}
	}

	.game-card-footer {
		width: 100%;
		padding: 0 24px;

		display: flex;
		align-items: center;
		justify-content: center;
		gap: 16px;

		.btn-action {
			flex: 1 1;
			position: relative;
			height: 64px;
			background: rgba(255, 255, 255, 0.02);
			border: none;
			box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1), inset 0px 4px 11px rgba(0, 0, 0, 0.16),
				inset 0px 0px 2px rgba(255, 255, 255, 0.4);
			backdrop-filter: blur(16px);
			border-radius: 4px;
			overflow: hidden;
			@include workSans(600, 16px, 130%);
			text-transform: uppercase;
			color: #ffffff;
			text-decoration: none;

			display: flex;
			justify-content: center;
			align-items: center;

			&::before {
				position: absolute;
				content: '';
				height: 3px;
				left: 0px;
				right: 0px;
				bottom: 0px;
				background: linear-gradient(269.99deg,
						#eeeeee -69.29%,
						#fffee2 -62.4%,
						rgba(255, 255, 255, 0.950883) -57.4%,
						#df68fd -51.6%,
						rgba(255, 255, 255, 0.815523) -44.74%,
						#b7eee5 -35.82%,
						#20282e -12.7%,
						#29353c -5.34%,
						rgba(255, 255, 255, 0.596267) -1.97%,
						#c9fbfe 5.76%,
						#79a2f2 11.83%,
						rgba(255, 255, 255, 0.741036) 17.11%,
						#79a2f2 23.13%,
						#5d23fd 33.9%,
						#ffffff 45.48%,
						#51555e 79.22%,
						#699cff 89.6%,
						#ffffff 92.26%,
						#79a2f2 95.03%,
						#ffffff 97.45%);
			}

			&:hover {
				color: #ffffff;
			}
		}
	}
}
