@import "../../../Style/_mixin.scss";

.my-game-wrapper {
  display: flex;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    padding-top: 12rem;
  }

  @media screen and (max-width: 576px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .content {
    flex: 1;
    padding-left: 60px;

    @media screen and (max-width: 991px) {
      padding-left: 26px;
      padding-left: 0;
    }

    @media screen and (max-width: 768px) {
      padding-left: 0;
    }
  }
}

.admin-table-box {
  background-image: url("../../../images/submit-form-wrapper.png");
  background-position: center;
  background-size: 100% 100%;
  padding: 50px;

  @media screen and (max-width: 991px) {
    &.participating-wrap {
      background: transparent;
      padding: 0;
    }
  }

  .main-container {
    padding: 48px 30px 36px 27px;
    .project-nosubmit-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: auto;
      h3 {
        color: black !important;
        margin-top: 50px;
        font-size: 40px;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: 0.1em;
        text-align: center;
		

      }
    }

    @media screen and (max-width: 991px) {
      padding: 38px 40px 70px 20px;
    }

    .alert-title-wrapper {
      border-radius: 20px;
      background: conic-gradient(
        from 180deg at 50% 50%,
        #eeeeee 0deg,
        #fffee2 14.87deg,
        rgba(255, 255, 255, 0.950883) 25.67deg,
        #df68fd 38.19deg,
        rgba(255, 255, 255, 0.815523) 53deg,
        #b7eee5 72.26deg,
        #20282e 122.18deg,
        #29353c 138.07deg,
        rgba(255, 255, 255, 0.596267) 145.34deg,
        #c9fbfe 162.04deg,
        #79a2f2 175.13deg,
        rgba(255, 255, 255, 0.741036) 186.54deg,
        #79a2f2 199.54deg,
        #5d23fd 222.78deg,
        #ffffff 247.79deg,
        #51555e 320.65deg,
        #699cff 343.05deg,
        #ffffff 348.79deg,
        #79a2f2 354.77deg,
        #ffffff 360deg
      );
      padding: 1px;

      .alert-title-container {
        background: #25222f;
        display: flex;
        align-items: center;
        border-radius: 20px;
        height: 67px;
        padding-left: 28px;
        padding-right: 28px;

        p {
          margin-left: 12px;
          font-size: 15px;
          line-height: 28px;
          margin-bottom: 0;
        }
      }
    }

    .game-title-container {
      position: relative;
      margin-top: 32px;

      .game-title-container-row {
        position: relative;
        height: 93px;
        padding-left: 26px;
        padding-right: 26px;
        border-radius: 4px;
        border: 2px solid var(--Black, #111);
        background: var(--White-White-100, #FFF);
        box-shadow: 4px 4px 0px 0px #111;
        cursor: pointer;
        z-index: 100;

        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div:first-child {
          display: flex;
          align-items: center;

          img {
            width: 66px;
            height: 66px;
            border-radius: 50%;
            object-fit: cover;

            @media screen and (max-width: 900px) {
              width: 56px;
              height: 56px;
            }

            @media screen and (max-width: 767px) {
              width: 46px;
              height: 46px;
            }
          }

          span {
            margin-left: 16px;
            @include cairo(700, 32px, 150%);
            color: black !important;

            @media screen and (max-width: 1280px) {
              @include cairo(700, 20px, 150%);
            }

            @media screen and (max-width: 900px) {
              @include cairo(700, 20px, 150%);
            }

            @media screen and (max-width: 767px) {
              @include cairo(700, 16px, 150%);
            }
          }
        }

        & > img {
          cursor: pointer;
          width: 5%;
        }
      }

      .game-detail-card {
        position: absolute;
        z-index: 99;
        width: 100%;
        max-width: unset;
        top: 120px;
        left: 0;
        border-radius: 4px;
        border: 2px solid var(--Black, #111);
        background: var(--White-White-100, #FFF);
        box-shadow: 4px 4px 0px 0px #111;
        padding: 0px 48px 55px 48px;

        .game-detail-card-header {
          padding: 16px 0;
          border-bottom: 1px solid #4f5258;

          display: flex;
          justify-content: space-between;

          @media screen and (max-width: 991px) {
            flex-direction: column;

            .btn-action-link {
              margin-top: 16px;
            }
          }

          .game-detail-card-header-ido {
            @include cairo(400, 16px, 24px);
            color: #9aa1ac;

            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;

            .date {
              @include cairo(700, 16px, 24px);
              color: #ffffff;
            }
          }

          .btn-action-link {
            height: 64px;
          }
        }

        .game-detail-card-body {
          padding: 24px 0;
          border-bottom: 1px solid #4f5258;

          display: flex;
          justify-content: space-between;
          // align-items: center;

          @media screen and (max-width: 1399px) {
            flex-wrap: wrap;

            .body-item:nth-child(n + 4) {
              margin-top: 24px;
            }
          }

          @media screen and (max-width: 767px) {
            width: 30px;
          }

          .body-item {
            flex: 1 1;
            width: 25%;

            display: flex;
            flex-direction: column;
            align-items: stretch;

            @media screen and (max-width: 1399px) {
              flex: 0 0 auto;
              width: 50%;
            }

            & > div:nth-child(1) {
              @include cairo(400, 16px, 24px);
              color: #9aa1ac;
              white-space: nowrap;
            }

            & > div:nth-child(2) {
              margin-top: 16px;
              @include cairo(700, 18px, 24px);
              color: black !important;
            }
          }

          .body-divider {
            margin: 0 3%;
            width: 1px;
            height: 46px;
            background: #e1e1e1;

            @media screen and (max-width: 1399px) {
              display: none;
            }
          }
        }

        .game-detail-card-footer {
          margin-top: 24px;

          display: flex;
          gap: 10%;

          @media screen and (max-width: 576px) {
            flex-direction: column;
            gap: 24px;
          }

          .game-detail-card-footer-row {
            flex: 1 1;

            display: flex;
            flex-direction: column;

            & > div {
              display: flex;
              align-items: center;

              & > div:first-child {
                flex: auto;
                @include cairo(400, 16px, 24px);
                color: #9aa1ac;
              }

              & > div:last-child {
                @include cairo(700, 24px, 16px);
                color: black !important;
                font-size: 13px;
                max-width: 210px;
              }
            }

            & > div + div {
              margin-top: 24px;
            }
          }
        }
      }
    }

    .game-detail-container {
      margin-top: 32px;

      .game-detail-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;

        &:first-child {
          margin-top: 0;
        }

        &:nth-child(2) {
          .game-detail-left {
            p {
              white-space: nowrap;
            }
          }
        }

        &:nth-child(3) {
          .game-detail-left {
            p {
              white-space: nowrap;
            }
          }
        }

        &:nth-child(4) {
          .game-detail-left {
            p {
              white-space: nowrap;
            }
          }
        }

        .game-detail-left {
          display: flex;
          align-items: center;

          & > img:first-child {
            width: 51px;
            height: 51px;
          }

          p {
            margin-bottom: 0;
            margin-left: 20px;
            font-weight: 600;
            font-size: 20px;
            line-height: 32px;
            color: black !important;
          }

          & > img:last-child {
            margin-left: 8px;
            width: 16px;
            height: 16px;
          }
        }

        .game-detail-right {
          .btn-action-link {
            text-transform: uppercase;
            height: 64px;
            width: 241px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .admin-table-box {
    .main-container {
      .alert-title-wrapper {
        border-radius: 16px;
        padding: 1px;

        .alert-title-container {
          border-radius: 16px;
          height: 67px;
          padding-left: 13px;
          padding-right: 20px;

          p {
            font-size: 12px;
            line-height: 150%;
          }
        }
      }

      .game-title-container {
        margin-top: 17px;

        &-row {
          border-radius: 16px;
          height: 49px;
          padding-left: 20px;
          padding-right: 20px;

          & > div:first-child {
            display: flex;
            align-items: center;

            img {
              width: 32px;
              height: 32px;
              border-radius: 50%;
            }

            p {
              margin-left: 16px;
              font-size: 16px;
            }
          }
        }

        .game-detail-card {
          border-radius: 16px;
          padding: 80px 20px 20px 20px;

          &-header {
            padding-bottom: 20px;
            border-bottom: 1px solid #4f5258;

            & > p {
              font-size: 16px;
            }

            &-row {
              & > p {
                font-weight: 700;
                font-size: 24px;
              }

              & > span {
                margin-left: 24px;
                font-size: 14px;
                line-height: 24px;
                color: #9aa1ac;
              }
            }
          }

          &-body {
            padding-top: 20px;
            padding-bottom: 20px;

            &-left {
              & > div {
                & > p:first-child {
                  font-size: 16px;
                }

                & > p:last-child {
                  font-size: 24px;
                }
              }

              & > div:last-child {
                margin-top: 16px;

                & > p:last-child {
                  margin-top: 4px;
                  font-size: 20px;
                }
              }
            }

            &-right {
              & > div {
                display: flex;
                flex-direction: column;

                & > p:first-child {
                  font-size: 16px;
                }

                & > p:last-child {
                  margin-top: 8px;
                  font-size: 20px;
                }
              }

              & > div:last-child {
                margin-top: 20px;
                width: 100%;
              }
            }
          }
        }
      }

      .game-detail-container {
        margin-top: 17px;

        .game-detail-row {
          .game-detail-left {
            & > img:first-child {
              width: 28px;
              height: 28px;
            }

            p {
              font-size: 14px;
              line-height: 32px;
            }
          }

          .game-detail-right {
            height: 40px;
            width: 200px;

            .btn-action-link {
              height: 100%;
              width: 200px;
              font-weight: 600;
              font-size: 12px;
              line-height: 130%;
              padding: 0;
              min-width: unset;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .admin-table-box {
    .main-container {
      .game-detail-container {
        .game-detail-row {
          .game-detail-right {
            margin-left: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .admin-table-box {
    .main-container {
      padding: 15px 9px;

      .alert-title-wrapper {
        border-radius: 12px;

        .alert-title-container {
          border-radius: 12px;
          height: unset;
          padding-left: 13px;
          padding-right: 15px;
          align-items: flex-start;
          padding-top: 10px;
          padding-bottom: 10px;

          & > img {
            margin-top: 3px;
            width: 24px;
            height: 24px;
          }

          p {
            font-weight: 400;
            font-size: 15px;
            line-height: 150%;
          }
        }
      }

      .game-title-container {
        margin-top: 24px;

        &-row {
          border-radius: 24px;
          height: 74px;
          padding-left: 10px;
          padding-right: 20px;

          & > div:first-child {
            display: flex;
            align-items: center;

            img {
              width: 53px;
              height: 53px;
              border-radius: 50%;
            }

            p {
              margin-left: 16px;
              font-weight: 700;
              font-size: 20px;
              line-height: 150%;
            }
          }
        }

        .game-detail-card {
          border-radius: 24px;
          padding: 20px 20px 20px 10px;

          &-header {
            padding-bottom: 35px;

            &-left {
              &-row {
                flex-direction: column;

                & > p {
                  font-weight: 700;
                  font-size: 32px;
                  line-height: 24px;
                }

                & > span {
                  margin-top: 8px;
                  margin-left: unset;
                }
              }
            }

            .btn-action-link {
              height: 64px;
            }
          }

          &-body {
            padding-top: 35px;
            padding-bottom: 35px;
            display: flex;
            flex-direction: column;

            &-right {
              margin-left: unset;
              margin-top: 24px;

              & > div {
                display: flex;
                flex-direction: column;

                & > p:first-child {
                  font-size: 16px;
                }

                & > p:last-child {
                  margin-top: 8px;
                  font-size: 20px;
                }
              }

              & > div:last-child {
                margin-top: 16px;
              }
            }
          }

          &-footer {
            margin-top: 36px;

            &-row {
              flex-direction: column;
              width: 100%;

              & > div {
                width: 100%;
                height: unset;
                border-right: none;
                flex-direction: row;
                justify-content: space-between;

                & > p:first-child {
                  font-size: 16px;
                }

                & > p:last-child {
                  margin-top: unset;
                  font-weight: 700;
                  font-size: 24px;
                }
              }
            }
          }
        }
      }

      .game-detail-container {
        margin-top: 24px;

        .game-detail-row {
          flex-direction: column;

          .game-detail-left {
            width: 100%;

            & > img:first-child {
              width: 51px;
              height: 51px;
            }

            p {
              font-size: 20px;
              line-height: 32px;
            }

            & > img:last-child {
              margin-top: 3px;
            }
          }

          .game-detail-right {
            margin-top: 24px;
            height: 64px;
            width: 241px;

            .btn-action-link {
              height: 100%;
              width: 241px;
              font-weight: 600;
              font-size: 12px;
              line-height: 130%;
              padding: 0;
              min-width: unset;
            }
          }
        }
      }
    }
  }
}
