@import '../../Style/_mixin.scss';

.modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	overflow: auto;
	z-index: 1000;

	display: block;

	.modal-wrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 356px;
		padding: 18px 24px 24px;
		background: url('../../images/submit-form-wrapper.png') center/100% 100% no-repeat;
		// background: #171524;
		// border-radius: 24px;

		@media screen and (min-width: 992px) {
			width: 512px;
		}

		@media screen and (max-width: 991px) {
			width: 356px;
		}

		@media screen and (max-width: 768px) {
			top: 120px;
			left: 16px;
			right: 16px;
			transform: none;
			width: auto;
		}

		.modal-title {
			margin-bottom: 24px;
			width: 100%;
			border-bottom: 1px solid black;
			color: rgb(68, 61, 61);
			padding-top: 20px;

			@media screen and (min-width: 992px) {
				@include cairo(700, 16px, 30px);
				padding-bottom: 12px;
			}

			@media screen and (max-width: 991px) {
				@include cairo(700, 12px, 22px);
				padding-bottom: 8px;
			}
		}

		.modal-actions {
			margin-top: 32px;
			width: 100%;

			display: flex;
			align-items: center;
			gap: 16px;

			@media screen and (max-width: 768px) {
				margin-top: 28px;
				flex-direction: column-reverse;
			}

			button {
				flex: 1 1;
				border-radius: 4px;
				text-transform: uppercase;
				color: #ffffff;
				overflow: hidden;

				display: flex;
				justify-content: center;
				align-items: center;

				@media screen and (min-width: 992px) {
					height: 64px;
					@include cairo(600, 16px, 130%);
				}

				@media screen and (max-width: 991px) {
					height: 48px;
					@include cairo(600, 12px, 130%);
				}

				@media screen and (max-width: 768px) {
					flex: none;
					width: 209px;
				}
			}

			.btn-primary {
				position: relative;
				background: url('../../images/box/orange_buttons/orange_button_2.png') center/100% 100% no-repeat;
				// background: rgba(255, 255, 255, 0.02);
				border: none;
				// box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1), inset 0px 4px 11px rgba(0, 0, 0, 0.16),
				// 	inset 0px 0px 2px rgba(255, 255, 255, 0.4);
				// backdrop-filter: blur(16px);

				&::after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					height: 3px;
					// background: linear-gradient(269.99deg, #EEEEEE -69.29%, #FFFEE2 -62.4%, rgba(255, 255, 255, 0.950883) -57.4%, #DF68FD -51.6%, rgba(255, 255, 255, 0.815523) -44.74%, #B7EEE5 -35.82%, #20282E -12.7%, #29353C -5.34%, rgba(255, 255, 255, 0.596267) -1.97%, #C9FBFE 5.76%, #79A2F2 11.83%, rgba(255, 255, 255, 0.741036) 17.11%, #79A2F2 23.13%, #5D23FD 33.9%, #FFFFFF 45.48%, #51555E 79.22%, #699CFF 89.6%, #FFFFFF 92.26%, #79A2F2 95.03%, #FFFFFF 97.45%);
				}
			}

			.btn-secondary {
				background: url('../../images/box/orange_buttons/orange_button_2.png') center/100% 100% no-repeat;
				// background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
				// backdrop-filter: blur(20px);
				// box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1), inset 0px 4px 11px rgba(0, 0, 0, 0.16), inset 0px 0px 2px rgba(255, 255, 255, 0.4);
			}
		}

		.modal-close {
			position: absolute;
			background: transparent;

			@media screen and (min-width: 992px) {
				top: -28px;
				right: -28px;
				width: 56px;
				height: 56px;
			}

			@media screen and (max-width: 991px) {
				top: 0;
				right: -16px;
				width: 32px;
				height: 32px;
			}

			@media screen and (max-width: 768px) {
				top: -28px;
				right: -12px;
				width: 56px;
				height: 56px;
			}

			img {
				@media screen and (min-width: 992px) {
					width: 56px;
					height: 56px;
				}

				@media screen and (max-width: 991px) {
					width: 32px;
					height: 32px;
				}

				@media screen and (max-width: 768px) {
					width: 56px;
					height: 56px;
				}
			}
		}

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			width: 135px;
			height: 3px;
			// background: conic-gradient(from 180deg at 50% 50%, #EEEEEE 0deg, #FFFEE2 14.87deg, rgba(255, 255, 255, 0.950883) 25.67deg, #DF68FD 38.19deg, rgba(255, 255, 255, 0.815523) 53deg, #B7EEE5 72.26deg, #20282E 122.18deg, #29353C 138.07deg, rgba(255, 255, 255, 0.596267) 145.34deg, #C9FBFE 162.04deg, #79A2F2 175.13deg, rgba(255, 255, 255, 0.741036) 186.54deg, #79A2F2 199.54deg, #5D23FD 222.78deg, #FFFFFF 247.79deg, #51555E 320.65deg, #699CFF 343.05deg, #FFFFFF 348.79deg, #79A2F2 354.77deg, #FFFFFF 360deg);

			@media screen and (max-width: 768px) {
				display: none;
			}
		}
	}
}