@import '../drawer/animation.scss';

.close-container {
	position: absolute;
	right: 40px;
	top: 40px;
	width: 30px;
	height: 30px;
	cursor: pointer;

	.line-1 {
		position: absolute;
		top: 14px;
		left: 0;
		width: 30px;
		height: 2px;
		background: white;
		transform: rotate(135deg);

		// animation: line1-animation 0.8s ease;
	}

	.line-2 {
		position: absolute;
		top: 14px;
		left: 0;
		width: 30px;
		height: 2px;
		transform: rotate(45deg);
		background: white;
		// animation: line2-animation 0.8s ease;
	}

	.line1-show-animation {
		animation: line1-animation 0.8s ease;
	}

	.line2-show-animation {
		animation: line2-animation 0.8s ease;
	}

	.line1-close-animation {
		animation: line1-close-animation 0.8s ease;
	}

	.line2-close-animation {
		animation: line2-close-animation 0.8s ease;
	}
}