.kyc-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-image: url("../../../images/submit-form-wrapper.png");
  // background-position: center;
  // background-size: 100% 100%;
  .no-wallet-connected {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    h3 {
      color: black !important;
      margin-top: 50px;
      font-size: 40px;
      font-weight: 700;
      line-height: 54px;
      letter-spacing: 0.1em;
      text-align: center;
    }
  }
  & > h2 {
    margin-top: 32px;
    font-weight: 700;
    font-size: 40px;
    line-height: 32px;
    text-align: center;
    color: black !important;

    @media screen and (max-width: 550px) {
      font-size: 24px;
    }
  }

  & > p {
    margin-top: 13px;
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #6c6581;

    @media screen and (max-width: 550px) {
      font-size: 12px;
    }
  }

  .btn-action-link {
    margin-top: 45px;
  }

  .kyc-check-icon {
    width: 154px;
    height: 154px;
  }

  & > h4 {
    margin-top: 21px;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: black !important;
  }

  .text-color {
    color: black !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: 0.05em;

    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }
}
