.modal-container {
	width: 603px;

	.modal-title {
		border-bottom: 1px solid #4F5258;
		padding-bottom: 26px;

		p {
			font-weight: 700;
			font-size: 20px;
			line-height: 37px;
			margin-bottom: 0;
			color: #000 !important;
		}
	}

	.modal-body {
		margin-top: 30px;

		.modal-body-container {
			display: flex;
			justify-content: space-between;
			padding: 21px 44px 36px 24px;
			background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
			backdrop-filter: blur(20px);
			border-radius: 24px;

			.modal-body-left {

				p {
					margin-bottom: 0;
					font-weight: 700;
					font-size: 14px;
					line-height: 150%;
					letter-spacing: 0.01em;
					color: #9AA1AC;
				}

				input {
					margin-top: 22px;
					padding: 12px 0;
					background-color: transparent;
					border: none;
					outline: none;
					color: white;
				}
			}

			.modal-body-right {

				p {
					margin-bottom: 0;
					font-weight: 700;
					font-size: 14px;
					line-height: 150%;
					letter-spacing: 0.01em;
					color: #9AA1AC;
				}

				.network-info {
					margin-top: 34px;
					display: flex;
					justify-content: center;
					align-items: center;

					p {
						margin-bottom: 0;
						font-weight: 700;
						font-size: 18px;
						line-height: 150%;
						letter-spacing: 0.01em;
						color: white;
					}
				}
			}
		}

		.modal-body-desc {
			margin-top: 9px;
			padding-left: 24px;
			padding-right: 48px;
			display: flex;
			justify-content: space-between;

			p {
				margin-bottom: 0;
				font-size: 16px;
				line-height: 24px;
				color: #9AA1AC;
			}
		}
	}

	.modal-footer {
		margin-top: 46px;
		display: flex;
		justify-content: space-between;

		button {
			width: 43%;
		}
	}
}