body {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-family: "Gloria Hallelujah" !important;
  margin: 0;
  padding: 0;
  user-select: none;
}

a {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  text-decoration: none !important;
}

a.hover-line:hover {
  text-decoration: underline;
}

button {
  background: transparent;
  border: none;
}

img {
  border: 0;
  outline: 0;
  vertical-align: middle;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff !important;
}

p {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
}

.project-container {
  display: flex;
  justify-content: center;

  a {
    margin: 3rem 0;
  }
}

.btn-action-link {
  display: flex;
  padding: 4px 16px 16px 16px;
  padding: 10px 16px 16px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  // background: var(--Primary-Orange, #ffa12b);
  background-image: url("../images/box/orange_buttons/orange_button_1.png");
  background-image: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  // width: 100%;
  // height: 100%;

  // box-shadow: 0px 2px 0px 1px #000,
  //   0px 1.2px 0px 0px rgba(255, 255, 255, 0.25) inset,
  //   0px -9px 0px 0px #915101 inset;
  line-height: 120%;
  color: var(--White-White-100, #fff);
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
  /* Text/md */
  -webkit-text-stroke-width: 0.5;
  -webkit-text-stroke-color: var(--White-White-100, #fff);
}

.btn-action-link:hover {
  color: #ffffff;
}

.title-action {
  margin-left: 24px;
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  color: #ffffff;
  letter-spacing: 0.4px;
  text-decoration: none;

  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 174px;
  height: 53px;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    width: 119px;
    height: 39px;
    font-weight: 600;
    font-size: 13px;
    line-height: 32px;
    letter-spacing: 0.1em;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
  }

  @media screen and (max-width: 500px) {
    width: 174px;
    height: 53px;
    font-size: 20px;
    line-height: 32px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-left: 0;
  }

  padding: 22px 22px;
  letter-spacing: 0.4px;
  min-width: 210px;
  text-decoration: none;
}

.weeweepad-container {
  min-height: 100vh;
  background: url("../images/main.png") center 0 no-repeat;
  width: 100%;;
  background-size: cover;
  
  @media screen and (max-width: 991px) {
    background: url("../images/mobile-bg.png") no-repeat;
    width: 100%;
    background-size: cover;
  }
  z-index: 10;
}

.inner-wrap {
  position: relative;

  @media screen and (max-width: 991px) {
    max-width: 100vw;
    margin-top: 0;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.w-full {
  width: 100%;
}

.loading-container {
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #797c83;

  @media screen and (max-width: 767px) {
    min-height: 70px;
  }
}

.d-none {
  display: none;
}

.page-container {
  @media screen and (min-width: 1200px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    padding-left: 70px;
    padding-right: 70px;
  }

  @media screen and (max-width: 1199px) {
    padding-left: 78px;
    padding-right: 78px;
  }

  @media screen and (max-width: 991px) {
    padding-left: 46px;
    padding-right: 46px;
  }

  @media screen and (max-width: 576px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.noncursor-pointer {
  cursor: unset !important;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.m-l-0 {
  margin-left: 0 !important;
}
