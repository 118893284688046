@use 'sass:math' as math;

@keyframes drawer-animation {
	0% {
		transform: translate(0, -100%);
		opacity: 0;
	}

	100% {
		transform: translate(0, 0);
		opacity: 1;
	}
}


$menu_items: 8;

@for $i from 0 to $menu_items {

	@keyframes item-animation-#{$i} {
		0% {
			transform: translate(0, -20px);
			opacity: 0;
		}

		#{math.pow(($i + 1) * 50, $menu-items) + 40%} {
			transform: translate(0, -20px);
			opacity: 0;
		}

		100% {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

}

@for $i from 0 to $menu_items {

	@keyframes item-close-animation-#{$i} {
		0% {
			transform: translate(0, 0);
			opacity: 1;
		}

		#{math.pow(($i + 1) * 50, $menu-items) + 30%} {
			transform: translate(0, -10px);
			opacity: 0;
		}

		100% {
			transform: translate(0, -20px);
			opacity: 0;
		}
	}

}


@keyframes nav-animation {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}


@keyframes line2-animation {
	0% {
		transform: rotate(0deg) scale(0, 0);
		opacity: 0;
	}

	50% {
		transform: rotate(0deg) scale(0, 0);
		opacity: 0;
	}

	100% {
		transform: rotate(45deg) scale(1, 1);
		opacity: 1;
	}
}

@keyframes line1-animation {
	0% {
		transform: rotate(0deg) scale(0, 0);
		opacity: 0;
	}

	60% {
		transform: rotate(0deg) scale(0, 0);
		opacity: 0;
	}

	100% {
		transform: rotate(135deg) scale(1, 1);
		opacity: 1;
	}
}



@keyframes line2-close-animation {
	0% {
		transform: rotate(45deg) scale(1, 1);
		opacity: 1;
	}

	30% {
		transform: rotate(45deg) scale(0.3, 1);
		opacity: 0.7;
	}

	100% {
		transform: rotate(0deg) scale(0, 0);
		opacity: 0;
	}
}

@keyframes line1-close-animation {
	0% {
		transform: rotate(135deg) scale(1, 1);
		opacity: 1;
	}

	50% {
		transform: rotate(0deg) scale(0.5, 1);
		opacity: 0.5;
	}

	100% {
		transform: rotate(0deg) scale(0, 0);
		opacity: 0;
	}
}

@keyframes open-drop-down {
	0% {
		transform: scale(1, 0);
	}

	5% {
		transform: scale(1, 0);
	}

	100% {
		transform: scale(1, 1);
	}
}
