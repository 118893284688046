@import "../../../Style/_mixin.scss";

.account-public-whitelist {
	.whitelist-table {
		margin-top: 32px;

		.table-row {
			height: 94px;
			border-bottom: 1px solid #4F5258;

			display: flex;
			align-items: center;

			&>div {
				flex: 1 1;
				@include cairo(400, 16px, 30px);
				color: #9AA1AC;
			}
		}
	}
}