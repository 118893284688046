@import "../../../../Style/_mixin.scss";

.upload-csv-modal {
	.upload-csv-modal-body {
		&>label {
			@include cairo(400, 14px, 26px);
			color: black;
			opacity: 0.5;
		}

		.btn-upload {
			margin-top: 8px;
			width: 210px;
			height: 48px;
			background: url('../../../../images/box/orange_buttons/orange_button_3.png') center/100% 100% no-repeat;
			// background: linear-gradient(136.24deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
			// 	linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
			// border: 1px solid;
			// border-image-source: linear-gradient(136.24deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
			// border-radius: 4px;
			// backdrop-filter: blur(20px);
			@include workSans(600, 16px, 130%);
			color: #FFFFFF;

			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;
		}

		.btn-sample-csv {
			margin-top: 24px;

			display: flex;
			align-items: center;
			gap: 8px;

			span {
				@include cairo(400, 14px, 28px);
				text-decoration: underline;
				color: black;
				opacity: 0.5;
			}
		}

		.max-whitelisted-input {
			margin-top: 24px;
		}
	}
}