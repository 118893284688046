@import "./_mixin.scss";

button {
  cursor: pointer;
}
/* New Style 12.02.2022 */
.pool-step-wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-bottom: 24px;

  justify-content: center;
  border-bottom: 2px solid #000;

  .pool-step-col {
    flex: 1 1;
    text-align: center;
    position: relative;
    margin-bottom: 10px;
    z-index: 1;

    display: flex;
    flex-direction: column-reverse;

    .pool-step-round {
      position: relative;
      width: 48px;
      height: 48px;
      margin: 0 auto;
      color: var(--Black-Black-40, rgba(0, 0, 0, 0.4));
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);

      /* Text/md */
      -webkit-text-stroke-width: 0.5;
      -webkit-text-stroke-color: var(--Black-Black-40, rgba(0, 0, 0, 0.4));
      font-family: "Gloria Hallelujah";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
      z-index: 1;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .pool-step-name {
      margin-bottom: 12px;
      @include workSans(500, 14px, 26px);
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
      /* Text/sm */
      font-size: 14px;
      line-height: 120%; /* 16.8px */
      display: block;
      color: var(--Black-Black-20, rgba(0, 0, 0, 0.2));
      &.active {
        color: var(--Black-Black-100, #000);
      }
    }
  }

  .pool-step-col.active {
    .pool-step-name {
      color: black !important;
    }
    .pool-step-round {
      border-radius: 4px;
      background: var(--primary-orange-hover, #d0872c);
      // box-shadow: 0px 2px 0px 1px #000, 0px -2px 0px 0px #915101 inset;
      background-size: 48px 48px;
      line-height: 52px;
      width: 100%;
      color: white;
    }
  }

  .pool-step-col.passed {
    .pool-step-name {
      color: var(--Secondary-Green, #1ec64d);
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
    }
    .pool-step-round {
      color: var(--Secondary-Green, #1ec64d);
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
    }
    .pool-step-col {
      &:before {
        content: "";
        background-color: #0a0f12;
        width: 48px;
        height: 48px;
        position: absolute;
        z-index: -1;
        border-radius: 100%;
        left: calc(50% - 24px);
      }
    }
  }

  .pool-step-col:not(:first-child):not(:last-child) {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 24px;
      height: 1px;
      background: rgba($color: #ffffff, $alpha: 0.2);
    }
  }

  .pool-step-col:first-child {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      right: 0;
      bottom: 24px;
      height: 1px;
      background: rgba($color: #ffffff, $alpha: 0.2);
    }
  }

  .pool-step-col:last-child {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 50%;
      bottom: 24px;
      height: 1px;
      background: rgba($color: #ffffff, $alpha: 0.2);
    }
  }
}

.pool-step-container {
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-column-gap: 30px;
  margin: 2rem 0;
}

.pool-step-contentbox {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 2rem;
  position: relative;
}

.pool-step-content h4 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  color: #fff;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #4f5258;
}

.pool-step-content h3 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 300;
  color: #fff;
  margin-bottom: 1rem;
}

.step-form-control {
  margin-bottom: 2rem;

  @media screen and (max-width: 640px) {
    margin-right: 0 !important;
  }
}

.step-form-control label {
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  color: #000 !important;
  margin-bottom: 0.5rem;
  white-space: nowrap;
}

.set-dates-grid .step-form-control {
  width: 20%;
  margin-right: 20px;
  margin-bottom: 40px;

  &.short {
    width: 12%;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 991px) {
    width: 35% !important;
  }
}

.delimiter {
  color: #fff;
  font-weight: 400;
  margin-right: 5px;
  margin-left: -5px;
  line-height: 46px;

  @media screen and (max-width: 640px) {
    display: none;
  }
}

.step-grid-pool {
  display: flex;
  gap: 18px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.poll-select-box {
  flex: 1 1;
  padding: 47px 31px 40px;
  border-radius: 4px;
  border: 2px solid var(--Black, #111);
  background: var(--White-White-100, #fff);
  box-shadow: 4px 4px 0px 0px #111;
  text-align: left;
  cursor: pointer;

  display: flex;
  flex-direction: column;
}

.poll-select-img {
  background: url("../images/round-dark-bg.png") 0 0 no-repeat;
  background-size: 65px 65px;
  width: 75px;
  height: 75px;
  margin: 0;
}

.poll-select-img1 {
  background: url("../images/selectmark.png") 0 0 no-repeat;
  background-size: 65px 65px;
  width: 75px;
  height: 75px;
  margin: 0;
}

.poll-select-name {
  display: flex;
  padding: 0 0 15px;
}

.poll-name-display {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.01em;
  display: inline-block;
  color: #000;
}

.poll-name-recommended {
  margin-top: 8px;
  width: 99px;
  height: 22px;
  border-radius: 4px;
  background: var(--primary-orange-hover, #d0872c);
  box-shadow: 0px 2px 0px 1px #000, 0px -2px 0px 0px #915101 inset;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    @include mulish(700, 10px);
    color: #fff;
    letter-spacing: 0.1px;
  }
}

.poll-select-info {
  max-width: 320px;
  @include cairo(400, 18px, 150%);
  color: #9aa1ac;

  span {
    margin-top: 10px;
    @include cairo(400, 18px, 150%);
    color: #000;

    display: block;
  }
}

.swap-top-grid {
  position: relative;
  max-width: 800px;
  display: flex;
  align-items: center;

  .step-form-control.short {
    width: 170px;
  }
}

.token-price-error {
  margin-top: 100px !important;
  margin-left: 206px !important;
  position: absolute;
}

.swap-cion {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  color: #fff;
  padding: 0 1rem;
}

.raised-amount {
  display: block;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  cursor: pointer;
  color: black;
}

.submit-form-control {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  border: none;
  padding: 12px 15px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
}

.submit-form-control:focus {
  outline: none;
}

select.submit-form-control {
  border-radius: 4px;
  border: 2px solid var(--Black, #111);
  background: var(--White-White-100, #fff);
  box-shadow: 4px 4px 0px 0px #111;
  font-weight: 400;
  font-size: 16px;
  color: #111;
  line-height: 26px;
  padding: 6.5px 17px;
}

.roya-text {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  color: #fff;
  padding: 0 0 0 1rem;
}

.set-dates-grid {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
  }
}

.set-date-info-box {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-column-gap: 9px;
  padding: 21px 26px;
  margin: 0 0 40px;
}

.info-icon-box {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: url("../images/icons/alert-circle-red.png") no-repeat center;
}

.set-date-text {
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  color: #ff4647;
}

.time-lock {
  display: flex;
  align-items: center;
  gap: 23px;
  margin-bottom: 40px;

  label {
    display: flex;
    gap: 20px;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #000;
  }

  .input {
    border: 2px solid #000;
    width: 25.5px;
    height: 25.5px;
    display: block;
    border-radius: 4px;
  }

  input {
    display: none;
  }

  input:checked + .input:before {
    background: #000;
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    position: relative;
    left: 3px;
    top: 3px;
  }

  .info-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    display: flex;
    gap: 23px;

    span {
      display: block;
      width: 24px;
      height: 24px;
      background: url("../images/icons/alert-circle-white.png") no-repeat center;
    }
  }
}

.allocation-container {
  margin-top: 50px;

  .time-lock {
    margin-bottom: 0;
  }
}

.allocation-grid01 {
  display: grid;
  grid-column-gap: 45px;
  grid-template-columns: 1fr auto;
}

.allocation-grid02 {
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
}

.access-label {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  display: block;
  color: #000;
  margin-bottom: 5px;
}

.ratio-control {
  position: relative;
}

.ratio-label {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  line-height: 1.4;
  font-weight: 300;
  color: var(--Black-Black-40, rgba(0, 0, 0, 0.4));
}

.access-grid {
  margin-bottom: 2rem;
}

.access-col {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  &.tab {
    border-radius: 8px;
    background: var(--Black-Black-10, rgba(0, 0, 0, 0.1));
    margin-top: 0;

    .type-label {
      padding: 9px 29px;
      color: var(--Black-Black-40, rgba(0, 0, 0, 0.4));
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
      -webkit-text-stroke-width: 0.5;
      -webkit-text-stroke-color: var(--Black-Black-40, rgba(0, 0, 0, 0.4));
      font-size: 16px;
      line-height: 30px;

      &.checked {
        border-radius: 4px;
        background: var(--primary-orange-hover, #d0872c);
        box-shadow: 0px 2px 0px 1px #000, 0px -2px 0px 0px #915101 inset;
      }
    }
  }
}

.access-switch {
  margin: 0 25px;
  display: none;
}

.type-label {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: 0.45px;
  color: rgba(255, 255, 255, 0.65);
}

.switch {
  position: relative;
  display: inline-block;
  width: 68px;
  height: 40px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 35px;
  width: 35px;
  left: 2px;
  bottom: 2px;
  background: url("../images/radio-check.png") 0 0 no-repeat;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Pool Name Steps */
.pool-label {
  position: absolute;
  top: 0;
  left: 30%;
  padding: 8px 45px;
  @include cairo(600, 20px, 32px);
  text-align: center;
  letter-spacing: 0.1em;
  color: #ffffff;

  &.long {
    margin-left: 30px;
  }
  @media screen and (max-width: 991px) {
    top: 90px;
  }
}

.pool-container {
  display: flex;
}

.pool-name-col {
  flex: 0 0 auto;
  width: calc(100% - 320px - 24px);
  padding: 90px 45px;
  background-image: url("../images/submit-form-wrapper.png");
  background-position: center;
  background-size: 100% 100%;

  @media screen and (max-width: 991px) {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.create-pool {
  padding-top: 70px;

  @media screen and (max-width: 991px) {
    padding-top: 150px;
  }
}

.pool-name-row {
  display: flex;
  gap: 24px;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    gap: 70px;
  }

  .backButton {
    width: 100%;
  }
}

.pool-name-box {
  h5 {
    color: black !important;
  }
}

.pool-name-box h4 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  color: #fff;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #4f5258;
}

.pool-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.pool-steps-select {
  position: relative;
}

.pool-steps-select input {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.pool-steps-select label {
  position: relative;
  padding-left: 65px;
  font-size: 16px;
  line-height: 45px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.45px;
}

.pool-steps-select label::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background-image: url("../images/register-stepbg.png");
  background-size: 45px 45px;
  width: 45px;
  height: 45px;
}

.pool-steps-select input[type="radio"]:checked + label:before {
  background-image: url("../images/selectmark.png");
}

.pool-confirming-info {
  display: inline-block;
  margin-right: 2rem;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.45px;
}

.bottom-border-none::before {
  display: none;
}

.pool-steps-action {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.pool-right-col {
  flex: none;
  width: 320px;

  .pool-label {
    top: -50px;
    left: 40px;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.pool-need-help {
  position: relative;
  padding: 16px;
  background-image: url("../images/submit-form-wrapper.png");
  background-position: center;
  background-size: 100% 100%;
  padding-top: 28px;

  iframe {
    width: 100%;
    aspect-ratio: 16/9;
  }

  &.space-top {
    margin-top: 80px;
  }
}

.pool-preview-box {
  position: relative;
  background-image: url("../images/submit-form-wrapper.png");
  background-position: center;
  background-size: 100% 100%;
  padding: 2rem;
}

.pool-preview-box h4 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  color: #fff;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #4f5258;
}

.pool-step-view {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.step-view-col {
  width: 33.33333%;
}

.step-view-heading {
  display: block;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--Black-Black-40, rgba(0, 0, 0, 0.4));
}

.step-view-info {
  display: block;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #000;
  margin-top: 8px;
}

.pool-step-devider {
  margin: 1.5rem auto;
  max-width: 80%;
  border-top: transparent;
}

.pool-date-view {
  background: url("../images/submit-form-wrapper.png") center/100% 100%
    no-repeat;
  // background: linear-gradient(
  //   223.88deg,
  //   rgba(255, 255, 255, 0) 1.79%,
  //   rgba(255, 255, 255, 0.15) 98.47%
  // );
  // backdrop-filter: blur(40px);
  // border: 1px solid rgba(255, 255, 255, 0.1);
  // border-radius: 24px;
  padding: 2rem 2rem 1rem;
  margin-top: 2rem;
}

.pool-date-col {
  display: grid;
  grid-template-columns: 6rem 1fr;
  grid-column-gap: 10px;
  margin-bottom: 1rem;
}

.pool-date-info {
  font-size: 13px;
  line-height: 26px;
  font-weight: 600;
  color: gray;
  letter-spacing: 0.45px;
}

.pool-date-label {
  color: gray;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
}

.pool-select-nameview {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
}

.pool-nameview-display {
  display: block;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  color: #000;
  letter-spacing: 0.45px;
  margin-bottom: 2px;
}

.pool-name-colview {
  margin-left: 20px;
}

.pool-nameview-selected {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #fff;
  border-radius: 4px;
  background: var(--primary-orange-hover, #d0872c);
  box-shadow: 0px 2px 0px 1px #000, 0px -2px 0px 0px #915101 inset;
  padding: 3px 10px;
}

/* Admin */
.admin-wrap-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.admin-wrap-heading h4 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  color: #fff;
  margin: 0;
}

.admin-table-list {
  display: grid;
  grid-template-columns: 7rem 1.25fr 3fr 15rem;
  grid-column-gap: 10px;
}

.admin-table-header {
  padding-bottom: 1rem;
  border-bottom: 1px solid #4f5258;
  margin-bottom: 1rem;
}

.admin-heading-col {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.45px;
}

.admin-list-col {
  font-size: 14px;
  line-height: 26px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.45px;
}

.admin-table-item {
  padding: 1.5rem 0;
}

.admin-table-item .access-col {
  margin-top: 0;
}

/* Whitelist wrap */

.whitelist-wrap {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 2rem;
}

.whitelist-wrap h4 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  color: #fff;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #4f5258;
}

.whitelist-top-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 15px;
}

.whitelist-status-timelock {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 50px;
  margin-bottom: 2.5rem;
}

.whitelist-status-timelock .type-label {
  color: #fff;
}

.whitelist-set-time h3 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 300;
  color: #fff;
  margin-bottom: 2rem;
}

.whitelist-action-row {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.btn-action-link1 {
  display: inline-block;
  // border-radius: 4px;
  // background: var(--Primary-Orange, #FFA12B);
  background: url("../../src/images/box/orange_buttons/orange_button_1.png")
    center/100% 100% no-repeat;
  // box-shadow: 0px 2px 0px 1px #000, 0px 1.2px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px -9px 0px 0px #915101 inset;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  color: var(--White-White-100, #fff);
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);

  /* Text/md */
  -webkit-text-stroke: 0.5 #fff;
  font-size: 16px;
  line-height: 120%; /* 19.2px */
  padding: 22px 22px;
  letter-spacing: 0.4px;
  min-width: 170px;
  text-decoration: none;

  @media screen and (max-width: 1200px) {
    min-width: unset;
  }
}

.btn-action-link1:hover {
  color: #ffffff;
}

.btn-action-link1.no-border::before {
  display: none;
}

.pool-selection-img {
  height: -20px;
}

.pool-select-img {
  /* background-size: 50px 50px;
    margin-top: -20px;
    margin-left: 160px; */
  background-size: 75px 75px;
  width: 75px;
  height: 75px;
  margin: -35px auto 0;
}

@media (max-width: 1400px) {
  .header-wrap {
    .header-nav {
      .header-navbar {
        .headermenu {
          ul {
            li {
              a {
                padding: 30px 15px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .header-wrap {
    .header-nav {
      .header-navbar {
        .headermenu {
          ul {
            li {
              a {
                padding: 30px 10px !important;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
