.quill {
	.ql-toolbar {
		.ql-stroke {
			fill: none;
			stroke: #fff;
		}

		.ql-fill {
			fill: #fff;
			stroke: none;
		}

		.ql-picker {
			color: #fff;
		}

		.ql-picker-item {
			color: #000;
		}

		.ql-stroke.ql-fill {
			fill: #fff;
		}
	}

	.ql-container {
		height: 250px;
	}
}