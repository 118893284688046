@import '../../Style/_mixin.scss';

.dashboard {
	@media screen and (min-width: 992px) {
		padding-top: 250px;
	}

	@media screen and (max-width: 991px) {
		padding-top: 144px;
	}

	.dashboard-wrapper {}

	.dashboard-roya-update+.dashboard-roya-update {
		margin-top: 32px;
	}

	.dashboard-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			align-items: stretch;
			gap: 32px;
		}

		.dashboard-header-item {
			width: 45%;

			@media screen and (max-width: 768px) {
				width: 100%;
			}
		}
	}

	.dashboard-stake-box {
		border-radius: 24px;
		padding: 0px 24px 0;

		h4 {
			font-size: 20px;
			line-height: 1.4;
			font-weight: 300;
			color: #FFFFFF;
			padding: 15px 0;
			letter-spacing: 0.5px;
			text-align: center;
		}

		.staking-nav {
			margin-bottom: 2rem;
		}

		.submit-form-group {
			padding-top: 1rem;
		}

		.btn-action-link {
			width: 100%;
		}

		@media (max-width: 767px) {
			margin-top: 35px;
			padding: 24px;
			background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
			backdrop-filter: blur(20px);
			border-radius: 24px;
		}
	}

	.dashboard-total-text {
		@include cairo(400, 14px, 26px);
		text-transform: uppercase;
		color: #FFFFFF;
		opacity: 0.5;
	}

	.dashboard-roya-count {
		margin-top: 5px;
		@include cairo(700, 48px, 56px);
		letter-spacing: 0.01em;
		color: #FFFFFF;

		display: block;
	}

	.dashboard-roya-action {
		margin-top: 24px;

		display: flex;
		gap: 8px;

		@media screen and (max-width: 768px) {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		a,
		button {
			background: url('../../images/box/orange_buttons/orange_button_4.png') center/100% 100% no-repeat;

			// position: relative;
			width: 136px;
			height: 64px;
			// background: rgba(255, 255, 255, 0.02);
			// box-shadow: 0px 24px 48px rgb(255 255 255 / 10%), inset 0px 4px 11px rgb(0 0 0 / 16%), inset 0px 0px 2px rgb(255 255 255 / 40%);
			// -webkit-backdrop-filter: blur(16px);
			// backdrop-filter: blur(16px);
			border-radius: 4px;
			@include workSans(600, 16px, 130%);
			text-transform: uppercase;
			color: #FFFFFF;
			overflow: hidden;

			display: flex;
			justify-content: center;
			align-items: center;

			&::before {
				position: absolute;
				content: "";
				height: 3px;
				left: 0px;
				right: 0px;
				bottom: 0px;
				background: linear-gradient(269.99deg, #eeeeee -69.29%, #fffee2 -62.4%, rgba(255, 255, 255, 0.950883) -57.4%, #df68fd -51.6%, rgba(255, 255, 255, 0.815523) -44.74%, #b7eee5 -35.82%, #20282e -12.7%, #29353c -5.34%, rgba(255, 255, 255, 0.596267) -1.97%, #c9fbfe 5.76%, #79a2f2 11.83%, rgba(255, 255, 255, 0.741036) 17.11%, #79a2f2 23.13%, #5d23fd 33.9%, #ffffff 45.48%, #51555e 79.22%, #699cff 89.6%, #ffffff 92.26%, #79a2f2 95.03%, #ffffff 97.45%);
			}
		}
	}

	.dashboard-tier-stake-box {
		border-radius: 24px;
		padding: 24px 24px;

		background-image: url(../../images/box/square/square2.png);
		background-position: center;
		background-size: 100% 100%;

		

		// background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
		// backdrop-filter: blur(40px);
		// border-radius: 24px;

		.staking-nav {
			margin-bottom: 2rem;

			display: flex;
			justify-content: center;
			align-items: center;

			a,
			button {
				width: 174px;
				height: 50px;
				@include cairo(600, 16px, 32px);
				font-size: 20px;
				letter-spacing: 0.1em;
				color: #5B5875;

				display: inline-flex;
				justify-content: center;
				align-items: center;
			}

			a.active,
			button.active {
				background: url('../../images/box/green_button/green button_4.png') center/100% 100% no-repeat;

				// position: relative;
				// background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.1) 98.47%);
				// backdrop-filter: blur(8px);
				border-radius: 4px;
				color: #FFFFFF;

				&::after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translate(-50%);
					width: 70px;
					height: 3px;
					background: conic-gradient(from 180deg at 50% 50%, #EEEEEE 0deg, #FFFEE2 14.87deg, rgba(255, 255, 255, 0.950883) 25.67deg, #DF68FD 38.19deg, rgba(255, 255, 255, 0.815523) 53deg, #B7EEE5 72.26deg, #20282E 122.18deg, #29353C 138.07deg, rgba(255, 255, 255, 0.596267) 145.34deg, #C9FBFE 162.04deg, #79A2F2 175.13deg, rgba(255, 255, 255, 0.741036) 186.54deg, #79A2F2 199.54deg, #5D23FD 222.78deg, #FFFFFF 247.79deg, #51555E 320.65deg, #699CFF 343.05deg, #FFFFFF 348.79deg, #79A2F2 354.77deg, #FFFFFF 360deg);
					box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
				}
			}
		}

		h4 {
			margin: 16px 0;
			@include cairo(400, 14px, 32px);
			letter-spacing: 0.5px;
			text-align: center;
			font-size: 22px;
			color: #9AA1AC !important;
		}

		h4+h4 {
			margin-top: 0px;
		}

		.submit-form-group {
			padding-top: 1rem;
		}

		.submit-label {
			color: #9AA1AC;
		}

		.btn-action-link {
			width: 100%;
		}
	}

	.dashboard-roya-stake-box {
		border-radius: 24px;
		padding: 24px 24px 0;
		background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
		backdrop-filter: blur(40px);
		border-radius: 24px;

		.staking-nav {
			margin-bottom: 2rem;
		}

		h4 {
			padding: 15px 0;
			@include cairo(300, 22px, 1.4);
			letter-spacing: 0.5px;
			text-align: center;
			color: #FFFFFF;
		}

		.submit-form-group {
			padding-top: 1rem;
		}

		.btn-action-link {
			width: 100%;
		}
	}

	.dashboard-roya-row {
		margin-top: 5rem;

		display: flex;
		gap: 3%;

		@media (max-width: 768px) {
			margin-top: 1rem;

			flex-direction: column;
			gap: 24px;
		}

		.roya-article-box {
			background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.1) 98.47%);
			border: 1px solid rgba(255, 255, 255, 0.1);
			backdrop-filter: blur(40px);
			border-radius: 24px;
			overflow: hidden;
			flex: 1 1;
			cursor: pointer;

			.roya-article-img {
				width: 100%;
				aspect-ratio: 4/3;
				border-radius: 24px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.roya-article-info {
				margin-bottom: 25px;
				padding: 16px 20px;
				overflow: hidden;

				display: flex;
				flex-direction: column;

				.title {
					@include cairo(700, 16px, 130%);
					letter-spacing: 0.05em;
					color: #FFFFFF;
				}

				.description {
					margin-top: 16px;
					@include textShrink(4);
					opacity: 0.5;

					* {
						@include cairo(600, 14px, 16px);
						color: #FFFFFF;
					}

					figure,
					img {
						display: none !important;
					}
				}
			}
		}
	}

	.dashboard-amount-input {
		position: relative;

		select {
			width: 100%;
			max-width: 100%;
			height: 55px;
			padding: 0px 20px 0px;
			border-radius: 20px;
			background: #15131e;
			@include cairo(400, 25px);
			color: #FFFFFF;
		}

		option {
			width: 595px;
			height: 65px !important;
			padding: 0px 30px 0px;
			background: #292736;
			border-radius: 20px;
			@include cairo(400, 22px, 32px);
			color: #FFFFFF;

			&:hover {
				box-shadow: 0 0 10px 100px #151321 !important;
			}
		}
	}

	.max-amount {
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
		font-size: 15px;
		line-height: 1.2;
		font-weight: 300;
		letter-spacing: 0.4px;
		color: #FFFFFF;
		text-decoration: underline;
		cursor: pointer;
	}

	.dashboard-stake-action {
		padding: 2rem 0;
	}

	.applications-box1 {
		margin: 72px 0;
		padding: 16px 32px;
		background: linear-gradient(136.24deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
			linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
		border-radius: 16px;

		.applications-list-item {
			display: flex;
			align-items: center;

			&>div {
				flex: 1 1;
				height: 64px;

				display: inline-flex;
				align-items: center;
			}

			&>div:nth-child(2) {
				justify-content: center;
			}

			&>div:nth-child(3) {
				justify-content: flex-end;
			}

			&>div:nth-child(4) {
				justify-content: flex-end;
			}
		}

		.applications-list-item.applications-header-item {
			@include cairo(400, 14px, 26px);
			color: #FFFFFF;
			opacity: 0.5;
		}

		.applications-list-item:not(:last-child) {
			border-bottom: 1px solid #4F5258;
		}
	}

	.allocation-history {
		margin-bottom: 5rem;

		.allocation-history-table {
			margin: 72px 0;
			padding: 16px 32px;
			background: linear-gradient(136.24deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
				linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
			border-radius: 16px;

			.applications-list-item {
				display: flex;
				align-items: center;

				&>div {
					flex: 1 1;
					height: 64px;

					display: inline-flex;
					align-items: center;
				}

				&>div:nth-child(2) {
					justify-content: center;
				}

				&>div:nth-child(3) {
					justify-content: flex-end;
				}
			}

			.applications-list-item.applications-header-item {
				@include cairo(400, 14px, 26px);
				color: #FFFFFF;
				opacity: 0.5;
			}

			.applications-list-item:not(:last-child) {
				border-bottom: 1px solid #4F5258;
			}
		}
	}
}