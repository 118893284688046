@import "../../../Style/_mixin.scss";

.staking-colitem {
	padding: 0 4px;

	@media screen and (max-width: 991px) {
		padding-bottom: 4rem;
	}

	.staking-colbox {
		@media screen and (max-width: 524px) {
			height: 372px;
		}

		@media screen and (max-width: 768px) {
			height: unset;
		}

		@media screen and (max-width: 991px) {
			height: 250px;
		}

		@media screen and (min-width: 1200px) {
			height: 360px;
		}

		.staking-imgbox {
			position: relative;
			width: 100%;
			height: 80%;
			overflow: hidden;

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				height: 100%;
				object-fit: cover;

				&.origin {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}

		.staking-content-info {
			padding: 25px;
			text-align: center;

			p {
				font-size: 16px;
				line-height: 24px;
				font-weight: 300;
				letter-spacing: 0.4px;
				color: #FFFFFF;
			}

			ul {
				display: inline-block;
				padding: 0;
				margin: 0;

				li {
					position: relative;
					font-size: 18px;
					line-height: 33px;
					font-weight: 500;
					text-align: center;
					letter-spacing: 0.4px;
					color: #FFFFFF;
					padding-left: 15px;

					&:before {
						position: absolute;
						content: "";
						left: 0;
						top: 15px;
						width: 4px;
						height: 4px;
						background: #fff;
					}
				}
			}
		}

		.staking-name {
			display: block;
			@include cairo(600, 16px, 24px);
			letter-spacing: 0.1px;
			color: #000 !important;
			margin-bottom: 10px;

			i {
				margin-left: 8px;
			}
		}

		.staking-token {
			display: block;
			font-size: 16px;
			line-height: 1.2;
			letter-spacing: 0.05em;
			color: #9AA1AC;
			margin-bottom: 10px;
		}
	}
}