@mixin workSans($weight, $size, $lineHeight:normal) {
	font-style: normal;
	font-weight: $weight;
	font-size: $size;
	line-height: $lineHeight;
}

@mixin cairo($weight, $size, $lineHeight:normal) {
	font-style: normal;
	font-weight: $weight;
	font-size: $size;
	line-height: $lineHeight;
}

@mixin mulish($weight, $size, $lineHeight:normal) {
	font-style: normal;
	font-weight: $weight;
	font-size: $size;
	line-height: $lineHeight;
}

@mixin verticalScroll($width:4px, $color:#E71383) {
	overflow-x: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: $width;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
		border-radius: $width;
		border: 1px solid $color;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: $width;
		border: 1px solid $color;
		background: $color;
	}
}

@mixin textShrink($maxLine:1) {
	text-overflow: ellipsis;
	overflow: hidden;

	@if $maxLine==1 {
		word-break: break-all;
	}

	display: -webkit-box;
	-webkit-line-clamp: $maxLine;
	-webkit-box-orient: vertical;
}