.participating-title {
	width:50%;
  @media screen and (max-width: 991px) {
    margin: auto;
		margin-top: 20px;
  }
}

.participating-wrap {
//   background-color: transparent !important;

  .participating-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    .project-nosubmit-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: auto;
		h3 {
		  color: white;
		  opacity: 0.5;
		  margin-top: 50px;
		  font-size: 40px;
		  font-weight: 700;
		  line-height: 54px;
		  letter-spacing: 0.1em;
		  text-align: center;
		  
  
		}
    }
    @media screen and (max-width: 991px) {
      justify-content: center;
    }
  }
}
