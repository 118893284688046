@import "./_mixin.scss";

$color_1: #fff;
$color_2: #ffffff;
$color_3: white;
$color_4: #5b5875;
$color_5: #74e1ed;
$color_6: rgba(255, 255, 255, 0.6);
$color_7: #a0a5ad;
$color_8: #9aa1ac;
$color_9: rgba(255, 255, 255, 0.5);
$color_10: #3ebb52;
$color_11: rgba(255, 255, 255, 0.65);
$color_12: rgba(255, 255, 255, 0.3);
$color_13: rgba(255, 255, 255, 0.2);
$color_14: rgba(255, 255, 255, 1);
$color_15: rgba(255, 255, 255, 0.25);
$color_16: black;
$color_17: red;
$color_18: hsla(0, 0%, 100%, 0.5);
$color_19: gray;
$font-family_1: "Work Sans", sans-serif;
$font-family_2: Cairo;
$font-family_3: "Cairo";
$font-family_4: "cairo";
$background-color_1: rgba(0, 0, 0, 0.5);
$background-color_2: #009bff;
$background-color_3: transparent;
$background-color_4: white;
$background-color_5: gray;
$background-color_6: #2196f3;
$background-color_7: #f9f9f9;
$background-color_8: #f1f1f1;
$background-color_9: #671ae4;
$border-color_1: #23272e;
$border-color_2: #4f5258;

/* Home Wrapper*/
/* .headermenu ul li a.active::before{
    position: absolute;
    content: '';
    left: 5px;
    top: -22px;
    bottom: -22px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    transform: rotate(12deg);
    z-index: -1;
} */
/* .headermenu ul li a.active::after{
    position: absolute;
    content: '';
    left: 2px;
    top: -22px;
    bottom: -22px;
    width: 2px;
    background-image: url(../images/navafterbg.png);
    background-size: cover;
    transform: rotate(12deg);
    z-index: -1;
} */
/* .latestbox:hover {
    padding: 22px;
} */
/* Tier Level*/
/* Comparison*/
/* RoadMap*/
/* Partners*/
/* Compability*/
/* Team */
/* footer */
/* inner page */
/* Staking Lots */
/* Total Value */
/* popup */
/* Social Media Home Page */
/* .dashboard-roya-count {
        font-size: 40px!important;
    }
    .roya-article-box {
        margin-bottom: 2rem;
    }
    .applications-header-item {
        display: flex!important;
        margin-top: -1rem!important;
        gap: 2rem!important;
    }
    .applications-lis-item1 {
        display: flex!important;
        gap: 2rem!important;
    }
    .applications-box1 {
        width: 105%;
    }
    .applications-lis-item {
        display: flex!important;
    } */
/* apply Wrap */
/* banner */
/* The container */
/* Hide the browser's default radio button */
/* .newcontainer2 input {
position: absolute;
opacity: 0;
cursor: pointer;
} */
/* Create a custom radio button */
/* On mouse-over, add a grey background color */
/* When the radio button is checked, add a blue background */
/* Create the indicator (the dot/circle - hidden when not checked) */
/* Show the indicator (dot/circle) when checked */
/* Style the indicator (dot/circle) */
/* When the radio button is checked, add a blue background / */
/* New-Game */
/* dashboard redesign */
/* .dashboarddropdown {
    width: 495px;
    border-radius: 20px;
    height: 55px;
    background: #15131e;
    color: white;
    padding: 0px 20px 0px;
    font-size: 25px;
    font-weight: 400;
    } */
/* .dashboarddropdown option{
    width: 595px;
    border-radius: 20px;
    height: 65px !important;
    background: #292736;
    color: white;
    padding: 0px 30px 0px;
    font-size: 22px;

    font-weight: 400;
    } */
/* .dashboarddropdown option:hover{
    background: #20282e;
} */
/* staking-lots */
/* token-sale */
/* game-details-popup */
/* game-submit */
/* Token-Sale */
/* Buy-tier-Box */
/* Token-Sale */
.heading-row {
  text-align: center;
}

.heading-rowforpartners {
  margin-top: -150px;
  padding: 217px 0px 0px 0px;
  text-align: center;
}

.title-wrap {
  background-image: url("../images/title.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 270px;

  @media screen and (max-width: 767px) {
    height: 180px;
  }

  h3 {
    color: #000 !important;
    font-size: 24px;
  }
}

.headingbox1 {
  display: inline-block;
  border-radius: 4px;
  min-width: 400px;
  padding: 30px;
  margin: 0 auto;
  position: relative;
  margin-top: 95px;

  @media screen and (max-width: 900px) {
    margin-top: 150px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 130px;
    min-width: 350px;
  }

  h1 {
    font-size: 35px;
    line-height: 1;
    font-weight: 600;
    color: #000000 !important;
    text-align: center;
    letter-spacing: 0.1em;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
    margin: 0;
  }

  h2 {
    font-size: 36px;
    line-height: 1;
    font-weight: 600;
    color: #ffc71a !important;
    text-align: center;
    letter-spacing: 0.1em;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
    margin: 0;
    margin-bottom: 10px;

    @media screen and (max-width: 900px) {
      font-size: 24px;
    }

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
}

.headingbox {
  display: inline-block;
  border-radius: 4px;
  min-width: 340px;
  // padding: 30px;
  margin: 0 auto;
  position: relative;
  margin-top: 95px;

  @media screen and (max-width: 900px) {
    margin-top: 100px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 70px;
  }

  h1 {
    font-size: 35px;
    line-height: 1;
    font-weight: 600;
    color: #000000 !important;
    text-align: center;
    letter-spacing: 0.1em;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
    margin: 0;
  }

  h2 {
    font-size: 36px;
    line-height: 1;
    font-weight: 600;
    color: #ffc71a !important;
    text-align: center;
    letter-spacing: 0.1em;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
    margin: 0;
    margin-bottom: 10px;

    @media screen and (max-width: 900px) {
      font-size: 24px;
    }

    @media screen and (max-width: 767px) {
      font-size: 15px;
    }
  }
}

.header-bottom-row {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.header-bottom-right1 {
  height: 45px;
  position: relative;
  background-size: cover;

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    height: 45px;
    align-items: center;
    justify-content: center;

    li {
      display: flex;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.05em;
      color: $color_2;
      position: relative;
      align-items: center;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.2);
      height: 42px;

      &:first-child {
        margin-right: 8px;

        @media screen and (max-width: 1440px) {
          display: none;
        }
      }

      img {
        margin-left: 5px;
        width: 15px;
        height: 15px;
      }

      .notranslate {
        margin-left: 10px;
      }

      .wee-symbol {
        width: 20px;
        height: 20px;
        margin: 0;
      }
    }
  }
}

.home-wrapperbg {
  height: auto;
  padding-top: 30px;
}

.home-banner-content {
  text-align: center;
  padding-top: 5rem;
  margin-top: 55px;
  height: 500px;

  @media only screen and (max-width: 1280px) {
    margin-top: 0px;
  }

  @media only screen and (max-width: 900px) {
    padding-top: 2rem;
    margin-top: 200px;
  }

  @media only screen and (max-width: 767px) {
    padding-top: 0;
    margin-top: 200px;
  }

  h1 {
    color: white !important;
  }

  h3 {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: 12px;
    text-transform: uppercase;
    color: $color_2;
    margin-bottom: 25px;
  }

  img {
    width: 85%;

    @media only screen and (min-device-width: 787px) and (max-device-width: 1200px) {
      width: 95%;
    }
  }
}

.latest-col {
  width: 33.333%;
  padding: 0 40px;
}

.latestbox {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  padding: 15px;
  transition: all 0.5s ease-in-out;
}

.cardimgbox {
  &:hover {
    padding: 10px;
  }

  transition: all 0.5s ease-in-out;
  height: 190px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: inset 0px 2.9422px 8.09105px rgba(0, 0, 0, 0.16),
      inset 0px 0px 1.4711px rgba(255, 255, 255, 0.4);
    border-radius: 18px;
  }
}

.latest-innerbox {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 42px 42px;
}

.card-gamebox {
  box-shadow: inset 0px 2.9422px 8.09105px rgba(0, 0, 0, 0.16),
    inset 0px 0px 1.4711px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(11.7688px);
  border-radius: 18px;
}

.gamecardinfo {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.game-count {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 1.2;
  color: $color_1;
  padding-top: 5px;

  img {
    margin-right: 5px;
  }
}

.gamename {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: $color_1;
}

.namesmallinfo {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: $color_4;
}

.game-register-action {
  margin: 0 -4px 0;
  text-align: center;

  a {
    display: block;
    background: rgba(255, 255, 255, 0.02);
    box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1),
      inset 0px 4px 11px rgba(0, 0, 0, 0.16),
      inset 0px 0px 2px rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(16px);
    border-radius: 4px;
    text-align: center;
    position: relative;
    overflow: hidden;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.2;
    color: $color_2;
    padding: 22px 22px;

    &::before {
      position: absolute;
      content: "";
      height: 3px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: linear-gradient(
        269.99deg,
        #eeeeee -69.29%,
        #fffee2 -62.4%,
        rgba(255, 255, 255, 0.950883) -57.4%,
        #df68fd -51.6%,
        rgba(255, 255, 255, 0.815523) -44.74%,
        #b7eee5 -35.82%,
        #20282e -12.7%,
        #29353c -5.34%,
        rgba(255, 255, 255, 0.596267) -1.97%,
        #c9fbfe 5.76%,
        #79a2f2 11.83%,
        rgba(255, 255, 255, 0.741036) 17.11%,
        #79a2f2 23.13%,
        #5d23fd 33.9%,
        #ffffff 45.48%,
        #51555e 79.22%,
        #699cff 89.6%,
        #ffffff 92.26%,
        #79a2f2 95.03%,
        #ffffff 97.45%
      );
    }
  }
}

.tier-row-center {
  align-items: center;
}

.tier-levelsimg {
  img {
    max-width: 100%;
    mix-blend-mode: screen;
  }
}

.tier-levels-info {
  text-align: center;

  @media screen and (max-width: 767px) {
    padding-top: 0 !important;
  }

  h3 {
    margin-bottom: 2rem;

    &::before {
      position: absolute;
      content: "";
      height: 3px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: linear-gradient(
        269.99deg,
        #eeeeee -69.29%,
        #fffee2 -62.4%,
        rgba(255, 255, 255, 0.950883) -57.4%,
        #df68fd -51.6%,
        rgba(255, 255, 255, 0.815523) -44.74%,
        #b7eee5 -35.82%,
        #20282e -12.7%,
        #29353c -5.34%,
        rgba(255, 255, 255, 0.596267) -1.97%,
        #c9fbfe 5.76%,
        #79a2f2 11.83%,
        rgba(255, 255, 255, 0.741036) 17.11%,
        #79a2f2 23.13%,
        #5d23fd 33.9%,
        #ffffff 45.48%,
        #51555e 79.22%,
        #699cff 89.6%,
        #ffffff 92.26%,
        #79a2f2 95.03%,
        #ffffff 97.45%
      );
    }
  }
}

.comparisonwrap {
  padding: 5rem 0 15rem;
  position: relative;

  &::before {
    position: absolute;
    z-index: -1;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: url("../images/blurbg.png") center top no-repeat;
    background-size: contain;
  }
}

.comparisonbox {
  max-width: 999px;
  margin: 40px auto 0;
  background: linear-gradient(110.76deg, #2a2837 7.68%, #2a2836 98.53%);
  border-radius: 24px;
  padding: 25px 25px 10px;
}

.comparison-list-item {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1.25fr;
  text-align: center;
  margin-bottom: 20px;
}

.comparison-header {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: $color_1;
}

.comparison-list {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: $color_1;

  .listbg {
    background: rgba(0, 0, 0, 0.12);
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(2) {
      border-radius: 20px 0 0 20px;
    }

    &:last-child {
      border-radius: 0 20px 20px 0;
    }
  }
}

.roadmapwrap {
  padding: 15rem 0 5rem;

  @media screen and (max-width: 1280px) {
    padding: 10rem 0 5rem;
  }
}

.roadmapline {
  margin: 5rem 0 5rem;

  @media screen and (max-width: 1100px) {
    display: none;
  }

  img {
    width: 96%;
    margin: 0 18px 0;
  }
}

.roadmapcontainer {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
}

.roadmap-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  @media screen and (max-width: 1100px) {
    justify-content: center;
  }
}

.roadmap-col {
  width: 16.5%;
  padding: 0 15px;

  @media screen and (max-width: 1100px) {
    width: 30%;
    margin-top: 40px;
  }
}

.roadmapbox {
  max-width: 212px;
  margin: 0 auto;

  background: url("../images/box/rectangle/rectangle2.png") center/100% 100%
    no-repeat;

  // background: linear-gradient(
  //   223.88deg,
  //   rgba(255, 255, 255, 0) 1.79%,
  //   rgba(255, 255, 255, 0.15) 98.47%
  // );
  // border: 1px solid rgba(255, 255, 255, 0.1);
  // backdrop-filter: blur(40px);
  // border-radius: 24px;
  min-height: 184px;
}

.roadmap-heading {
  // background: linear-gradient(
  //   223.88deg,
  //   rgba(255, 255, 255, 0) 1.79%,
  //   rgba(255, 255, 255, 0.15) 98.47%
  // );
  // backdrop-filter: blur(40px);
  border-radius: 24px 24px 0px 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.01em;
  color: rgb(228, 213, 11);
  padding: 14px 0;
}

.roadmap-list-item {
  padding: 15px 15px;

  ul {
    padding: 0;
    margin: 0;

    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.01em;
      color: black;
      margin-bottom: 10px;
    }
  }
}

.partners-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;
  padding: 5rem 0;
}

.partners-col {
  width: 25%;
  padding: 0 15px;
}

.partners-box {
  background: url("../images/box/rectangle/rectangle9.png") center/100% 100%
    no-repeat;

  // background: linear-gradient(
  //   223.88deg,
  //   rgba(255, 255, 255, 0) 1.79%,
  //   rgba(255, 255, 255, 0.15) 98.47%
  // );
  // backdrop-filter: blur(40px);
  // border: 1px solid rgba(255, 255, 255, 0.15);
  // border-radius: 24px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  padding: 15px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.compabilitywrap {
  width: 100%;
  max-width: 1060px;
  margin: 0 auto;
  padding: 0 15px;
}

.compability-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -25px;
  margin-right: -25px;
  padding-top: 7rem;
}

.compability-col {
  width: 33.333%;
  padding: 0 25px;
  transition: all 0.5s ease-in-out;

  &:hover {
    transform: translateY(-40px);
  }
}

.compability-box {
  background: url("../images/box/rectangle/rectangle9.png") center/100% 100%
    no-repeat;

  // background: linear-gradient(
  //   223.88deg,
  //   rgba(255, 255, 255, 0) 1.79%,
  //   rgba(255, 255, 255, 0.15) 98.47%
  // );
  // backdrop-filter: blur(40px);
  // border: 1px solid rgba(255, 255, 255, 0.15);
  // border-radius: 24px;
  padding: 22px 16px 0;
  text-align: center;
  position: relative;

  &::before {
    // position: absolute;
    content: "";
    left: 0;
    bottom: -8px;
    background: url("../images/bottom-line.png") center 0 no-repeat;
    width: 100%;
    height: 20px;
  }

  h4 {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 500;
    color: $color_1;
    letter-spacing: 0.1em;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
    text-align: center;
    margin: 0;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 900px) {
      font-size: 20px;
    }
  }
}

.compability-iconbox {
  background-color: $color_7;
  width: 155px;
  height: 155px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: linear-gradient(
  //   223.88deg,
  //   rgba(255, 255, 255, 0) 1.79%,
  //   rgba(255, 255, 255, 0.15) 98.47%
  // );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 100%;
}

.teamwrap {
  padding-top: 10rem;
}

.team-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.team-col {
  width: 25%;
}

.team-box-wrap {
  padding: 16px;
  transition: all 0.2s ease-in-out;
  border-radius: 24px;
  border: 1px solid transparent;

  &:hover {
    background: linear-gradient(
      223.88deg,
      rgba(255, 255, 255, 0) 1.79%,
      rgba(255, 255, 255, 0.15) 98.47%
    );
    backdrop-filter: blur(40px);
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
}

.team-member-img {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 24px;
  padding: 16px;

  img {
    width: 100%;
    border-radius: 16px;
  }
}

.team-member-info {
  padding: 16px 0;

  h4 {
    font-size: 24px;
    line-height: 1.4;
    font-weight: 600;
    color: $color_2;
    letter-spacing: 0.5px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $color_6;
    margin-bottom: 1.5rem;
  }
}

.member-info {
  display: block;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: $color_5;
}

.details-banner-wrap {
  img {
    width: 100%;
  }
}

.details-container {
  margin-top: -12rem;
}

.details-container1 {
  margin-top: -120rem;
}

.details-sidebar-box {
  background-image: url("../images/game-detail-title-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 1rem;
}

.border-box-wrap.details-img-border {
  border-radius: 32px;
}

.details-sidebar-img {
  border-radius: 32px;
  height: 280px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 32px;
    object-fit: cover;
  }
}

.sidebar-details-01 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.23);
  padding: 2rem 0;
  text-align: center;

  h4 {
    font-size: 2rem;
    line-height: 1.4;
    font-weight: 700;
    color: $color_2;
  }
}

.details-info-text01 {
  display: block;
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 500;
  color: $color_8;
  margin-bottom: 2rem;
}

.details-info-text02 {
  display: block;
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 700;
  color: $color_8;
  margin-bottom: 5px;
}

.details-info-text03 {
  display: block;
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 500;
  color: $color_8;
}

.sidebar-details-02 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.23);
  padding: 0;
  text-align: center;
}

.details-info-finis {
  display: block;
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 500;
  color: $color_8;
  margin-bottom: 1rem;
}

.details-info-percent {
  background: rgba(255, 255, 255, 0.04);
  height: 10px;
  border-radius: 100px;
}

.percent-count {
  background: #ffa12b;
  box-shadow: 0px 3px 2px rgba(9, 15, 27, 0.5);
  background-blend-mode: overlay, screen, difference, normal;
  mix-blend-mode: normal;
  border-radius: 200px;
  height: 10px;
}

.details-info-raised {
  display: block;
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 500;
  color: $color_8;
  margin: 1rem 0;
}

.details-info-totalamount {
  display: block;
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 700;
  color: $color_8;
}

.sidebar-details-03 {
  padding-top: 1rem;
  text-align: center;

  span {
    display: block;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 400;
    color: $color_8;
    margin-bottom: 5px;
  }
}

.sidebar-dtails-bottom {
  padding: 2rem;
  display: grid;
  align-items: center;
  grid-template-columns: 60px 1fr;
  grid-column-gap: 15px;
}

.sidebar-original-img {
  background: #0a0f12;
  border: 1px solid #fff;
  border-radius: 100%;
  width: 60px;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
}

.sidebar-original-info {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: $color_8;

  strong {
    display: block;
    font-weight: 700;
  }

  h3 {
    display: block;
    font-weight: 700;
    align-items: center;
    font-size: 20px;
    font-size: 27px;
    margin-top: 7px;
    margin-left: 7px;
  }
}

.volume-icon-box {
  img {
    width: 80%;
    margin: 6px 0 0;
  }

  background: url(../images/round-bg.png) 0 0 no-repeat;
  width: 60px;
  height: 65px;
  align-items: center;
  justify-content: center;
  margin: -38px auto 3.5rem;
  padding: 4px 6px 0;
}

.volume-icon-box1 {
  background: url(../images/round-bg.png) 0 0 no-repeat;
  width: 60px;
  height: 65px;
  align-items: center;
  justify-content: center;
  margin: -38px auto 3.5rem;

  img {
    margin: -3px 0 0;
    width: 70%;
  }
}

.col-md-3 {
  width: 23%;
}

.project-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 10px;

  @media (max-width: 787px) {
    justify-content: center;
  }

  .project-card {
    width: 300px;

    @media (max-width: 787px) {
      width: 230px;
    }

    @media (max-width: 600px) {
      width: 300px;
    }
  }
}

.about-project-box {
  background-image: url("../images/submit-form-wrapper.png");
  background-position: center;
  background-size: 100% 100%;
  padding: 4rem;
  width: 100%;

  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 5rem 2rem;
  }
}

.about-top-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.23);
  padding-bottom: 3rem;

  h3 {
    font-size: 2.25rem;
    line-height: 1.4;
    font-weight: 600;
    color: $color_2;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.3rem;
    line-height: 1.4;
    color: $color_8;
    margin-bottom: 2rem;
  }
}

.about-winnning-row {
  background: #fff;
  border-radius: 4px;
  border: 2px solid var(--Black, #111);
  box-shadow: 4px 4px 0px 0px #111;
  border-radius: 12px;
  padding: 2rem 2rem 0;

  @media screen and (max-width: 767px) {
    padding: 1rem
  }

  .table-responsive {
    height: 400px;
  }
  h3 {
    font-size: 2rem;
    line-height: 1.4;
    font-weight: 600;
    color: #000 !important;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

  table {
    thead {
      position: sticky;
      top: 0;
      background-color: #000;
    }
    th {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.45px;
      color: #ffa12b;
      background-color: transparent;
      padding: 15px 0;
      border-color: $border-color_2 !important;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    td {
      background: transparent;
      font-size: 13px;
      font-weight: 400;
      color: $color_1;
      padding: 15px 0;
      border-color: $border-color_1;
    }

    &.whitelist-table {
      max-height: 400px;
      overflow: auto;
    }
  }
}

.provider-pool-row {
  margin-bottom: 2rem;
  padding-top: 3rem;

  h4 {
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
    color: $color_9;
    margin-bottom: 1rem;
  }
}

.pool-row {
  position: relative;
}

.pool-devider-box {
  position: absolute;
  left: 50%;
  top: 50%;
  width: auto !important;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.direction-box {
  button {
    background: conic-gradient(
      from 180deg at 50% 50%,
      #eeeeee 0deg,
      #fffee2 14.87deg,
      rgba(255, 255, 255, 0.950883) 25.67deg,
      #df68fd 38.19deg,
      rgba(255, 255, 255, 0.815523) 53deg,
      #b7eee5 72.26deg,
      #20282e 122.18deg,
      #29353c 138.07deg,
      rgba(255, 255, 255, 0.596267) 145.34deg,
      #c9fbfe 162.04deg,
      #79a2f2 175.13deg,
      rgba(255, 255, 255, 0.741036) 186.54deg,
      #79a2f2 199.54deg,
      #5d23fd 222.78deg,
      #ffffff 247.79deg,
      #51555e 320.65deg,
      #699cff 343.05deg,
      #ffffff 348.79deg,
      #79a2f2 354.77deg,
      #ffffff 360deg
    );
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
    border-radius: 100%;
    width: 85px;
    height: 85px;
    background-size: 85px;
    border: none;
    padding: 0;

    &:focus {
      outline: none;
    }
  }
}

.provider-box {
  height: 100%;
}

.provider-pool-box {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 24px;
  padding: 3rem;
  height: 100%;
}

.pool-amount-col {
  width: 80%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .max-btn {
    position: absolute;
    right: 20px;
    color: white;
    padding: 10px;
    cursor: pointer;
  }

  &.rpt-output,
  &.reserve-box {
    width: 100%;

    .max-btn {
      cursor: pointer;
    }
  }
}

.pool-form-control {
  align-items: center;
  width: 100%;

  input[type="text"] {
    width: 100%;
    height: 48px;
    background: rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 24px;
    padding: 12px 15px;
    font-weight: 600;
    font-size: 16px;
    color: $color_1;
  }

  &:focus {
    outline: none;
  }
}

.pool-select-col {
  width: 80px;
}

select.pool-form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../images/icons/arrow-down-white.svg") no-repeat 90% center
    rgba(0, 0, 0, 0.12);
}

.pool-amount-row {
  display: flex;
  align-items: center;
  grid-column-gap: 15px;
}

.balance-text {
  display: block;
  font-size: 13px;
  line-height: 1.4;
  color: $color_2;
  margin-top: 2rem;
}

.receive-bottom-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.liquidity-rpt-withdraw {
  margin-bottom: 2rem;
}

.liquidity-rpt-box {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 3rem;
  height: 100%;

  h4 {
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 400;
    color: $color_2;
    margin-bottom: 1rem;
  }
}

.rpt-total {
  display: block;
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 600;
  color: $color_2;
}

.rpt-amount {
  display: block;
  margin-top: 1rem;
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: $color_9;
}

.withdraw-bottom-action {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;

  .btn-action-link + .btn-action-link {
    margin-left: 1.5rem;
  }
}

.liquidity-history {
  margin-bottom: 5rem;
  position: relative;

  h4 {
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 500;
    color: $color_2;
    margin-bottom: 1rem;
  }

  table {
    th {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.45px;
      color: $color_1;
      padding: 15px 0;
      border-color: $border-color_2 !important;
      width: 25%;
    }

    td {
      font-size: 13px;
      font-weight: 400;
      color: $color_9;
      padding: 15px 0;
      border-color: $border-color_2;
      width: 25%;
    }
  }
}

.liquidity-history-row {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 3rem;
}

.volume-wrap {
  position: relative;
  margin-bottom: -3rem;
}

.volume-box {
  background-image: url(../images/box/square/square2.png);
  background-position: center;
  background-size: 100% 100%;

  // background: linear-gradient(
  //   223.88deg,
  //   rgba(255, 255, 255, 0) 1.79%,
  //   rgba(255, 255, 255, 0.1) 98.47%
  // );
  // border: 1px solid rgba(255, 255, 255, 0.1);
  // backdrop-filter: blur(40px);
  border-radius: 24px;
  text-align: center;
  padding: 1px;
  padding-bottom: 3.5rem;

  h4 {
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 400;
    color: $color_2;
    margin-bottom: 1rem;
  }
}

.volume-box-wrap {
  margin-top: 6rem;
}

.total-volume {
  display: block;
  font-size: 3rem;
  line-height: 1.4;
  font-weight: 600;
  // color: $color_2;
  color: $color_19;
}

.all-projects-wrap {
  padding-top: 20px;

  .headingbox {
    background-image: url("../images/box/rectangle/rectangle13.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

  .headingbox1 {
    background-image: url("../images/box/rectangle/rectangle13.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
}

.roya-reserve-heading {
  padding-top: 250px;

  @media screen and (max-width: 991px) {
    padding-top: 12rem;
  }

  @media screen and (max-width: 576px) {
    padding-top: 7rem;
  }

  p {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5;
    color: $color_1;
    padding-top: 2rem;
    margin-bottom: 2rem;
  }
}

.reserve-stake-box {
  background-image: url(../images/box/rectangle/rectangle4.png);
  background-position: center;
  background-size: 100% 100%;

  // background: linear-gradient(
  //   223.88deg,
  //   rgba(255, 255, 255, 0) 1.79%,
  //   rgba(255, 255, 255, 0.1) 98.47%
  // );
  // border: 1px solid rgba(255, 255, 255, 0.1);
  // backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 3rem 3rem 6rem;

  h4 {
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
    color: $color_9;
    margin-bottom: 1rem;
  }
}

.reserve-stake-display {
  text-align: center;
}

.stake-available {
  display: block;
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 500;
  color: $color_19;
  // color: $color_2;
  margin: 2rem 0 1rem;
}

.reserve-stake-btn {
  display: flex;
  align-items: center;
}

.roye-stake-row {
  max-width: 780px;
  margin: -5rem auto 0;
  padding-bottom: 2rem;
}

.roye-box {
  height: 100%;
}

.roye-stake-box {
  background-image: url(../images/box/rectangle/rectangle12.png);
  background-position: center;
  background-size: 100% 100%;

  // background: linear-gradient(
  //   223.88deg,
  //   rgba(255, 255, 255, 0) 1.79%,
  //   rgba(255, 255, 255, 0.1) 98.47%
  // );
  // border: 1px solid rgba(255, 255, 255, 0.1);
  // backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 3rem;
  height: 100%;

  h4 {
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 400;
    color: $color_2;
  }

  .total-volume {
    margin-bottom: 1rem;
  }
}

.claim-amount {
  display: block;
  font-size: 13px;
  line-height: 1.4;
  // color: $color_2;
  color: $color_19;
  margin-top: 0.5rem;
}

.rpt-staking-wrap {
  h3 {
    font-size: 1.75rem;
    line-height: 1.4;
    font-weight: 500;
    color: $color_2;
    margin-bottom: 3rem;
  }

  .heading-row {
    margin-bottom: 3rem;
  }
}

.rpt-dynamic-wrap {
  position: relative;
  padding: 4rem 0 7rem;
}

.rpt-dynamic-box {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 24px;
}

.rpt-accumulate-wrap {
  margin-bottom: -3rem;
}

.rpt-accumulate-box {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 4rem;

  .rpt-amount {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
}

.stake-dynamic-count {
  font-size: 2rem;
  line-height: 1.4;
  font-weight: 700;
  color: $color_2;
  padding: 0.25rem 0;
}

.stake-dynamic-img {
  margin: -2rem 0;
  padding-left: 5rem;

  img {
    max-width: 100%;
  }
}

.submit-wrap {
  padding-top: 50px;

  @media screen and (max-width: 991px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 576px) {
    padding-top: 7rem;
  }

  h3 {
    text-align: center;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 400;
    color: $color_2;
    letter-spacing: 0.5px;
    margin: 0;
    padding: 23px 0 37px;

    @media screen and (max-width: 900px) {
      padding: 10px 0;
      font-size: 18px;
    }

    @media screen and (max-width: 767px) {
      padding: 0;
      font-size: 12px;
    }
  }
}

.submit-container {
  max-width: 950px;
  margin: 0 auto;
}

.submit-inner-box {
  // padding: 2rem;

  @media screen and (max-width: 767px) {
    padding: 0;
  }
}

.submit-form-review {
  margin-top: 100px !important;

  p {
    color: var(--Primary-Dark-Orange, #915101);
    text-align: center;
    font-size: 16px;
    line-height: 120%; /* 19.2px */
  }
}

.full-width {
  width: 100% !important;
}

.submit-form-group {
  margin-top: 32px;

  &:last-child {
    margin-top: 30px;
  }

  & > input,
  textarea {
    &::placeholder {
    }
  }

  .drop-down {
    height: 47px;

    & > div:first-child {
      background-color: rgba(0, 0, 0, 0.12);
      border-radius: 20px;
      padding: 12px 30px;
      font-weight: 400;
      font-size: 15px;
      color: #a5a6aa;

      img {
        width: 12px;
        height: 6px;
        position: unset;
      }
    }

    & > div:last-child {
      & > div {
        border-bottom: 1px solid grey;
      }
    }
  }

  .quill {
    .ql-toolbar {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .ql-container {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.flip-rotate {
  transform: rotate(180deg);
}

.submit-label {
  width: 100%;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 300;
  color: $color_9;
  margin-bottom: 0.5rem;
  text-align: left;
}

.submit-form-control {
  width: 100%;
  border-radius: 4px;
  border: 2px solid var(--Black, #111);
  background: var(--White-White-100, #fff);
  box-shadow: 4px 4px 0px 0px #111;
  padding: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #000 !important;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #a5a6aa;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
  }
}

.submit-action-row {
  text-align: center;
  margin-top: 50px;
}

.staking-nav {
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    display: flex;
    padding: 0;
    margin: 0;

    li {
      a {
        display: block;
        position: relative;
        font-size: 20px;
        line-height: 1.2;
        font-weight: 600;
        color: $color_4;
        text-align: center;
        letter-spacing: 0.1em;
        padding: 15px 25px 15px;
        text-decoration: none;
      }

      a.mobile-only {
        display: none;

        @media (max-width: 800px) {
          display: block;
        }
      }

      a.active {
        color: $color_1;
        background: linear-gradient(
          223.88deg,
          rgba(255, 255, 255, 0) 1.79%,
          rgba(255, 255, 255, 0.1) 98.47%
        );
        -webkit-backdrop-filter: blur(16px);
        backdrop-filter: blur(16px);
        border-radius: 4px;

        &::before {
          position: absolute;
          content: "";
          left: 0;
          bottom: -8px;
          background: url("../images/bottom-line.png") center 0 no-repeat;
          width: 100%;
          height: 20px;
        }
      }
    }
  }
}

.staking-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;

  .staking-nav ul {
    flex-direction: column;
  }
}

.staking-listrow {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5rem;
  margin-left: -10px;
  margin-right: -12px;

  @media (max-width: 800px) {
    display: none;
  }
}

.staking-listrow-slider-mobile {
  display: none;
  padding-top: 5rem;

  @media (max-width: 800px) {
    display: block;
  }
}

.staking-colbox {
  margin-bottom: 25px;
}

.staking-content-info {
  padding: 25px;
  text-align: center;

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: 0.4px;
    color: $color_6;
  }

  ul {
    display: inline-block;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      font-size: 18px;
      line-height: 33px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0.4px;
      color: $color_2;
      padding-left: 15px;

      &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 15px;
        width: 4px;
        height: 4px;
        background: #fff;
      }
    }
  }
}

.dashboard-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.staking-token {
  display: block;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: $color_6;
  margin-bottom: 10px;
}

.stak-arrow {
  display: inline-block;
  vertical-align: middle;
  background: url("../images/icons/arrow-up.png") 0 0 no-repeat;
  width: 10px;
  height: 7px;
}

.staking-name {
  display: block;
  @include cairo(600, 16px, 24px);
  letter-spacing: 0.1px;
  color: #ffffff;
  margin-bottom: 10px;

  i {
    margin-left: 8px;
  }
}

.staking-discount-row {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 24px;
  padding: 24px 20px;

  h4 {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 600;
    letter-spacing: 0.05em;
    color: $color_6;
  }

  .btn-action-link {
    min-width: auto;
    width: 100%;
    margin-top: 15px;
  }
}

.staking-discount-box {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
}

.discount-amount {
  display: block;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: $color_1;
}

.staking-action-row {
  display: flex;
  justify-content: center;
  margin-bottom: -5rem;
  padding-top: 4rem;

  .btn-action-link {
    margin: 0 10px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;

    .btn-action-link {
      width: 207px;
      height: 64px;
      margin-bottom: 12px;
    }
  }
}

.staking-slider {
  width: 100%;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 90px;
  height: 45px;
  position: relative;
  margin: 4rem 0 0;
}

.slider-fillcolor {
  border-radius: 90px;
  height: 45px;
  background: linear-gradient(
    88.83deg,
    #eeeeee -290.8%,
    #fffee2 -272.69%,
    rgba(255, 255, 255, 0.950883) -259.54%,
    #df68fd -244.29%,
    rgba(255, 255, 255, 0.815523) -226.25%,
    #b7eee5 -202.79%,
    #20282e -142%,
    #29353c -122.65%,
    rgba(255, 255, 255, 0.596267) -113.78%,
    #c9fbfe -93.45%,
    #79a2f2 -77.51%,
    rgba(255, 255, 255, 0.741036) -63.62%,
    #79a2f2 -47.78%,
    #5d23fd -19.47%,
    #ffffff 10.99%,
    #51555e 99.71%,
    #699cff 127%,
    #ffffff 133.99%,
    #79a2f2 141.27%,
    #ffffff 147.64%
  );
}

.slider-cursorpoint {
  position: absolute;
  margin-left: -40px;
  top: -20px;
  bottom: 0;
  width: 82px;
  height: 82px;
  border-radius: 100%;
  background: linear-gradient(
    88.83deg,
    #eeeeee -290.8%,
    #fffee2 -272.69%,
    rgba(255, 255, 255, 0.950883) -259.54%,
    #df68fd -244.29%,
    rgba(255, 255, 255, 0.815523) -226.25%,
    #b7eee5 -202.79%,
    #20282e -142%,
    #29353c -122.65%,
    rgba(255, 255, 255, 0.596267) -113.78%,
    #c9fbfe -93.45%,
    #79a2f2 -77.51%,
    rgba(255, 255, 255, 0.741036) -63.62%,
    #79a2f2 -47.78%,
    #5d23fd -19.47%,
    #ffffff 10.99%,
    #51555e 99.71%,
    #699cff 127%,
    #ffffff 133.99%,
    #79a2f2 141.27%,
    #ffffff 147.64%
  );

  &::before {
    position: absolute;
    content: "";
    left: 7px;
    top: 7px;
    bottom: 0;
    width: 68px;
    height: 68px;
    border-radius: 100%;
    background: #151321;
  }
}

.value-itembox {
  margin-left: -15%;

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    margin-left: -5px;
    margin-right: -5px;

    li {
      width: 33.333%;
      padding: 0 5px;
    }
  }
}

.pools-staking {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 300;
    letter-spacing: 0.05em;
    color: $color_1;
  }
}

.total-valueamount {
  display: block;
  font-size: 2rem;
  line-height: 1.4;
  font-weight: 600;
  color: $color_2;
}

.value-locaked {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 6rem 4rem;

  h4 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 300;
    color: $color_1;
    margin-bottom: 1rem;
  }

  .balance-text {
    margin-top: 1rem;
    color: $color_9;
  }
}

.locaked-amount {
  display: block;
  font-size: 2.6rem;
  line-height: 1.4;
  font-weight: 700;
  color: $color_2;
  letter-spacing: 0.05em;
}

.row-center {
  align-items: center;
}

.value-graph-locaked {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 3rem 0 3rem 0;

  h4 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 300;
    color: $color_1;
    margin-bottom: 1rem;
  }
}

.graph-content-info {
  padding: 0 4rem;
}

.volume-display {
  display: flex;
  align-items: center;
}

.volume-percent {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  color: $color_10;
  margin-left: 10px;

  img {
    margin-right: 8px;
  }
}

.total-valuerow {
  padding-top: 4rem;
}

.total-graphrow {
  padding-top: 2rem;
  margin-bottom: -3rem;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: $background-color_1;
}

.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.modal-backdrop {
  background: linear-gradient(
      359.16deg,
      rgba(21, 20, 29, 0.2) 0.69%,
      rgba(32, 27, 57, 0.2) 20.04%,
      rgba(70, 64, 94, 0.2) 41.67%,
      rgba(27, 20, 67, 0.2) 55.33%
    ),
    rgba(14, 13, 21, 0.9) !important;
  backdrop-filter: blur(14px);
}

.modal-backdrop.show {
  opacity: 1 !important;
}

.register-modalwrap {
  width: 100%;
  margin: 0 auto;
}

.register-modal-dialog {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  ) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  backdrop-filter: blur(40px);
  border-radius: 24px !important;
  padding: 25px !important;
  position: relative;
}

.modal-close {
  position: absolute;
  right: -24px;
  top: -24px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: url("../images/register-stepbg.png") 0 0 no-repeat;
  border: none;
  background-size: 48px 48px;
}

.register-innerbg {
  h3 {
    text-align: center;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: 0.1em;
    color: #000 !important;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
    margin-bottom: 2rem;
  }
}

.register-step {
  position: relative;

  &::after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 24px;
    width: 88%;
    background: linear-gradient(
      223.88deg,
      rgba(255, 255, 255, 0) 1.79%,
      rgba(255, 255, 255, 0.15) 98.47%
    );
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(40px);
    border-radius: 24px;
    height: 10px;
  }

  ul {
    padding: 0;
    margin: 0;
    display: flex;

    li {
      width: 33.333%;
      text-align: center;
      position: relative;
      z-index: 1;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }

    li.active {
      .stepround {
        background: url("../images/round-box.png") 0 0 no-repeat;
        background-size: 52px 52px;
        width: 52px;
        height: 52px;
      }
    }
  }
}

.stepbox {
  display: inline-block;
  text-align: center;
}

.stepround {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  border-radius: 100%;
  background: url("../images/register-stepbg.png") 0 0 no-repeat;
  background-size: 48px 48px;
  font-size: 20px;
  line-height: 48px;
  font-weight: 600;
  color: $color_1;
  text-align: center;
}

.step-name {
  display: block;
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  color: $color_6;
  text-align: center;
  margin-top: 12px;
}

.regis-container {
  margin-top: 2rem;

  h4 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    color: $color_1;
    margin-bottom: 1rem;
  }
}

.wallet-listitem {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    li {
      width: 33.333%;
      padding: 0 15px;
    }
  }
}

.wallet-box {
  height: 100%;
  width: 100%;
  text-align: center;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2.5rem 0 2rem;
  cursor: pointer;
}

.walletimg {
  height: 54px;
}

.walletname {
  display: block;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  color: $color_1;
  margin-top: 1rem;
}

.register-action-row {
  display: flex;
  justify-content: space-between;
  padding: 3rem 0 1rem;
}

.bottom-border-none {
  &::before {
    display: none;
  }
}

.list-lots-display {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-column-gap: 25px;
}

.list-lots-table {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 12px;

  th {
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    color: $color_1;
    border-color: $border-color_2 !important;
  }

  td {
    border: none;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    color: $color_9;
  }

  tbody {
    border: none !important;
  }
}

.email-inputbox {
  label {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 300;
    color: $color_11;
    margin-bottom: 0.5rem;
  }
}

.social-media-wrapper {
  // margin-top: -15rem;
  padding: 0;
}

.social-container {
  max-width: 790px;
  margin: 0 auto;

  .staking-nav {
    margin-bottom: 3rem;
  }
}

.social-media-tabs {
  display: grid;
  align-items: center;
  grid-template-columns: 340px 1fr;
}

.media-tablist-item {
  ul {
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 2rem;

      a {
        position: relative;
        display: inline-block;
        text-decoration: none;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0.05em;
        line-height: 1.2;
        color: $color_6;
      }

      a.active {
        font-weight: 500;
        color: $color_2;
        padding-left: 55px;

        &::before {
          position: absolute;
          content: "";
          left: 0;
          width: 40px;
          height: 2px;
          background: rgba(255, 255, 255, 0.3);
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.social-media-box {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 2rem;
}

.social-mediaitem {
  background: #1c1c1c;
  border-radius: 20px;
  padding: 16px;
  display: grid;
  grid-template-columns: 42px 1fr;
  margin-bottom: 10px;
}

.media-target-box {
  text-align: center;
  margin: 1rem 0 3rem;
}

.targetbox-container {
  display: inline-block;
  position: relative;
}

.targetbox-info {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.target-text {
  display: block;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.4px;
  color: $color_4;
  margin-top: 3px;
}

.social-imgicon {
  svg {
    width: 32px;
    height: 32px;
    fill: #fff;
  }
}

.social-like-comment {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
}

.social-countbox {
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    fill: rgba(255, 255, 255, 0.3);
  }
}

.social-countbox + .social-countbox {
  margin-left: 12px;
}

.total-social-count {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: $color_12;
}

.social-target-row {
  display: grid;
  align-items: center;
  grid-template-columns: 118px 1fr;
}

.social-feed-list {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  color: $color_1;
}

.total-target {
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 1px;
  color: $color_1;

  span {
    font-size: 16px;
    color: $color_13;
  }
}

.social-bottom-info {
  max-width: 1000px;
  margin: 0 auto 15rem;
  text-align: center;
  padding: 5rem 0;
  background-image: url("../images/box/cirgle_elipsae/circle3.png");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  @media screen and (max-width: 1280px) {
    margin: 0 auto 10rem;
  }

  @media screen and (max-width: 767px) {
    padding-left: 2rem;
    padding-right: 2rem;

    p {
      padding: 10px 0 !important;
      font-size: 16px !important;
    }
  }

  .btn-action-link {
    margin-bottom: 2rem;
  }

  p {
    text-align: left;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: var(--Black-Black-40, rgba(0, 0, 0, 0.4));
    margin: 0;
    padding-top: 1rem;

    span {
      color: #000;
    }

    strong {
      color: #000;
    }

    &.left-label {
      font-size: 20px;
      padding-left: 60px;
    }
  }
}

.social-ido-wrap {
  max-width: 540px;
  margin: 0 auto;
}

.social-idobox {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 2rem;
}

.time-count-wrap {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 15px 0;
}

.timecount-box {
  text-align: center;
  margin: 0 20px;
}

.time-countno {
  display: block;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  font-weight: 600;
  color: $color_1;
}

.count-txt {
  display: block;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: $color_12;
  margin-top: 3px;
}

.invest-row {
  display: grid;
  grid-template-columns: 1fr auto;

  input {
    font-size: 13px;
    border-radius: 4px 0 0 4px;
  }

  .btn-action-link {
    min-width: 120px;
  }
}

.ido-name-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 2rem;
}

.ido-result {
  display: inline-block;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 10px 25px;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 1.2;
  color: $color_15;
  margin-bottom: 1rem;
}

.ido-social-link {
  padding: 0;
  margin: 1.5rem 0;
  display: flex;

  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: url("../images/social-roundbg.png") 0 0 no-repeat;
    background-size: 60px 60px;
    margin: 0 auto;

    a {
      svg {
        position: relative;
        z-index: 1;
        width: 22px;
        height: 22px;
        fill: #fff;
      }
    }
  }

  li + .ido-social-link {
    li {
      margin-left: 8px;
    }
  }
}

.ido-circle-counter {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  img {
    max-width: 90%;
    height: auto;
  }
}

.cursor {
  cursor: pointer;
}

.show {
  display: inline-block;
}

.hide {
  display: none;
}

.stak-arrow-down {
  display: inline-block;
  vertical-align: middle;
  background: url("../images/icons/arrow-down-white.svg") 0 0 no-repeat;
  width: 10px;
  height: 7px;
}

.staking-imgbox-patch {
  left: -27px !important;
}

.upcoming-projects-row {
  max-width: 1600px;
  margin: 5rem auto 0;
  padding: 0 15px;
  text-align: center;

  span {
    color: #a0a5ad;
  }
}

.upcoming-box {
  position: relative;
  margin-bottom: 30px;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 35%;
    height: 120px;
    content: "";
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 24px 0 0 0;
    border-right: none;
    border-bottom: none;
  }

  &::before {
    position: absolute;
    right: 0;
    top: 0;
    width: 35%;
    height: 120px;
    content: "";
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0 24px 0 0;
    border-left: none;
    border-bottom: none;
  }
}

.upcoming-innerbox {
  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 35%;
    height: 120px;
    content: "";
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0 0 0 24px;
    border-right: none;
    border-top: none;
  }

  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 35%;
    height: 120px;
    content: "";
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0 0 24px 0;
    border-left: none;
    border-top: none;
  }

  position: relative;
  padding: 15px;
}

.upcoming-cardbox {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 24px 7px 0;
}

.upcomingcard-gamebox {
  box-shadow: inset 0px 2.9422px 8.09105px rgba(0, 0, 0, 0.16),
    inset 0px 0px 1.4711px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(11.7688px);
  border-radius: 18px;
}

.upcoming-cardimgbox {
  height: 190px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: inset 0px 2.9422px 8.09105px rgba(0, 0, 0, 0.16),
      inset 0px 0px 1.4711px rgba(255, 255, 255, 0.4);
    border-radius: 18px;
  }
}

.upcoming-gamecardinfo {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.gamecount {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 1.2;
  color: $color_1;
  padding-top: 5px;

  img {
    margin-right: 5px;
  }
}

.game-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: $color_1;
}

.game-name-smallinfo {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: $color_4;
}

.upcoming-game-register-action {
  text-align: center;
  margin: 10px 0 20px;

  .btn-action-link {
    min-width: 58%;
    max-width: 180px;
  }
}

.application-row {
  margin-bottom: 5rem;

  @media (max-width: 800px) {
    display: none;
  }
}

.applications-original-img {
  background: #0a0f12;
  border: 1px solid #fff;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  margin-right: 20px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
}

.applications-lis-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.applications-header-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 1rem;
}

.applications-header-col {
  font-size: 14px;
  font-weight: 400;
  padding: 15px 0;
  letter-spacing: 0.45px;
}

.applications-col {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: $color_1;
  padding: 15px 0;
  letter-spacing: 0.45px;
}

.applications-box {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 24px;
  margin-top: 5rem;
}

.apply-wrap {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 50px;

  .apply-header {
    background-image: url("../images/submit-form-wrapper.png");
    background-position: center;
    background-size: 100% 100%;
    padding: 30px 60px;
  }

  @media screen and (max-width: 991px) {
    padding-top: 12rem;
  }

  @media screen and (max-width: 576px) {
    padding-top: 7rem;
  }
}

.game-apply-info {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #000;
}

.game-apply-img {
  width: 150px;
  height: 150px;
  margin-right: 40px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
}

.game-apply-content {
  h4 {
    font-weight: 700;
    font-size: 32px;
    line-height: 56px;
    letter-spacing: 0.01em;
    color: black !important;
    margin-bottom: 0;
  }
}

.game-dark-info {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: $color_8;
}

.game-apply-list {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.apply-list-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #9aa1ac !important;
}

.apply-list-labelinfo {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: black !important;
}

.join-safelist-box {
  margin-top: 3rem;

  .join-safelist-header {
    background-image: url("../images/submit-form-wrapper.png");
    background-position: center;
    background-size: 100% 100%;
    padding: 30px 60px;
  }
  @media (max-width: 767px) {
    padding: 38px 17px;
  }

  h4 {
    font-weight: 700;
    font-size: 32px;
    line-height: 56px;
    letter-spacing: 0.01em;
    color: #000 !important;
    margin-bottom: 14px;
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: var(--Black-Black-40, rgba(0, 0, 0, 0.4));
    margin-bottom: 24px;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 56px;
    color: #000 !important;
    padding-bottom: 14px;
    margin-bottom: 0;
  }
}

.join-safelist-step {
  padding-top: 1rem;
  background-image: url("../images/submit-form-wrapper.png");
  background-position: center;
  background-size: 100% 100%;
  padding: 50px 30px;

  .submit-form-group {
    margin-top: 0;
    margin-bottom: 25px;

    .submit-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      color: #000 !important;
      margin-bottom: 7px;
    }
  }

  .alert-white {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #ffffff;
    padding-top: 8px;
  }
}

.apply-media-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #000;

  &:last-child {
    border-bottom: none;
  }
}

.apply-media-action {
  display: flex;

  @media (max-width: 767px) {
    margin-top: 10px;
    width: 100%;
  }

  .btn-action-link {
    min-width: auto;
    width: 160px;
    height: 68px;
    padding-left: 13.5px;
  }
}

.feed-action-link.btn-action-link {
  &::before {
    display: none;
  }
}

.feed-action {
  height: 10px;
}

.did-action {
  text-align: center;
  margin-left: 15px;
  width: 160px;

  .btn-action-link {
    width: 100%;
  }
}

.apply-media-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: var(--Black-Black-10, rgba(0, 0, 0, 0.1));
  background-size: 60px 60px;
  margin-right: 20px;

  svg {
    position: relative;
    z-index: 1;
    width: 25px;
    height: 25px;
  }
}

.apply-media-display {
  display: flex;
  align-items: center;
}

.apply-social-info {
  h5 {
    margin-bottom: 3px;
    color: black !important;
  }

  span {
    color: var(--Black-Black-40, rgba(0, 0, 0, 0.4));
  }
}

.apply-checkbox-list {
  position: relative;

  input {
    display: none;

    &:checked + label {
      &::after {
        display: block;
      }
    }
  }

  label {
    position: relative;
    padding-left: 25px;
    font-size: 13px;
    line-height: 1.4;
    font-weight: 300;
    letter-spacing: 0.25px;
    color: $color_9;
    padding-top: 3px;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 3px;
      width: 16px;
      height: 16px;
      background: rgba(255, 255, 255, 0.25);
      border-radius: 100%;
    }

    &::after {
      display: none;
      position: absolute;
      content: "";
      left: 3px;
      top: 6px;
      width: 10px;
      height: 10px;
      background: rgba(0, 0, 0, 0.75);
      border-radius: 100%;
    }
  }
}

.submit-apply-action {
  padding: 1rem 0;

  .btn-action-link {
    width: 100%;
  }
}

.successfully {
  margin-left: 0px;

  h4 {
    color: var(--Black-Black-40, rgba(0, 0, 0, 0.4)) !important;
  }
}

.apply-checkbox-listnew {
  position: relative;

  input {
    display: none;
  }
}

.check {
  width: 500px;
  margin: 50px auto;
  clear: both;
  display: block;
  background-color: $background-color_2;
  border-radius: 4px;

  &::after {
    clear: both;
    display: block;
    content: "";
  }

  .checkbox-container {
    float: left;
    width: 50%;
    box-sizing: border-box;
    text-align: center;
    padding: 40px 0px;
  }
}

.checkbox-label {
  position: relative;
  padding-left: 25px;
  font-size: 13px;
  line-height: 1.4;
  font-weight: 300;
  letter-spacing: 0.2px;
  color: black !important;
  padding-top: 3px;

  &.staking-lot-modal-check {
    height: 20px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .mark {
      background-color: $background-color_4;
      border-radius: 50px;
      transform: rotate(0deg) scale(1);
      opacity: 1;
      border: 2px solid rgba(0, 0, 0, 0.12);

      &::after {
        transform: rotate(45deg) scale(1);
        left: 5px;
        top: 1px;
        width: 6px;
        height: 12px;
        border: solid rgb(19, 18, 18);
        border-width: 0 4px 4px 0;
        border-radius: 0;
      }

      &::before {
        left: -1px;
        top: -1px;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        transform: scale(3);
        opacity: 0;
        transition: all 0.3s ease-out;
      }
    }

    &:checked ~ .mark1 {
      background-color: $background-color_4;
      border-radius: 50px;
      transform: rotate(0deg) scale(1);
      opacity: 1;
      border: 2px solid white;

      &::after {
        transform: rotate(45deg) scale(1);
        left: 5px;
        top: 1px;
        width: 6px;
        height: 12px;
        border: solid rgb(19, 18, 18);
        border-width: 0 4px 4px 0;
        border-radius: 0;
      }

      &::before {
        left: -3px;
        top: -3px;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        transform: scale(3);
        opacity: 0;
        transition: all 0.3s ease-out;
      }
    }

    &:checked ~ .mark2 {
      background-color: $background-color_4;
      border-radius: 50px;
      transform: rotate(0deg) scale(1);
      opacity: 1;
      border: 2px solid white;

      &::after {
        transform: rotate(45deg) scale(1);
        left: 5px;
        top: 1px;
        width: 6px;
        height: 12px;
        border: solid gray;
        border-width: 0 4px 4px 0;
        border-radius: 0;
      }

      &::before {
        left: -3px;
        top: -3px;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        transform: scale(3);
        opacity: 0;
        transition: all 0.3s ease-out;
      }
    }
  }

  .mark {
    left: 0;
    top: 2px;
    position: absolute;
    height: 20px;
    width: 20px;
    background: rgba(0, 0, 0, 0.12);
    border-radius: 50px;
    transition: all 0.3s ease-in;

    &::after {
      position: absolute;
      content: "";
      border-radius: 5px;
    }

    &::before {
      position: absolute;
      content: "";
      border-radius: 10px;
      border: 5px solid rgb(230, 230, 223);
      transform: scale(0);
    }
  }

  .mark1 {
    left: 0;
    top: 2px;
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 50px;
    transition: all 0.3s ease-in;

    &::after {
      position: absolute;
      content: "";
      border-radius: 5px;
    }

    &::before {
      position: absolute;
      content: "";
      border-radius: 10px;
      border: 5px solid rgb(230, 230, 223);
      transform: scale(0);
    }
  }

  .mark2 {
    top: 0px;
    border: 1px solid rgb(230, 230, 223);
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 50px;
    transition: all 0.3s ease-in;

    &::after {
      position: absolute;
      content: "";
      border-radius: 5px;
    }

    &::before {
      position: absolute;
      content: "";
      border-radius: 10px;
      border: 5px solid rgb(230, 230, 223);
      transform: scale(0);
    }
  }
}

.newbody {
  color: $color_16;
  font-size: 14px;
  margin: 0;
  padding: 0;
  background: linear-gradient(
      359.16deg,
      rgba(21, 20, 29, 0.2) 0.69%,
      rgba(32, 27, 57, 0.2) 20.04%,
      rgba(70, 64, 94, 0.2) 41.67%,
      rgba(27, 20, 67, 0.2) 55.33%
    ),
    #0e0d15 !important;
}

.newbtn-action-link {
  &:before {
    position: absolute;
    content: "";
    height: 3px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      269.99deg,
      #eee -69.29%,
      #fffee2 -62.4%,
      hsla(0, 0%, 100%, 0.950883) -57.4%,
      #df68fd -51.6%,
      hsla(0, 0%, 100%, 0.815523) -44.74%,
      #b7eee5 -35.82%,
      #20282e -12.7%,
      #29353c -5.34%,
      hsla(0, 0%, 100%, 0.596267) -1.97%,
      #c9fbfe 5.76%,
      #79a2f2 11.83%,
      hsla(0, 0%, 100%, 0.741036) 17.11%,
      #79a2f2 23.13%,
      #5d23fd 33.9%,
      #fff 45.48%,
      #51555e 79.22%,
      #699cff 89.6%,
      #fff 92.26%,
      #79a2f2 95.03%,
      #fff 97.45%
    );
  }

  display: inline-block;
  background: hsla(0, 0%, 100%, 0.02);
  border: none;
  box-shadow: 0 24px 48px hsl(0deg 0% 100% / 10%),
    inset 0 4px 11px rgb(0 0 0 / 16%), inset 0 0 2px hsl(0deg 0% 100% / 40%);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  color: $color_1;
  padding: 36px;
  letter-spacing: 0.4px;
  min-width: 229px;
  text-decoration: none;
  width: 100%;
  margin-top: 35px;
}

.newbtn-action-link2 {
  &:before {
    position: absolute;
    content: "";
    height: 3px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      269.99deg,
      #eee -69.29%,
      #fffee2 -62.4%,
      hsla(0, 0%, 100%, 0.950883) -57.4%,
      #df68fd -51.6%,
      hsla(0, 0%, 100%, 0.815523) -44.74%,
      #b7eee5 -35.82%,
      #20282e -12.7%,
      #29353c -5.34%,
      hsla(0, 0%, 100%, 0.596267) -1.97%,
      #c9fbfe 5.76%,
      #79a2f2 11.83%,
      hsla(0, 0%, 100%, 0.741036) 17.11%,
      #79a2f2 23.13%,
      #5d23fd 33.9%,
      #fff 45.48%,
      #51555e 79.22%,
      #699cff 89.6%,
      #fff 92.26%,
      #79a2f2 95.03%,
      #fff 97.45%
    );
  }

  display: inline-block;
  background: hsla(0, 0%, 100%, 0.02);
  border: none;
  box-shadow: 0 24px 48px hsl(0deg 0% 100% / 10%),
    inset 0 4px 11px rgb(0 0 0 / 16%), inset 0 0 2px hsl(0deg 0% 100% / 40%);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  color: $color_1;
  padding: 22px;
  letter-spacing: 0.4px;
  min-width: 153px;
  text-decoration: none;
  left: 595px;
  top: -105px;
}

.newcursor {
  cursor: pointer;
}

.newdetails-banner-wrap {
  padding-top: 570px;

  img {
    width: 100%;
    padding-left: 680px;
    margin-left: 100px;
  }
}

.newholo2 {
  margin-left: -100px;
  margin-top: -1800px;
}

.newdetails-container {
  margin-top: -150rem;
}

.newcontainer {
  max-width: 1320px;
}

.newgame-details-grid {
  display: grid;
  grid-template-columns: 360px 1fr;
  grid-column-gap: 30px;
  margin-left: 300px;
}

.newborder-box-wrap.details-img-border {
  border-radius: 32px;
}

.newdetails-sidebar-img {
  border-radius: 32px;
  height: 280px;

  img {
    width: 85%;
    height: 110%;
    border-radius: 32px;
    object-fit: cover;
  }
}

.newsidebar-details-01 {
  padding: 2rem 0;
  text-align: center;
  margin-left: 360px;
  margin-top: -200px;
  position: absolute;

  h4 {
    font-size: 2rem;
    line-height: 1.4;
    font-weight: 700;
    color: $color_1;
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $color_8;
    margin-right: 100px;
  }
}

.alert {
  color: $color_17;
}

.newabout-project-box {
  background: linear-gradient(
    223.88deg,
    hsla(0, 0%, 100%, 0) 1.79%,
    hsla(0, 0%, 100%, 0.1) 98.47%
  );
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 4rem;
}

.newabout-top-row {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.23);
  padding-bottom: 3rem;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.23);
  padding-bottom: 3rem;

  h3 {
    font-size: 2.25rem;
    line-height: 1.4;
    font-weight: 600;
    color: $color_1;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.3rem;
    line-height: 1.4;
    color: $color_8;
    margin-bottom: 2rem;
  }
}

.newsocial-link {
  padding: 0;
  margin: 0;
  margin-right: 560px;
  row-gap: 0px;
  display: grid;
  margin-left: -110px;

  li {
    a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      background: url("../images/social-roundbg.png");
      background-size: 60px 60px;
      margin: 0 auto;

      svg {
        position: relative;
        z-index: 1;
        width: 25px;
        height: 25px;
      }
    }
  }

  li + li {
    margin-left: 0px;
    margin-top: -55px;
  }
}

.newbasic.h2 {
  color: $color_3;
}

.newpool-box {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: $color_18;
  margin-bottom: 1rem;

  h6 {
    color: $color_3;
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
    color: $color_18;
    margin-bottom: 1rem;
  }
}

.newpool-from-control {
  input {
    width: 100%;
    background: rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 24px;
    padding: 12px 15px;
    font-weight: 600;
    font-size: 16px;
    color: $color_1;
  }
}

.newSocial-media {
  padding-top: 40px;
  padding-bottom: 40px;

  h2 {
    color: $color_3;
  }
}

.newfollow {
  margin-left: 185px;
  margin-top: -55px;
  margin-left: 172px;
  margin-top: -55px;

  h5 {
    color: $color_3;
  }

  span {
    color: $color_3;
    position: absolute;
  }
}

.newRetweet {
  margin-left: 170px;
  margin-top: -55px;

  h5 {
    color: $color_3;
  }

  span {
    color: $color_3;
    position: absolute;
  }
}

.newJoin {
  h5 {
    margin-left: 170px;
    margin-top: -55px;
    color: $color_3;
  }

  span {
    color: $color_3;
    position: absolute;
    padding-left: 170px;
  }
}

.newabout-winning-row {
  h3 {
    font-size: 2rem;
    line-height: 1.4;
    font-weight: 600;
    color: $color_3;
    margin-bottom: 1.5rem;
  }
}

.newbtn-action-link1 {
  display: inline-block;
  background: hsla(0, 0%, 100%, 0.02);
  border: none;
  box-shadow: 0 24px 48px hsl(0deg 0% 100% / 10%),
    inset 0 4px 11px rgb(0 0 0 / 16%), inset 0 0 2px hsl(0deg 0% 100% / 40%);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  color: $color_1;
  padding: 22px;
  letter-spacing: 0.4px;
  min-width: 153px;
  text-decoration: none;
  left: 385px;
  top: -40px;
}

.newTerms-and-Conditions {
  color: $color_3;
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: $color_18;
  margin-bottom: 1rem;
  padding-top: 60px;
}

.newupdates {
  color: $color_3;
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: $color_18;
  margin-bottom: 1rem;
  padding-top: 20px;
}

.newDark-Frontiers {
  padding-top: 5px;
  padding-bottom: 30px;
  color: $color_3;
}

.newtotal {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: $color_18;
  margin-bottom: 1rem;
  padding-top: 70px;
  padding-left: 25px;
}

.newPersonal-Allocaiton {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: $color_18;
  margin-bottom: 1rem;
  padding-top: 30px;
  padding-left: 25px;
}

.newIDO-Date {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: $color_18;
  margin-bottom: 1rem;
  padding-top: 30px;
  padding-left: 25px;
  padding-bottom: 75px;
}

.newdoller {
  h4 {
    font-size: 20px;
    color: $color_3;
  }

  padding-left: 640px;
  margin-top: -25px;
}

.newdoller1 {
  h4 {
    font-size: 20px;
    color: $color_3;
  }

  padding-left: 603px;
  margin-top: -25px;
}

.newdoller2 {
  h4 {
    font-size: 20px;
    color: $color_3;
  }

  padding-left: 415px;
  margin-top: -25px;
  position: absolute;
}

.newcontainer2 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    input ~ .checkmark {
      background-color: $background-color_5;
    }

    input ~ .newcheckmark {
      background-color: $background-color_5;
    }
  }

  input {
    &:checked ~ .checkmark {
      background-color: $background-color_6;

      &:after {
        display: block;
      }
    }

    &:checked ~ .newcheckmark {
      background-color: $background-color_6;

      &:after {
        display: block;
      }
    }
  }

  .newcheckmark {
    &:after {
      top: 7px;
      left: 7px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: white;
    }
  }
}

.newcheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $background-color_5;
  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.newholo1 {
  padding-left: 675px;
}

.newdoller.hr {
  height: 2px;
  border-width: 0;
  color: $color_19;
  background-color: $background-color_5;
  width: 900px;
}

.newhr {
  width: 225%;
}

.newbasic {
  h2 {
    color: $color_3;
  }
}

.newbordarboxwrap {
  margin-right: -385px;
}

.newcheckmark1 {
  margin-left: 620px;
  margin-right: -506px;
  margin-top: -105px;
}

.New-btn-action-link1 {
  display: inline-block;
  background: rgba(255, 255, 255, 0.02);
  border: none;
  box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1),
    inset 0px 4px 11px rgba(0, 0, 0, 0.16),
    inset 0px 0px 2px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  color: $color_2;
  padding: 18px 22px;
  letter-spacing: 0.4px;
  min-width: 180px;
  text-decoration: none;

  &:hover {
    color: $color_2;
  }

  &::before {
    position: absolute;
    content: "";
    height: 3px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(
      269.99deg,
      #eeeeee -69.29%,
      #fffee2 -62.4%,
      rgba(255, 255, 255, 0.950883) -57.4%,
      #df68fd -51.6%,
      rgba(255, 255, 255, 0.815523) -44.74%,
      #b7eee5 -35.82%,
      #20282e -12.7%,
      #29353c -5.34%,
      rgba(255, 255, 255, 0.596267) -1.97%,
      #c9fbfe 5.76%,
      #79a2f2 11.83%,
      rgba(255, 255, 255, 0.741036) 17.11%,
      #79a2f2 23.13%,
      #5d23fd 33.9%,
      #ffffff 45.48%,
      #51555e 79.22%,
      #699cff 89.6%,
      #ffffff 92.26%,
      #79a2f2 95.03%,
      #ffffff 97.45%
    );
  }
}

.new-submit-col {
  margin-left: 30px;
  display: block;

  @media (max-width: 991px) {
    display: none;
  }
}

.New-submit-col1 {
  margin-top: 2rem;
}

.New-game-dark-coin {
  text-align: center;

  h4 {
    color: #000 !important;
    font-size: 30px;
    line-height: 26px;
    font-weight: 700;
    margin-top: 7px;
    margin-bottom: 13px;
  }

  h5 {
    color: #000 !important;
    font-size: 26px;
    line-height: 26px;
    font-weight: 700;
    margin-top: 7px;
    margin-bottom: 13px;
  }

  p {
    color: $color_8;
    font-size: 20px;
    margin-top: 20px;
    line-height: 120%; /* 16.8px */
  }
}

.new-geme-details-sidebar-box {
  // border: 1px solid rgba(255, 255, 255, 0.1);
  // border-radius: 24px;
  padding: 2rem;
  background: url('../../src/images/box/rectangle/game-detail-title-bg.png') center/100% 100% no-repeat;
  // background: linear-gradient(
  //   223.88deg,
  //   rgba(255, 255, 255, 0) 1.79%,
  //   rgba(255, 255, 255, 0.08) 98.47%
  // );
  // backdrop-filter: blur(40px);
  margin-top: 30px;
}

.new-game-details-02 {
  margin-top: -35px;
}

.New-game-right-back-cover {
  img {
    margin-top: -126rem;
  }
}

.New-game-left-back-cover {
  margin-top: 13rem;
}

.new-page-frame {
  @media screen and (max-width: 991px) {
    display: none;
  }

  img {
    margin-top: -12rem;
  }
}

.new-page-frame1 {
  @media screen and (max-width: 991px) {
    display: none;
  }

  img {
    margin-top: -42rem;
    margin-left: 15rem;
  }
}

.new-page-frame2 {
  @media screen and (max-width: 991px) {
    display: none;
  }

  img {
    margin-top: -50rem;
    margin-left: 31rem;
  }
}

.New-sidebar-dtails-bottom {
  padding: 2rem;
  display: grid;
  align-items: center;
  grid-template-columns: 60px 1fr;
  grid-column-gap: 15px;
}

.New-details-info-percent {
  background: #9aa1ac;
  height: 10px;
  border-radius: 100px;
  margin-top: 2rem;
}

.applications-lis-item1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.applications-box1 {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 24px;
  margin-top: 4rem;
  margin-bottom: 5rem;
}

.buy-roya-btn-action-link {
  display: inline-block;
  background: rgba(255, 255, 255, 0.02);
  border: none;
  box-shadow: 0px 24px 48px rgb(255 255 255 / 10%),
    inset 0px 4px 11px rgb(0 0 0 / 16%),
    inset 0px 0px 2px rgb(255 255 255 / 40%);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  color: $color_2;
  padding: 18px 22px;
  letter-spacing: 0.4px;
  min-width: 130px;
  text-decoration: none;
  margin-right: 1rem;

  &::before {
    position: absolute;
    content: "";
    height: 3px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(
      269.99deg,
      #eeeeee -69.29%,
      #fffee2 -62.4%,
      rgba(255, 255, 255, 0.950883) -57.4%,
      #df68fd -51.6%,
      rgba(255, 255, 255, 0.815523) -44.74%,
      #b7eee5 -35.82%,
      #20282e -12.7%,
      #29353c -5.34%,
      rgba(255, 255, 255, 0.596267) -1.97%,
      #c9fbfe 5.76%,
      #79a2f2 11.83%,
      rgba(255, 255, 255, 0.741036) 17.11%,
      #79a2f2 23.13%,
      #5d23fd 33.9%,
      #ffffff 45.48%,
      #51555e 79.22%,
      #699cff 89.6%,
      #ffffff 92.26%,
      #79a2f2 95.03%,
      #ffffff 97.45%
    );
  }
}

.dashboard-inner-wrap {
  padding: 0rem 0 15rem;
  position: relative;
}

.dashboard-upcoming-innerbox {
  position: relative;
  padding: 15px;

  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 35%;
    height: 120px;
    content: "";
    border-radius: 0 0 24px 0;
    border-left: none;
    border-top: none;
  }

  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 35%;
    height: 120px;
    content: "";
    border-radius: 0 0 0 24px;
    border-right: none;
    border-top: none;
  }
}

.dashboard-active-allocation-row {
  display: flex;
}

.dark-frontiers-logo {
  padding: 0 10px 0px;

  h5 {
    position: absolute;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: 0.05em;
  }
}

.completeicon-box {
  background: #4758ee;
  backdrop-filter: blur(40px);
  border-radius: 8px;
  padding: 5px 20px 1px;
}

.dark-applications-lis-item {
  display: grid;
  grid-template-columns: 7rem 6rem;
  margin: -1.1rem -10px 0px;
}

.dark-applications-original {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.05em;
  color: $color_4;
}

.dark-active-allocation-row {
  display: flex;
  margin-bottom: 2rem;
}

.dark-table {
  margin-bottom: 1rem;
}

.dark-applications-col {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: $color_1;
  padding: 15px 0;
  letter-spacing: 0.45px;
  justify-content: flex-end;
}

.staking-applications-lis-item {
  display: grid;
  grid-template-columns: 1fr 248px;
  grid-column-gap: 15px;
  color: $color_3;
}

.staking-applications-col {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: $color_1;
  padding: 8px 16px 8px;
  letter-spacing: 0.45px;

  @media (max-width: 800px) {
    padding-left: 30px !important;
    padding-right: 0 !important;
    text-align: left;
    opacity: 0.5;
  }
}

.staking-applications-header-col {
  font-size: 17px;
  font-weight: 600;
  padding: 15px 64px 15px;
  letter-spacing: 0.45px;

  @media (max-width: 800px) {
    padding-left: 30px !important;
    padding-right: 0 !important;
    text-align: left;

    &:nth-child(2) {
      display: none;
    }
  }
}

.tier-applications-lis-item {
  display: grid;
  grid-template-columns: 1fr 1fr 15rem 21rem;
  color: $color_3;
}

.tier-com-applications-lis-item {
  display: grid;
  grid-template-columns: 1fr 170px 15rem 336px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.tier-applications-header-col {
  font-size: 17px;
  font-weight: 600;
  padding: 15px 57px 15px;
  letter-spacing: 0.45px;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
}

.tier-applications-col {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: $color_1;
  padding: 8px 14px 8px;
  letter-spacing: 0.45px;
  // background: linear-gradient(
  //   223.88deg,
  //   rgba(255, 255, 255, 0) 1.79%,
  //   rgba(255, 255, 255, 0.1) 98.47%
  // );
  // -webkit-backdrop-filter: blur(16px);
  // backdrop-filter: blur(16px);
  justify-content: space-around;
}

.allocation-pool-row {
  text-align: center;

  h2 {
    font-size: 25px;
    line-height: 1.2;
    font-weight: 600;
    color: $color_1;
    text-align: center;
    letter-spacing: 0.1em;
    text-shadow: 0px 0px 8px rgb(255 255 255 / 45%);
    margin: 0;
  }

  @media (max-width: 800px) {
    display: none;
  }
}

.dropbtn1 {
  display: inline-block;
  border: 1px solid white;
  position: relative;
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
  line-height: 0;
  color: $color_1;
  padding: 17px;
  letter-spacing: 0.4px;
  min-width: 185px;
  text-decoration: none;
  bottom: 25px;
  background: transparent;
  border-radius: 20px;
  opacity: 1;
  cursor: pointer;
  text-align-last: left;
}

.dropdown1 {
  position: relative;
  display: inline-block;

  &:hover {
    .dropdown-content {
      display: block;
    }

    .dropbtn1 {
      background-color: $background-color_9;
    }
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: $background-color_7;
  min-width: 190px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  a {
    color: $color_16;
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: $background-color_8;
    }
  }
}

.token-sale-application-row {
  margin-bottom: 4rem;
  margin-top: 20rem;
}

.token-sale-img {
  padding: 5rem 25rem 0;

  img {
    width: 100%;
    height: 100%;
  }
}

.token-sale-btn-row {
  padding: 0rem 12rem 0;
  margin-top: -10rem;
}

.register-innerbg1 {
  h5 {
    font-size: 25px;
    line-height: 1.2;
    letter-spacing: 0.1em;
    color: $color_2;
    text-shadow: 0px 0px 8px rgb(255 255 255 / 45%);
    margin-bottom: 2rem;
  }
}

.final-confirmation {
  display: grid;
  justify-content: center;
  padding: 2rem 0px 0px;

  h4 {
    font-weight: 400;
    font-size: 25px;
  }
}

.game-details-btn {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  padding: 2rem 10rem 0px;
}

.confirmation-btn-action-link {
  display: inline-block;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  border: none;
  box-shadow: 0px 24px 48px rgb(255 255 255 / 10%),
    inset 0px 4px 11px rgb(0 0 0 / 16%),
    inset 0px 0px 2px rgb(255 255 255 / 40%);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  color: $color_2;
  padding: 16px 22px;
  letter-spacing: 0.4px;
  min-width: 160px;
}

.game-submit-inner-wrap {
  padding: 20rem 0 0;
  position: relative;
}

.game-submit-row {
  text-align: center;
  padding: 3rem 0px;
}

.submit-row {
  text-align: center;
  margin-top: 50px;

  @media (max-width: 991px) {
    margin-top: 0;
  }

  .submit-form-control {
    text-align: left;
  }

  .text-input-group {
    background-image: url("../images/submit-form-wrapper.png");
    background-position: center;
    background-size: 100% 100%;
    padding: 90px 50px;

    @media screen and (max-width: 550px) {
      padding: 90px 25px;
    }
  }

  .upload-input-group {
    background-image: url("../images/submit-form-wrapper.png");
    background-position: center;
    background-size: 100% 100%;
    padding: 90px 50px;

    @media screen and (max-width: 767px) {
      padding: 90px 25px;
    }
  }
}

.submitbox {
  display: inline-block;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  min-width: 340px;
  padding: 0 40px;
  margin: 0 auto;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: -8px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFYAAAAUCAYAAAAXxsqQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAATvSURBVHgB7VnNbuM2EJ4hKcmylWyzGxRFD0UPPbW3oih6ae+99hXaR+mz9NC3KlAsGmybxLHjOLZIdkibzogmJTm7ue0ABClxOD8fP/7IBvgoLyfWWnSFt8Mz14l1+ftT7fXp5vpif7lYcu/67KX6+vwM5eU7SGyqjnUhL3agf0gX9+95m+v12T/Fd0q47zGS1eX4dWYhAhThKWCMAonbFtLg8PZQAtxWPA4yNnI2c7bi/lgHIB8j92+ZXkffYRg7CEuAAyrYs9gbEYngAPqDHMN222NzjK+hODBjc8g+f+bFMB3DFR24yo/osjTUAcxQ+Lug89xlGCbolCX4vnLqkg9iIQ0qwhO43nZgqxOVcM4BlKwO72JW920HAMfJpJY4JHTi5DBTp2zGY/lKA+hnbMqHYbWBbu6BrYYIetgKPLCZvTWAKFlJMTcOBiAPDPQkACcmnpPc2KEtJzeeszQUve9vc0Y4Y+O9NTA1LqGPj3mpJf2Stsf4SAGrWZ9gbW8nXAJUdK0KygEw+c0PP/5H6mCNGwFIFQhEI4QApZSuyqItVKWLUrWTyWRTV9VmNpu2dT3VdV23zVnTNk1jZmdn9vzVK103M6zPGirncnrWyObiAkU1KbRV1Wpj69UjTJZLXS7vrZrfaFjcGpfK5vPX8u7LSzH/4lLOp7XYLFa6+ufWzK6uzexmDpO7W1vdL2yxXqB6vEexXQDqBwp4g2A3tCiJW4IgQYNWUmqUJEqBIB0I0vq2KgAKopqiUhRgVQlYlAhFRc/lvlCb9OzPv+Eb6O6xvpD4rYHfY/lB5Zjp2Kx+//OPa7d3wH7vsDt8LXi0XcPfksOEURZgfMsY8G0/Kd6/Af8KyBFZ2dK4LdkiDdhYrHBimmq6cZNx8clFW0zLYjIrq/PPmvZhidOyuYTruVxc/XVT3aw29fzflTT3a1DmEQvT2rI0WBYWqkrAdCYtAYLFRBAYwkqFqAoCkbKSBQFLILokhNwRVuyphPvjlPCAp/3SEc+lZTvU/v67b1/Dbito9yz2jA7AqszHwIHBv/70y99OVWphYbubec+ArUC7JvJuaWhL490z1agRhY8B/T7sgkZFD64UvhZYkB4BQVgKUWqUUwM40wqrtiKk0MVnjEU3H60mkmlJ9RbuLqbV7foNfEpJf/W1glKRW1J4N1/j1bsHeHu7hOVyaddv7yjGBdjtvbV6haDXloqjLhl1FN6CsA4LbQXNrIONsKZifdQSd1gqSS1BEyMEukRC22FGwKZuC08fCNGHAWetYswNNb96iWgSUqf1cyR1meeX8fgA6vM3FEuqP5z0/ACLD66W1S3vD1jGjOUJaOaIO40Pr5wMXY1ghI2Uvbg9ZhzA8JWs76rGDzDNion6d47CB0IEbpgtZIYsRJs0HAOeCjTHvKHnobsxQHqicnGk2qkYUhKDe9hPoQvsTjncCsIDHDs0kXH3HJgqoF9yS8xmAscTbX0oGcN8vorjbaHDWE7QjlH2W4ETvtz7vrjiAPuY0bcM+7YHC8MfE0P1c4Tb4MzkX2Idxh4SCNTtWOsCnANz7BdN/P7URMeyKrVNQCK2MROQIkdsuwN2jKEffKAv+z3xYKV7FRt7eIw9tT+EvA8zTz0Ej3JBfr9n7ew/Awdr+TvukV7fL+9Dtof+mej7J6HPP+/L/Wsw5h8D6JHU+P8BX/l+p2tAxgoAAAAASUVORK5CYII=)
      center 0 no-repeat;
    width: 100%;
    height: 20px;
  }

  h2 {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 600;
    color: $color_1;
    text-align: center;
    letter-spacing: 0.1em;
    text-shadow: 0px 0px 8px rgb(255 255 255 / 45%);
    margin: 0;
    padding: 8px 0 15px;
  }
}

.successful-tab {
  padding: 3rem 0 0px;

  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 37px;
    text-align: center;
    color: $color_2;
  }
}

.token-sale-inner-wrap {
  padding: 20rem 0 0;
  position: relative;
  padding: 20rem 0 0;
  background: url("/images/ellipse.png") 0 top no-repeat;
  position: relative;
}

.token-sale-row {
  text-align: center;
  text-align: center;
}

.tokenbox {
  display: inline-block;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  min-width: 340px;
  padding: 0 40px;
  margin: 0 auto;
  position: relative;
  display: inline-block;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  min-width: 340px;
  padding: 0 40px;
  margin: 0 auto;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: -8px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFYAAAAUCAYAAAAXxsqQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAATvSURBVHgB7VnNbuM2EJ4hKcmylWyzGxRFD0UPPbW3oih6ae+99hXaR+mz9NC3KlAsGmybxLHjOLZIdkibzogmJTm7ue0ABClxOD8fP/7IBvgoLyfWWnSFt8Mz14l1+ftT7fXp5vpif7lYcu/67KX6+vwM5eU7SGyqjnUhL3agf0gX9+95m+v12T/Fd0q47zGS1eX4dWYhAhThKWCMAonbFtLg8PZQAtxWPA4yNnI2c7bi/lgHIB8j92+ZXkffYRg7CEuAAyrYs9gbEYngAPqDHMN222NzjK+hODBjc8g+f+bFMB3DFR24yo/osjTUAcxQ+Lug89xlGCbolCX4vnLqkg9iIQ0qwhO43nZgqxOVcM4BlKwO72JW920HAMfJpJY4JHTi5DBTp2zGY/lKA+hnbMqHYbWBbu6BrYYIetgKPLCZvTWAKFlJMTcOBiAPDPQkACcmnpPc2KEtJzeeszQUve9vc0Y4Y+O9NTA1LqGPj3mpJf2Stsf4SAGrWZ9gbW8nXAJUdK0KygEw+c0PP/5H6mCNGwFIFQhEI4QApZSuyqItVKWLUrWTyWRTV9VmNpu2dT3VdV23zVnTNk1jZmdn9vzVK103M6zPGirncnrWyObiAkU1KbRV1Wpj69UjTJZLXS7vrZrfaFjcGpfK5vPX8u7LSzH/4lLOp7XYLFa6+ufWzK6uzexmDpO7W1vdL2yxXqB6vEexXQDqBwp4g2A3tCiJW4IgQYNWUmqUJEqBIB0I0vq2KgAKopqiUhRgVQlYlAhFRc/lvlCb9OzPv+Eb6O6xvpD4rYHfY/lB5Zjp2Kx+//OPa7d3wH7vsDt8LXi0XcPfksOEURZgfMsY8G0/Kd6/Af8KyBFZ2dK4LdkiDdhYrHBimmq6cZNx8clFW0zLYjIrq/PPmvZhidOyuYTruVxc/XVT3aw29fzflTT3a1DmEQvT2rI0WBYWqkrAdCYtAYLFRBAYwkqFqAoCkbKSBQFLILokhNwRVuyphPvjlPCAp/3SEc+lZTvU/v67b1/Dbito9yz2jA7AqszHwIHBv/70y99OVWphYbubec+ArUC7JvJuaWhL490z1agRhY8B/T7sgkZFD64UvhZYkB4BQVgKUWqUUwM40wqrtiKk0MVnjEU3H60mkmlJ9RbuLqbV7foNfEpJf/W1glKRW1J4N1/j1bsHeHu7hOVyaddv7yjGBdjtvbV6haDXloqjLhl1FN6CsA4LbQXNrIONsKZifdQSd1gqSS1BEyMEukRC22FGwKZuC08fCNGHAWetYswNNb96iWgSUqf1cyR1meeX8fgA6vM3FEuqP5z0/ACLD66W1S3vD1jGjOUJaOaIO40Pr5wMXY1ghI2Uvbg9ZhzA8JWs76rGDzDNion6d47CB0IEbpgtZIYsRJs0HAOeCjTHvKHnobsxQHqicnGk2qkYUhKDe9hPoQvsTjncCsIDHDs0kXH3HJgqoF9yS8xmAscTbX0oGcN8vorjbaHDWE7QjlH2W4ETvtz7vrjiAPuY0bcM+7YHC8MfE0P1c4Tb4MzkX2Idxh4SCNTtWOsCnANz7BdN/P7URMeyKrVNQCK2MROQIkdsuwN2jKEffKAv+z3xYKV7FRt7eIw9tT+EvA8zTz0Ej3JBfr9n7ew/Awdr+TvukV7fL+9Dtof+mej7J6HPP+/L/Wsw5h8D6JHU+P8BX/l+p2tAxgoAAAAASUVORK5CYII=)
      center 0 no-repeat;
    width: 100%;
    height: 20px;
    position: absolute;
    content: "";
    left: 0;
    bottom: -8px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFYAAAAUCAYAAAAXxsqQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAATvSURBVHgB7VnNbuM2EJ4hKcmylWyzGxRFD0UPPbW3oih6ae+99hXaR+mz9NC3KlAsGmybxLHjOLZIdkibzogmJTm7ue0ABClxOD8fP/7IBvgoLyfWWnSFt8Mz14l1+ftT7fXp5vpif7lYcu/67KX6+vwM5eU7SGyqjnUhL3agf0gX9+95m+v12T/Fd0q47zGS1eX4dWYhAhThKWCMAonbFtLg8PZQAtxWPA4yNnI2c7bi/lgHIB8j92+ZXkffYRg7CEuAAyrYs9gbEYngAPqDHMN222NzjK+hODBjc8g+f+bFMB3DFR24yo/osjTUAcxQ+Lug89xlGCbolCX4vnLqkg9iIQ0qwhO43nZgqxOVcM4BlKwO72JW920HAMfJpJY4JHTi5DBTp2zGY/lKA+hnbMqHYbWBbu6BrYYIetgKPLCZvTWAKFlJMTcOBiAPDPQkACcmnpPc2KEtJzeeszQUve9vc0Y4Y+O9NTA1LqGPj3mpJf2Stsf4SAGrWZ9gbW8nXAJUdK0KygEw+c0PP/5H6mCNGwFIFQhEI4QApZSuyqItVKWLUrWTyWRTV9VmNpu2dT3VdV23zVnTNk1jZmdn9vzVK103M6zPGirncnrWyObiAkU1KbRV1Wpj69UjTJZLXS7vrZrfaFjcGpfK5vPX8u7LSzH/4lLOp7XYLFa6+ufWzK6uzexmDpO7W1vdL2yxXqB6vEexXQDqBwp4g2A3tCiJW4IgQYNWUmqUJEqBIB0I0vq2KgAKopqiUhRgVQlYlAhFRc/lvlCb9OzPv+Eb6O6xvpD4rYHfY/lB5Zjp2Kx+//OPa7d3wH7vsDt8LXi0XcPfksOEURZgfMsY8G0/Kd6/Af8KyBFZ2dK4LdkiDdhYrHBimmq6cZNx8clFW0zLYjIrq/PPmvZhidOyuYTruVxc/XVT3aw29fzflTT3a1DmEQvT2rI0WBYWqkrAdCYtAYLFRBAYwkqFqAoCkbKSBQFLILokhNwRVuyphPvjlPCAp/3SEc+lZTvU/v67b1/Dbito9yz2jA7AqszHwIHBv/70y99OVWphYbubec+ArUC7JvJuaWhL490z1agRhY8B/T7sgkZFD64UvhZYkB4BQVgKUWqUUwM40wqrtiKk0MVnjEU3H60mkmlJ9RbuLqbV7foNfEpJf/W1glKRW1J4N1/j1bsHeHu7hOVyaddv7yjGBdjtvbV6haDXloqjLhl1FN6CsA4LbQXNrIONsKZifdQSd1gqSS1BEyMEukRC22FGwKZuC08fCNGHAWetYswNNb96iWgSUqf1cyR1meeX8fgA6vM3FEuqP5z0/ACLD66W1S3vD1jGjOUJaOaIO40Pr5wMXY1ghI2Uvbg9ZhzA8JWs76rGDzDNion6d47CB0IEbpgtZIYsRJs0HAOeCjTHvKHnobsxQHqicnGk2qkYUhKDe9hPoQvsTjncCsIDHDs0kXH3HJgqoF9yS8xmAscTbX0oGcN8vorjbaHDWE7QjlH2W4ETvtz7vrjiAPuY0bcM+7YHC8MfE0P1c4Tb4MzkX2Idxh4SCNTtWOsCnANz7BdN/P7URMeyKrVNQCK2MROQIkdsuwN2jKEffKAv+z3xYKV7FRt7eIw9tT+EvA8zTz0Ej3JBfr9n7ew/Awdr+TvukV7fL+9Dtof+mej7J6HPP+/L/Wsw5h8D6JHU+P8BX/l+p2tAxgoAAAAASUVORK5CYII=)
      center 0 no-repeat;
    width: 100%;
    height: 20px;
  }

  h2 {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 600;
    color: $color_1;
    text-align: center;
    letter-spacing: 0.1em;
    text-shadow: 0px 0px 8px rgb(255 255 255 / 45%);
    margin: 0;
    padding: 8px 0 15px;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 600;
    color: $color_1;
    text-align: center;
    letter-spacing: 0.1em;
    text-shadow: 0px 0px 8px rgb(255 255 255 / 45%);
    margin: 0;
    padding: 8px 0 15px;
  }
}

.swap-sale {
  text-align: center;
  padding: 4rem 0px;
  text-align: center;
  padding: 4rem 0px;
}

.token-sale-box {
  background: white;
  border-radius: 20px;
  padding: 64px;
  margin-bottom: 30px;
  width: 50%;
  margin: 0 22rem;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  backdrop-filter: blur(40px);
  border-radius: 16px;
  padding: 64px;
  margin-bottom: 30px;
  max-width: 550px;
  margin: 40px auto 4rem;
}

.token-sale-inner-box {
  background: black;
  border-radius: 20px;
  margin-bottom: 30px;
  width: 100%;
  padding: 10px 10px;
  margin-top: 4rem;
  background: black;
  border-radius: 20px;
  margin-bottom: 30px;
  width: 100%;
  padding: 10px 10px;
  margin-top: 2rem;
}

.token-box {
  background: white;
  border-radius: 20px;
  margin-bottom: 30px;
  width: 100%;
  padding: 20px;
  margin-top: 15px;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  backdrop-filter: blur(40px);
  border-radius: 20px;
  margin-bottom: 30px;
  width: 100%;
  padding: 16px;
  margin-top: 15px;
}

.token-swap-row {
  display: flex;
  justify-content: space-between;
  display: flex;
  justify-content: space-between;
}

.token {
  width: 130px;
  border-radius: 20px;
  height: 30px;
  background: blue;
  color: $color_3;
  padding: 0 12px;
  display: block;
  width: 150px;
  border-radius: 20px;
  height: 35px;
  background: #4758ee;
  color: $color_3;
  padding: 0 12px;
  display: block;
}

.token-price {
  h5 {
    color: $color_3;
    display: block;
  }

  text-align: end;
}

.token-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.token-box-no2 {
  background: white;
  border-radius: 20px;
  margin-bottom: 30px;
  width: 100%;
  padding: 16px;
  margin: -1.7rem 0px 0px;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  backdrop-filter: blur(40px);
  border-radius: 20px;
  margin-bottom: 30px;
  width: 100%;
  padding: 16px;
  margin: -1.7rem 0px 0px;
}

.applications-col1 {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: $color_1;
  padding: 15px 0;
  letter-spacing: 0.45px;
  justify-content: space-around;

  @media (max-width: 800px) {
    display: block;
    text-align: right;
    opacity: 0.5;
    padding-right: 60px !important;
  }
}

.token-sale-stake-action {
  padding: 1rem 0;
}

.token-proceed-btn-action-link {
  display: inline-block;
  background: rgba(255, 255, 255, 0.02);
  border: none;
  box-shadow: 0px 24px 48px rgb(255 255 255 / 10%),
    inset 0px 4px 11px rgb(0 0 0 / 16%),
    inset 0px 0px 2px rgb(255 255 255 / 40%);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  color: $color_2;
  padding: 22px 22px;
  letter-spacing: 0.4px;
  min-width: 210px;
  text-decoration: none;

  &::before {
    position: absolute;
    content: "";
    height: 3px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(
      269.99deg,
      #eeeeee -69.29%,
      #fffee2 -62.4%,
      rgba(255, 255, 255, 0.950883) -57.4%,
      #df68fd -51.6%,
      rgba(255, 255, 255, 0.815523) -44.74%,
      #b7eee5 -35.82%,
      #20282e -12.7%,
      #29353c -5.34%,
      rgba(255, 255, 255, 0.596267) -1.97%,
      #c9fbfe 5.76%,
      #79a2f2 11.83%,
      rgba(255, 255, 255, 0.741036) 17.11%,
      #79a2f2 23.13%,
      #5d23fd 33.9%,
      #ffffff 45.48%,
      #51555e 79.22%,
      #699cff 89.6%,
      #ffffff 92.26%,
      #79a2f2 95.03%,
      #ffffff 97.45%
    );
  }
}

.token-submit-form-control {
  width: 50%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  border: none;
  padding: 12px 1px;
  font-weight: 400;
  font-size: 15px;
  color: $color_1;
  margin-left: -6rem;
}

.token-sale-language {
  padding: 0 0 2rem;
}

@media screen and (max-width: 767px) {
  .btn-action-link {
    font-size: 15px;
    padding: 15px 15px;
  }

  .applications-box1 {
    display: none;
  }

  .provider-pool-box {
    height: auto;
    margin-bottom: 1.5rem;
    padding: 2rem;
  }

  .provider-pool-row {
    padding-top: 1.5rem;
    margin-bottom: 0;
  }

  .direction-box {
    button {
      width: 50px;
      height: 50px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    margin-top: -55px;
  }

  .liquidity-rpt-box {
    height: auto;
    margin-bottom: 1.5rem;
    padding: 2rem;
  }

  .roye-stake-box {
    height: auto;
    margin-bottom: 1.5rem;
    padding: 2rem;
  }

  .rpt-accumulate-box {
    height: auto;
    margin-bottom: 1.5rem;
    padding: 2rem;
  }

  .rpt-dynamic-box {
    height: auto;
    margin-bottom: 1.5rem;
    padding: 2rem;
  }

  .reserve-stake-box {
    height: auto;
    margin-bottom: 1.5rem;
    padding: 2rem;
  }

  .roye-box {
    height: auto;
  }

  .roye-stake-row {
    margin-top: 0;
    padding-bottom: 0;
  }

  .withdraw-bottom-action {
    .btn-action-link {
      min-width: auto;
    }
  }

  .rpt-total {
    font-size: 2rem;
  }

  .total-volume {
    font-size: 2rem;
  }

  .liquidity-history {
    margin-bottom: 1.5rem;

    table {
      th {
        font-size: 12px;
      }
    }
  }

  .volume-icon-box {
    width: 80px;
    height: 80px;
    background-size: 80px 80px;
    margin: -35px auto 1.5rem;
  }

  .volume-box {
    h4 {
      font-size: 1.1rem;
    }

    padding-bottom: 1.5rem;
  }

  .volume-box-wrap {
    margin-top: 3.5rem;
  }

  .footer-social-link {
    ul {
      li {
        margin-bottom: 1.5rem;
      }
    }

    max-width: 398px;
    padding: 2rem 0 1rem;
  }

  .footercontainer {
    p {
      font-size: 18px !important;
      margin-bottom: 1.5rem;
    }
  }

  .liquidity-history-row {
    padding: 2rem;

    table {
      th:nth-child(2n) {
        display: none;
      }

      tr:nth-child(2n) {
        display: none;
      }
    }
  }

  .rpt-dynamic-wrap {
    padding: 0;
  }

  .stake-dynamic-img {
    margin: 0;
    padding: 0 0 1.5rem;
    text-align: center;

    img {
      max-width: 180px;
    }
  }

  .stake-dynamic-count {
    font-size: 1.5rem;
    font-weight: 500;

    img {
      width: 40px;
      margin-right: 15px;
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .staking-action-row {
    .btn-action-link {
      min-width: auto;
    }
  }

  .staking-slider {
    margin-top: 0;
  }

  .staking-nav {
    ul {
      li {
        a {
          font-size: 20px;
          padding: 15px 15px 15px;
        }
      }
    }
  }

  .staking-listrow {
    padding-top: 4rem;
  }

  .total-valuerow {
    padding-top: 2rem;
  }

  .value-locaked {
    padding: 2rem;
  }

  .locaked-amount {
    font-size: 2rem;
  }

  .value-itembox {
    ul {
      li {
        width: 50%;
        margin-bottom: 1.5rem;
      }
    }

    margin-left: 0;
    margin-top: 1rem;
  }

  .total-valueamount {
    font-size: 1rem;
  }

  .pools-staking {
    height: 120px;

    h4 {
      font-size: 12px;
    }
  }

  .teamwrap {
    padding-top: 3rem;
  }

  .team-col {
    width: 100%;
  }

  .compability-col {
    width: 100%;
    padding: 0;
    margin-bottom: 1.5rem;

    &:nth-child(2) {
      transform: translateY(-0px);
    }
  }

  .compability-iconbox {
    height: 100px;
    width: 100px;

    img {
      width: 60px;
    }
  }

  .compability-box {
    h4 {
      height: auto;
      padding: 2rem 0;
      font-size: 1.5rem;
      letter-spacing: 0.5px;
    }
  }

  .compability-row {
    padding-top: 3rem;
    margin-left: 0;
    margin-right: 0;
  }

  .partners-row {
    padding: 3rem 0;
    margin-left: -10px;
    margin-right: -10px;
  }

  .partners-col {
    width: 50%;
    padding: 0 10px;
  }

  .partners-box {
    height: 112px;
    margin-bottom: 20px;
  }

  .comparisonwrap {
    &::before {
      display: none;
    }
  }

  .roadmapwrap {
    padding: 3rem 0 2rem !important;
  }

  .roadmap-row {
    padding-top: 2rem;
  }

  .roadmapline {
    display: none;
  }

  .roadmap-col {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .home-banner-content {
    img {
      max-width: 100%;
    }

    h3 {
      font-size: 2rem;
      letter-spacing: 0.5px;
    }
  }

  .latest-col {
    width: 100%;
    padding: 0;
    margin-bottom: 1.5rem;
  }

  .home-wrapperbg {
    height: auto;
    background: none;
  }

  .tier-levels-info {
    text-align: center;
    padding-top: 2rem;
  }

  .social-media-tabs {
    grid-template-columns: 1fr;
  }

  .social-media-box {
    padding: 16px;
  }

  .rpt-staking-wrap {
    .headingbox {
      padding: 5px;
    }

    h2 {
      font-size: 24px;
    }
  }
}

.bright-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;

  letter-spacing: 0.1em;
  color: #ffffff;
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
}

.ml-1 {
  margin-left: 1rem;
}

.dashboard-roya-wrap {
  margin-top: 50px;
}

.text-color-white {
  color: gray !important;
}

.custom-success-box {
  background: url('../images/box/rectangle/rectangle7.png') center/100% 100% no-repeat;
}

.success-text-color {
  color: black !important;
}

.success-info-color {
  color: gray !important;
}

.custom-toast-container {
  .Toastify__toast-theme--light, .Toastify__toast-theme--dark, .Toastify__toast-theme--colored.Toastify__toast--default, .Toastify__toast-theme--colored.Toastify__toast--info, .Toastify__toast-theme--colored.Toastify__toast--success, .Toastify__toast-theme--colored.Toastify__toast--warning, .Toastify__toast-theme--colored.Toastify__toast--error {
    background: url('../images/box/rectangle/rectangle13.png') center/100% 100% no-repeat;
    font-family: Gloria Hallelujah;
  }

  .Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 5px;
    z-index: -9999;
    opacity: 1;
    transform-origin: unset;
  }

  .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards;
  }

  .Toastify__progress-bar--controlled {
    transition: transform 0.2s;
  }

  .Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    transform-origin: unset;
  }
}

@mixin gif-logo-styles($margin-left, $width) {
  margin-left: $margin-left;
  width: $width;
}

.container-custom {
  position: relative;

  .gif-logo {
    position: absolute;
    @include gif-logo-styles(-300px, 400px);
    margin-top: 100px;
  }

  @media only screen and (min-width: 1792px) {
    .gif-logo {
      @include gif-logo-styles(-230px, 300px);
    }
  }

  @media only screen and (min-width: 1982px) {
    .gif-logo {
      @include gif-logo-styles(-380px, 330px);
    }
  }

  @media only screen and (min-width: 2422px) {
    .gif-logo {
      @include gif-logo-styles(-680px, 460px);
    }
  }

  @media only screen and (max-width: 1792px) {
    .gif-logo {
      @include gif-logo-styles(-190px, 300px);
    }
  }

  @media only screen and (max-width: 1518px) {
    .gif-logo {
      @include gif-logo-styles(-120px, 280px);
    }
  }

  @media only screen and (max-width: 1280px) {
    .gif-logo {
      @include gif-logo-styles(-60px, 260px);
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 1164px) {
    .gif-logo {
      @include gif-logo-styles(-50px, 260px);
    }
  }

  @media only screen and (max-width: 981px) {
    .gif-logo {
      @include gif-logo-styles(-100px, 260px);
    }
  }

  @media only screen and (max-width: 900px) {
    .gif-logo {
      @include gif-logo-styles(-60px, 230px);
      margin-top: 150px;
    }
  }

  @media only screen and (max-width: 643px) {
    .gif-logo {
      @include gif-logo-styles(-40px, 200px);
    }
  }

  @media only screen and (max-width: 550px) {
    .gif-logo {
      @include gif-logo-styles(-40px, 200px);
      margin-top: 100px;
    }
  }
}

