.first-sales-col{
    border-right: 1px solid #000 ;
    align-items: baseline;
}
.r-button-group{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.r-button-group .r-btn-col:first-child{
    width: 100%;
}
.r-button-group .r-btn-col button{
    font-size: 14px;
}

.footer-social-link {


    ul {
        li {
            text-align: center;
            width: 100px;
            margin: 0;
        }

        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        margin: 0;
    }
}
.social-link {
    display: flex;
    padding: 0;
    margin: 0;

    li {
        a {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            border-radius: 100%;
            background: url("../../../../images/round-wrapper.svg") 0 0 no-repeat;
            background-size: 60px 60px;
            margin: 0 auto;

            svg {
                position: relative;
                z-index: 1;
                width: 25px;
                height: 25px;
            }
        }
    }
}

.r-bottom-pera{
    margin: 24px 0 20px;
}
.r-bottom-pera p{
    color: #9AA1AC;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}
.r-bottom-pera p span{
    color: #000;
}

.check_Title p{
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #000;
}

.check_amount input{
    padding: 5px;
    border: none;
    color: #000;
    border-radius: 4px;
    border: 2px solid var(--Black, #111);
    background: var(--White-White-100, #FFF);
    box-shadow: 4px 4px 0px 0px #111;
}
.success_tag{
    background: linear-gradient(223.88deg, rgba(203, 79, 223, 0) 1.79%, #802DFD 98.47%) !important;
    color: #fff !important;
    border: 1px solid;
    border-image-source: linear-gradient(136.24deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);  
}
.error_tag{
    background: linear-gradient(223.88deg, rgba(182, 187, 189, 0) 1.79%, #676189 98.47%);
    color: #fff !important;
    border: 1px solid;
    border-image-source: linear-gradient(136.24deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
}
